import { setSessionCookie, getAllSessionCookie } from "./functions";
import Cookies from 'js-cookie'
import { devApiUrl } from "../constants";

// request wrapper for rest api
const requestApi2 = (url, type = 'GET', data = {}) => {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest
        let error = {status: 500, success: false, messages: [], data: {}}
        xhr.open(type, devApiUrl + url)
        xhr.onload = function (event) {
            try {
                var res = JSON.parse(event.target.response)
                if(res.cookies){
                    for(let key in res.cookies){
                        // setSessionCookie(key, res.cookies[key], {secure: false, domain: '.uadreams.com'})
                        let params = {secure: false} 
                        if(window.location.hostname !== 'localhost'){
                            params.domain = '.uadreams.com'
                        }
                        Cookies.set(key, res.cookies[key], params)
                    }
                }

                delete res.cookies

                resolve(res)
            } catch (err) {
                error.messages.push(err)
                reject(error)
            }
        };

        xhr.onerror = function (event) {
            error.messages.push("Error " + event.target.status + " occurred while receiving the document.")
            reject(error)
        }

        // xhr.setRequestHeader('cookie', JSON.stringify(getAllSessionCookie()))

        const cookies = Cookies.getJSON()
        xhr.setRequestHeader('cookies', Buffer.from(JSON.stringify(cookies)).toString('base64'))

        xhr.setRequestHeader('Content-Type', 'application/json')
        data = JSON.stringify(data || {})
        xhr.send(data)
    });
}
export default requestApi2;