import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";
import { devApiUrl } from "../constants";

export class QuickSupport extends CustomCollection {
    promiseCreateInstance () {
        return Promise.resolve();
    };
    
    sendMistake (text) {
        this.mistakeText = window.getSelection();
        this.trigger('userFindMistake')
    }
    
    sendTextError (data) {
        var userId = getCookie('logid');
        var url = '/support/grammaticalError';
        if (userId > 0) {
            url = '/user/' + userId + '/support/grammaticalError';
        }
        
        return requestApi(url, 'POST', data, null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        )
    }
    
    sendError (data) {
        return new Promise(function (resolve, reject) {
            var url = devApiUrl + '/support';
            var userId = getCookie('logid');
            if (userId > 0) {
                url = devApiUrl + '/user/' + userId + '/support'
            }
    
            var xhr = new XMLHttpRequest();
            // xhr.withCredentials = true;
    
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    resolve(this.responseText);
                }
            });
            var ssid = getCookie('login_key');
            xhr.open("POST", url);
            xhr.setRequestHeader("api-token", ssid);
    
            xhr.send(data);
        })
    };
}