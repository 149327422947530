import { HandlerWays } from "./handlerWays";
import api from "../api/ApiV2"

let historyStates = [];

function isReadyPath(path) {
    const pathForCheck = path.split('?')[0]

	if(pathForCheck.indexOf("/mailbox") === -1){
		return true
	}

    return false
}

class Router extends HandlerWays{
	constructor(dynamicLoad = true) {
		super();
		this._extendsHistory();
		this._dynamicLoad = dynamicLoad;

		if (this._dynamicLoad) {
			if (this.testIE()) { 
				window.onhashchange = event => this.processingPopState(event);
			} else {
				window.addEventListener('popstate', event => this.processingPopState(event));
			}
	
			document.addEventListener('click', event => this.followingLink(event));
			
			if (this.testMobile()) {
				this._polyfillMobileClick();
			}
		}

	}

	_extendsHistory() {
		let _historyPushState = History.prototype.pushState; 
		History.prototype.pushState = function () {
			historyStates.push(arguments[2]);
			return _historyPushState.apply(this, arguments);
		}
	}

	_polyfillMobileClick() {
		var isClick = true;
		window.addEventListener('touchstart', () => {isClick = true});
		window.addEventListener('touchmove', () => {isClick = false});
		window.addEventListener('touchend', event => {

			if (isClick) {
				event.target.dispatchEvent(new Event('click'));
			}
		});
	}

	getHistoryState() {
		return historyStates;
	}

	testIE() {
		return false;
		return window.jsclientinfo.browser === 'Microsoft Internet Explorer';
	}

	testMobile() {
		return false;
		return window.jsclientinfo.mobile === true;
	}

	setLocate(fullPath) {

		if (window.closeMobMenu) {
			window.closeMobMenu();
		}

		window.instancesPool.gets(['User']).then(([user]) => {
			user.trigger('page-changed')
		})
		
		window.history.pushState({dynamicPageURL : fullPath}, null, fullPath);
	}

	// из за того что у ИЕ нет свойства HTMLElement.path
	_getFirstAnchor(element) {
		if(!element){
			return null
		}
		if (element.tagName === 'A') {
			return element;
		} else if (element.tagName !== 'BODY'){
			return this._getFirstAnchor(element.parentNode);
		} else {
			return null;
		}
	}

	checkIsChat(path) {
		if(window.location.href.includes('/startvideochat/')) {
			window.location.href = path
			return true
		}
		return false
	}

	followingLink(event) {
		let linkAnchorElement = this._getFirstAnchor(event.target);

		// return console.dir(linkAnchorElement);

		if (linkAnchorElement instanceof HTMLAnchorElement && linkAnchorElement.target !== '_blank' && !!linkAnchorElement.href) {

			let path = linkAnchorElement.href;
			path = path.replace(/^.*\/\/[^\/]+/, '');
			
			if(this.checkIsChat(path) || isReadyPath(path)) return true;
			this.setState(path).then((controller) => { 

				if (!controller) {
					this._nextPage = path;
					event.preventDefault();
				} else if (controller.createDynamicPage(path)) {
					event.preventDefault();
					this.setLocate(path);
				}

				if (this.testMobile()) {
					window.closeMobMenu();
				}
			});
		}
	}

	goToPage(path) {
		if(this.checkIsChat(path) || isReadyPath(path)) return true;
		
		if (!this._dynamicLoad) {
			return document.location.assign(path);
		}

		this.setState(path).then((controller) => { 

			if (!controller) {
				this._nextPage = path;
			} else if (controller.createDynamicPage(path)) {
				this.setLocate(path);
			}

			if (this.testMobile()) {
				window.closeMobMenu();
			}
		});
	}
	goTo404() {
		this.goToPage('/404')
	}

	// deprecated
	processingTouch(event) {return;}

	processingPopState(event) {
		if (!!event.state && !!event.state.dynamicPageURL) {
			window.location.reload()
		} else if (this.controller instanceof Object) {
			const pathForController = this.getPathForController()
			if(pathForController && pathForController!==""){
				this.controller.setState(pathForController)
			} else {
				window.location.reload()
			}
		}
	}

	getPathForController() {
		return window.location.hash.slice(1);
	}

	getPathForRouter() {
		return window.location.pathname;
	}

	getQueryProps(query) {
		let jsonQuery = decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"');
		try {
			return JSON.parse('{"' + jsonQuery + '"}');
		} catch (error) {
			return null;
		}
	}

	initController(handler, params) {
		if (handler.init instanceof Function) {
			handler.init(this, params);
		} else if (handler instanceof Function) {
			params.callback();
			let currenHref = window.location.href.replace(/^.*\/\/[^\/]+/, '');
			let wrapperParams = {...params, callback : controller => {
				if (!controller.createDynamicPage(params.path, params) 
				&& this._nextPage && currenHref !== this._nextPage) {
					// это временный костыль из за соединение двух технологий, такого не должно быть
					return document.location.assign(this._nextPage)
				}
				
				if (!params.browserTransition) {
					this.setLocate(params.path);
				}
			
			}};
			this.trigger('beforeLoadChunck', {});
			handler().then(
				lazyHandler => lazyHandler.default.init(this, wrapperParams), e => document.location.assign(this._nextPage)
			)
		}
	}

	// Устанавливает слушателя для адреса, если 
	// Пока что второй аргумент выступает ссылкой на клас
	// в дальнейшем планируется внедрить прием ссылки на 
	//  реквестор который в свою очередь по запросу будет
	//  подгружать необходимый контроллер со всеми его связами  
	use(ruleWays, handler) {
		this.addRulesWay(ruleWays);
		this.addListener('changeState', event => {
			if (this.checkPathways(ruleWays, event.rule)) {
				this.initController(handler, {
					...event, 
					queryParams : this.getQueryProps(window.location.search.substring(1))
				});
			}
		});
	}

	init() {
		let path = this.getPathForRouter();
		if(path.indexOf("?") === -1 && path.indexOf("&") !== -1 && !path.includes('attach')){
			window.location.href = path.split("&")[0]
		}else{
			let fullPath = window.location.href.replace(window.location.origin, '');
			this.setState(path, fullPath, true);
		}
	}
}

export {Router};