import { CustomCollection } from "../helpers/custom-classes"

export class App extends CustomCollection {
    canPlayVideoInPage = true

    async promiseCreateInstance () {
        return Promise.resolve();
    }

    setCanPlayVideoInPage(value) {
        var self = this
        self.canPlayVideoInPage = value
        self.trigger('onSetCanPlayVideoInPage', {canPlayVideoInPage: value})
    }
}