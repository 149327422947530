import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";
import { instancesPool } from "../old/models";

export class LadiesStatus extends CustomCollection {
    _ladiesStatusIds = {};

    promiseCreateInstance () {
        var self = this;
        var userId = getCookie('logid');
    
        if (userId > 0) {
            return requestApi('/user/' + userId + '/ladies/statuses', 'get', null, 'json').then(
                function (data) {
                    self._ladiesStatusIds = data;
                    return Promise.resolve();
                }, function (error) {
                    return Promise.reject(error);
                }
            );
        } else {
            return Promise.resolve();
        }
    };
    
    getIdsList(type) {
        var self = this;
        if(type === 'favorites') {
            return self._ladiesStatusIds.favorites
        } else if(type === 'recent') {
            return self._ladiesStatusIds.recent
        } else if(type === 'sweethearts') {
            return self._ladiesStatusIds.sweethearts
        } else if(type === 'favoritesAndSweethearts') {
            return self._ladiesStatusIds.favoritesAndSweethearts || self._ladiesStatusIds.favorites.concat(self._ladiesStatusIds.sweethearts)
        } else {
            return []
        }
    }
    
    _requestChangeStatus (ladyId, status) {
    
        var userId = getCookie('logid');
    
        if (userId <= 0) {
            return Promise.reject();
        }
    
        if (ladyId <= 0 || !['favorite', 'sweetheart', 'default'].includes(status)) {
            return Promise.reject('Lady.oldSetStatus: not correct status code or empty ladyId');
        }
    
        var url = '/user/' + userId + '/lady/' + ladyId + '/status/' + status;
        return requestApi(url, 'POST', null, 'json');
    }
    
    _clearLadyStatus (ladyId) {
        var favoriteKey = this._ladiesStatusIds.favorites.indexOf(ladyId);
        var sweetheartKey = this._ladiesStatusIds.sweethearts.indexOf(ladyId);
        var sweetAndFavKey= this._ladiesStatusIds.favoritesAndSweethearts.indexOf(ladyId);
    
        if (favoriteKey !== -1) {
            this._ladiesStatusIds.favorites.splice(favoriteKey, 1);
        }
    
        if (sweetheartKey !== -1) {
            this._ladiesStatusIds.sweethearts.splice(sweetheartKey, 1);
        }
    
        if(sweetAndFavKey !== -1) {
            this._ladiesStatusIds.favoritesAndSweethearts.splice(sweetAndFavKey, 1);
        }
    };
    
    _setStatus (ladyId, status) {
        this._clearLadyStatus(ladyId);
        if (status === 'favorite') {
            this._ladiesStatusIds.favorites.unshift(ladyId);
            this._ladiesStatusIds.favoritesAndSweethearts.unshift(ladyId);
        } else if (status === 'sweetheart') {
            this._ladiesStatusIds.sweethearts.unshift(ladyId);
            this._ladiesStatusIds.favoritesAndSweethearts.unshift(ladyId);
        }
    }
    
    _changeStatusAjax (ladyId, nextStatus) {
        var self = this;
        this._setStatus(ladyId, nextStatus);
        clearTimeout(this._timerForChangeLadyStatus);
        this._timerForChangeLadyStatus = setTimeout(function () {
            self._requestChangeStatus(ladyId, nextStatus).then((resp)=>{
                self._ladiesStatusIds.favorites = resp.favorites;
                self._ladiesStatusIds.sweethearts = resp.sweethearts;
                self._ladiesStatusIds.favoritesAndSweethearts = resp.favoritesAndSweethearts;
                self._ladiesStatusIds.recent = resp.recent;
            })
        }, 800);
        return nextStatus;
    }
    
    changeStatus (ladyId) {
        var currentStatus = this.getLadiesStatus([ladyId]);
        var statusesChengeMap = {
            default: 'favorite', favorite: 'sweetheart', sweetheart: 'default'
        };
        var nextStatus = statusesChengeMap[currentStatus[ladyId]] || 'favorite';
        return this._changeStatusAjax(ladyId, nextStatus);
    };
    
    getLadiesStatus (ladieIds) {
        var self = this,
            statuses = {};
        ladieIds = ladieIds || [];
        ladieIds.forEach(function (ladyId) {
            ladyId = ladyId * 1;
            var status = 'default';
    
            if (self._ladiesStatusIds.favorites.includes(ladyId)) {
                status = 'favorite';
            } else if (self._ladiesStatusIds.sweethearts.includes(ladyId)) {
                status = 'sweetheart';
            }
    
            statuses[ladyId] = status;
        });
    
        return statuses;
    };
    _detectNotLoadingLadies (ids) {
        var self = this;
        ids = ids || [];
        return new Promise(function (resolve, reject) {
            var noLoadLadies = ids.filter(function (id) {
                var result = self.findIndex(function (item) {
                    return item.id === id;
                });
                return result === -1;
            });
            resolve(noLoadLadies);
        });
    };
    
    _loadData (ids) {
        ids = ids || [];
        var self = this;
        return self._detectNotLoadingLadies(ids).then(function (loadingLadieIds) {
            return Promise.all([
                window.instancesPool.get('Ladies').then(function (ladies) {
                    return ladies.getLadies(loadingLadieIds)
                }),
                window.instancesPool.get('Invitations')
            ])
        }).then(function (results) {
            var ladies = results[0];
            var invitations = results[1];
    
            if (!ladies || !invitations) {
                return Promise.reject('ERROR: _loadData bad responce ladies or invitations');
            }
    
            ladies.forEach(function (lady) {
                lady.invitation = invitations.getById(lady.id);
                self.push(lady);
            });
    
            return Promise.resolve();
        }).then(function () {
            var ladies = self.filter(function (ladyStatus) {
                return ids.includes(ladyStatus.id);
            });
            return Promise.resolve(ladies);
        })
    };
    
    _sortByLastEvent (items) {
        return Promise.resolve(items);
    };
    
    getListFavoritesAndSweethearts () {
        var self = this;
        var ladyIds = self._ladiesStatusIds.sweethearts.concat(self._ladiesStatusIds.favorites);
        return self._loadData(ladyIds).then(function (items) {
            return self._sortByLastEvent(items)
        });
    };
    
    getListFavorites () {
        var self = this;
        return self._loadData(self._ladiesStatusIds.favorites).then(function (items) {
            return self._sortByLastEvent(items);
        });
    }
    
    getListSweethearts () {
        var self = this;
        return self._loadData(self._ladiesStatusIds.sweethearts).then(function (items) {
            return self._sortByLastEvent(items);
        });
    };
    
    getRecent () {
        var self = this;
        return self._loadData(self._ladiesStatusIds.recent).then(function (items) {
            return self._sortByLastEvent(items);
        });
    };
    
    detectedLadiesStatus (ladies) {
        var self = this;
    
        if (!(ladies instanceof Array)) {
            return false;
        }
    
        ladies.forEach(function (lady) {
            lady.status = 'default';
    
            if (self._ladiesStatusIds.favorites.includes(lady.id)) {
                lady.status = 'favorite';
            } else if (self._ladiesStatusIds.sweethearts.includes(lady.id)) {
                lady.status = 'sweetheart';
            }
        });
    };
    
    detectedLadyStatus (lady) {
        this.detectedLadiesStatus([lady]);
    }
    
    count () {
        return this._ladiesStatusIds.favorites.length + this._ladiesStatusIds.sweethearts.length;
    
    };
    
    changeFollow (ladyId) {
        var self = this;
        var currentStatus = this.getLadiesStatus([ladyId]);
        var statusesChengeMap = {
            default: 'sweetheart', favorite: 'sweetheart', sweetheart: 'default'
        };
        var nextStatus = statusesChengeMap[currentStatus[ladyId]] || 'sweetheart';
        return this._changeStatusAjax(ladyId, nextStatus);
    }

    addToSweethearts (ladyId) {
        if(!this._ladiesStatusIds.sweethearts.includes(ladyId)) {
            this._ladiesStatusIds.sweethearts.push(ladyId)
            instancesPool.get('Ladies').then(ladies => {
                ladies.getLadies([ladyId]).then(([lady]) => {
                    if(lady) lady.status = 'sweetheart'
                })
            })
        }
    }
}