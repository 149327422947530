import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";

export class Lady extends CustomEventTarget {
    constructor (params) {
        super(params);
        
        for (var k in params) {
            this[k] = params[k];
        }
    }

    changeStatus () {
        var self = this;
        window.instancesPool.get('LadiesStatus').then(
            function (ladiesStatus) {
                ladiesStatus.changeStatus(self.id);
                ladiesStatus.detectedLadyStatus(self);
                self.trigger('changed');
            }
        );
    };
    
    changeFollow () {
        var self = this;
        window.instancesPool.get('LadiesStatus').then(
            function (ladiesStatus) {
                ladiesStatus.changeFollow(self.id);
                ladiesStatus.detectedLadyStatus(self);
                self.trigger('changed');
            }
        );
    }
    
    static _requestor(params) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies';
        var params = params || {};
        var self = this;
    
        for (var k in params) {
            url += "/".concat(k, "/").concat(params[k]);
        }
    
        return requestApi(url, 'GET', null, 'json').then(
            function (data) {
                var instance = new self;
                for (var k in data[0]) {
                    instance[k] = data[0][k];
                }
                return Promise.resolve(instance);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    
    // DEPRECATED
    static getById(id) {
        return Lady._requestor({ 'id': id });
    };
    
    static getByRoomId(roomid) {
    
        if (roomid <= 0) {
            return Promise.resolve('Error empty arguments');
        }
    
        return Lady._requestor({ 'roomid': roomid });
    }
}