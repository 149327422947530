import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import requestApi2 from "../helpers/request-api2";
import { getCookie, setCookie, setCookieLearn } from "../helpers/functions";
import { devApiUrl, isEnvDevelopment } from "../constants";
import { instancesPool } from "../old/models";
import Cookies from 'js-cookie'

export class User extends CustomEventTarget {
    clientLogged = false;
    loadingStatus = {};
    loadingTimeOuts = {};
    userId = null

    promiseCreateInstance() {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId;

        return new Promise(function (resolve, reject) {

            if (userId <= 10000) {
                resolve();
            } else {
                requestApi(url, 'GET', null, 'json').then(function (data) {
                    for (var k in data) {

                        if (data[k] === 'string') {
                            self[k] = data[k].trim();
                        } else {
                            self[k] = data[k];
                        }
                    }
                    self.clientLogged = true
                    self.userId = userId

                    resolve();
                }, console.warn)
            }
        });
    }

    detectDevice() {
        return this.isMobile == 1 ? 'mobile' : 'desctop';
    };

    // updateCounter () {
    //     var self = this;
    //     var userId = getCookie('logid')
    //     var url = '/user/' + userId + '/getUserCounts';
    //     return requestApi(url, 'GET', null, 'json').then(function(resp) {
    //         // console.log(resp);
    //         self.counts = resp
    //         self.trigger('counts-changed')
    //     });
    // };

    setNewValues(newValues, id) {
        var self = this;
        if (newValues.bth) {
            newValues.bth = JSON.stringify(newValues.bth);
        }
        return requestApi('/user/' + id + '/updateProfile', 'POST', newValues, 'json', 'json').then(function (resp) {
            if (newValues.currentTimeZoneId && newValues.currentTimeZoneId !== self.currentTimeZoneId) {
                instancesPool.get('Times').then(function (udTime) {
                    udTime.currentTimeZoneId = newValues.currentTimeZoneId
                    udTime.trigger('changed');
                })
            }
            if (newValues.bth) {
                newValues.bth = JSON.parse(newValues.bth);
            }
            for (var k in newValues) {
                self[k] = newValues[k];
                self.trigger('changed', {});
            }
            return Promise.resolve(resp)
        });
    }
    hideVideoChatInfo() {
        var url = '/user/' + getCookie('logid') + '/hideVideoChatInfo';
        return requestApi(url, 'POST');
    };
    showVideoChatInfo() {
        return this.clientLogged && this.showVideoChatInfo;
    };
    getUserFiles(trigger, loadingTrigger, type, params = {}) {

        var self = this;
        if (self.id > 0) {
            if (self.userFiles && !trigger && (!params.roomId)) {

                return new Promise(function (res, rej) {
                    res(filterDeclinedFiles(self.userFiles))
                })
                .then(result => {
                    
                    if(params.withoutConverter){

                        return new Promise(function (res, rej) {

                            res({
                                private: result.private.filter(function (file) {
                                    return !file.type.includes("Pending") && !file.type.includes("converter")
                                }),
                                public: result.public
                            })

                        })
                    }

                    return result

                })

            } else {
                var url = '/user/' + self.id + '/files';

                if (params.from || params.roomId) {
                    url += '?'
                    if (params.from) {
                        url += 'from=' + params.from
                    }
                    if (params.roomId) {
                        if (params.from) {
                            url += "&"
                        }
                        url += 'roomId=' + params.roomId
                    }
                }

                return requestApi(url, 'GET', null, 'json').then(function (files) {
                    
                    if(params.withoutConverter){
                        const filesFilter = {
                            private: files.private.filter(function (file) {
                                return !file.type.includes("Pending")
                            }),
                            public: files.public
                        }
                        files = {...filesFilter}
                    }

                    self.userFiles = files;
                    if (trigger) {
                        self.trigger('files-changed')
                    }
                    if (loadingTrigger) {
                        self.trigger('file-loaded')
                    }
                    if (params.from || params.roomId) {
                        return Promise.resolve(files);
                    } else {
                        return Promise.resolve(filterDeclinedFiles(files));
                    }
                })
            }
        }
        function filterDeclinedFiles(userFilesObj) {
            if (type === 'withDeclined') {
                return userFilesObj
            } else {
                var woDeclined = {
                    private: userFilesObj.private.filter(function (file) {
                        return file.porn_status !== 'decline'
                    }),
                    public: userFilesObj.public.filter(function (file) {
                        return file.porn_status !== 'decline'
                    }),
                }
                return woDeclined
            }
        }
    }

    getUserFilesById(fileIds) {
        return new Promise((resolve, reject) => {
            this.getUserFiles().then(files => {
                const result = fileIds.map(fileId => {
                    const fullFile = [...files.private, ...files.public].find(file => file.id === fileId)
                    return fullFile
                })
                resolve(result)
            })
        })
    }

    setUserAway(data) {
        var self = this;
        if (self.id > 0) {
            var url = '/user/' + self.id + '/away?dateTo=' + data.date + '&reason=' + data.reason;
            return requestApi(url, 'POST', null, 'json').then(function () {
                self.awayInfo = {
                    dateTo: data.date,
                    reason: data.reason
                };
                return Promise.resolve();
            })
        }
    }
    deleteUserAway(data) {
        var self = this;
        if (self.id > 0) {
            var url = '/user/' + self.id + '/away';
            return requestApi(url, 'DELETE').then(function () {
                self.awayInfo = null;
                return Promise.resolve();
            })
        }
    }
   
    uploadFiles(data) {
        var self = this;

        return new Promise(function (resolve, reject) {
            var allFiles = data.getAll('someExpressFiles');
            var videoFiles = [];
            var error = [];
            for (var i = 0; i < allFiles.length; i++) {
                // размер в MB
                if (allFiles[i].size / 1024 / 1024 <= 200) {
                    if (allFiles[i].type.includes('video')) {
                        videoFiles.push(allFiles[i])
                    } else {
                        self.startUpload(allFiles[i]).then(function (data) {
                            resolve(data)
                        })
                    }
                } else {
                    error.push({ "id": Math.floor(Math.random() * 10000), "userFileName": "failed", "invalid": true, 'reason': 'File is more than 200 MB. Please reduce the size and try again.' });
                    showErrors();
                    reject();
                }
            }

            // проверка длины видео
            if (videoFiles.length) {
                Promise.all(videoFiles.map(function (item) {
                    return new Promise(function (res, reject) {
                        var video = document.createElement('video');
                        video.preload = 'metadata';
                        video.src = URL.createObjectURL(item);;
                        video.onloadedmetadata = function () {
                            var duration = video.duration;
                            res({ item: item, duration: duration })
                        }
                        video.onerror = function () {
                            res({ item: item, duration: 10, error: true })
                        }
                    });
                })).then(function (data) {
                    data.map(function (file) {
                        if (file.duration > 5) {
                            self.startUpload(file.item).then(function (data) {
                                resolve(data)
                            })
                        } else {
                            error.push({ "id": Math.floor(Math.random() * 10000), "userFileName": "failed", "invalid": true, 'reason': 'File less then 5 sec' });
                            showErrors();
                            reject();
                        }
                    })
                });
            } 

            function showErrors() {
                if (error.length > 0) {
                    self.userFiles.private = error.concat(self.userFiles.private);
                    self.trigger('files-changed')
                    self.trigger('loading-errors', { errorFiles: error })
                }
            }
        })
    }

    updateUserFiles(privateFiles) {
        var self = this;
        return new Promise(function (resolve, reject) {
            if (self?.userFiles?.private) {
                self.userFiles.private = privateFiles;
                resolve(true)
            }
        })
    }

    startUpload(file) {
        var self = this;

        return new Promise(function (resolve, reject) {
            var userId = getCookie('logid');
            var url = devApiUrl + '/user/' + userId + '/file';

            const xhrArray = {[file.id]: new XMLHttpRequest()}
            const xhr = xhrArray[file.id]

            // ставим слушателя, если хотим отменить загрузку
            document.addEventListener("cancel-uploader-" + file.id, (e) => xhrArray[e.detail.fileId].abort())
            
            // отправляем на сколько % загружен файл (отправляем слушателю данные)
            xhr.upload.onprogress = function(event) {
                // console.log( 'Загружено на сервер ' + Math.round(event.loaded * 100 / event.total) + ' % ' );
                const newEvent = new CustomEvent("progress-uploader", { detail: { progress: Math.round(event.loaded * 100 / event.total), fileId: file.id}});
                document.dispatchEvent(newEvent);
            }

            // xhr.addEventListener("readystatechange", function () {});
            xhr.onload = xhr.onerror = function() {
                if (this.readyState === 4) {
                    if (this.status === 200 || this.status === 400) {
                        var loadedFiles = JSON.parse(this.responseText);
                        loadedFiles = loadedFiles.map(function (item) {
                            if (item.type === 'image') {
                                item.type = 'photo'
                            }
                            return item
                        })

                        let addToState = true
                        if(file.from && file.from == 'letter' && loadedFiles[0] && loadedFiles[0].type.includes("converter")){
                            addToState = false
                        }

                        if (self?.userFiles?.private && addToState) {
                            self.userFiles.private = loadedFiles.concat(self.userFiles.private);
                            self.trigger('files-changed')
                        }
                        resolve(loadedFiles);
                    } else {
                        var error = [{ "id": Math.floor(Math.random() * 10000), "userFileName": "failed", "invalid": true, }];
                        if (self?.userFiles?.private) {
                            self.userFiles.private = error.concat(self.userFiles.private);
                        }
                        self.trigger('files-changed')
                    }
                }
            };


            xhr.open("POST", url);

            var ssid = getCookie('login_key');
            var supportId = getCookie('slogid');
            var adm = getCookie('adm');

            if (supportId > 0 && adm) {
                xhr.setRequestHeader('api-token-support', adm);
            } else {
                xhr.setRequestHeader('api-token', ssid);
            }

            let fileForm = new FormData();
            fileForm.append('someExpressFiles', file)

            xhr.send(fileForm);
        })
    }

    checkFileConverter(fileId, type) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/file/converter?id=' + fileId + '&type=' + type
        return requestApi(url, 'GET', null, 'json').then(function (data) {
            return Promise.resolve(data)
        })
    }

    checkFileLoadingStatus(fileId) {
        var self = this;
        if (self.loadingStatus[fileId]) {
            return Promise.resolve(self.loadingStatus[fileId])
        } else {
            self.loadingStatus[fileId] = 0;
            self.checkPercent(fileId);
            return Promise.resolve(self.loadingStatus[fileId])
        }
    }

    checkPercent(fileId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/file/loading?id=' + fileId;
        return requestApi(url, 'GET', null, 'json').then(function (data) {
            self.loadingStatus[fileId] = data.percent;
            self.trigger('loading-status-changed');
            if (data.percent == 100 || data.percent === 'error') {
                //ничего
            } else {
                self.loadingTimeOuts[fileId] = setTimeout(function () {
                    self.checkPercent(fileId)
                }, 5 * 1000)
            }
            return Promise.resolve(self.loadingStatus[fileId])
        })
    }

    clearLoadingTimeOut(fileId) {
        var self = this;
        if (self.loadingTimeOuts[fileId]) {
            clearTimeout(self.loadingTimeOuts[fileId]);
        }
    }

    deleteFile(fileId, album, fileType) {
        var self = this;
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/file?fid=' + fileId + '&type=' + fileType;
            return requestApi(url, 'DELETE').then(function (data) {
                if (album === 'private') {
                    self.userFiles.private = self.userFiles.private.filter(function (item) {
                        return item.id !== fileId
                    })
                    self.trigger('files-changed', 'file-delete')
                } else {
                    self.userFiles.public = self.userFiles.public.filter(function (item) {
                        return item.id !== fileId
                    })
                    self.trigger('files-changed')
                }
                return Promise.resolve();
            })
        }
    }
    deleteErrorFile(fileId) {
        var self = this;

        self.userFiles.private = self.userFiles.private.filter(function (item) {
            return item.id !== fileId
        })
        self.userFiles.public = self.userFiles.public.filter(function (item) {
            return item.id !== fileId
        })
        self.trigger('files-changed')
        return Promise.resolve();
    }

    // cancelLoading(fileId, fyleType) {
    //     var self = this;
    //     var userId = getCookie('logid');
    //     var url = '/user/' + userId + '/file/cancel?id=' + fileId + '&type=' + fyleType;
    //     return requestApi(url, 'PUT', null, 'json').then(function (data) {
    //         self.userFiles.private = self.userFiles.private.filter(function (item) {
    //             return item.id !== fileId
    //         })
    //         self.trigger('files-changed');
    //         self.trigger('loading-canceled', fileId);
    //         return Promise.resolve();
    //     })
    // }

    moveFile(fileId, album) {
        var self = this;
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/file/move?fid=' + fileId + '&album=' + album;
            instancesPool.get('Basket').then(function (basket) {
                return requestApi(url, 'PUT', null, 'json').then(function (data) {
                    if (data.success) {
                        var newPublic = [];
                        var newPrivate = [];
                        if (album === 'private') {
                            var removedFile = {};
                            self.userFiles.public.map(function (item) {
                                if (item.id === fileId) {
                                    removedFile = item
                                }
                            })
                            var newPublic = self.userFiles.public.filter(function (item) {
                                return item.id !== fileId
                            });
                            var newPrivate = self.userFiles.private;
                            if (data.data.creditsInfo === "return") {
                                newPrivate.unshift(removedFile);
                                basket.changeCredit('photo', basket.credits.photo + data.data.creditsCount)
                            }
                        } else {
                            var removedFile = {};
                            self.userFiles.private.map(function (item) {
                                if (item.id === fileId) {
                                    removedFile = item
                                }
                            })
                            var newPrivate = self.userFiles.private.filter(function (item) {
                                return item.id !== fileId
                            });
                            var newPublic = self.userFiles.public
                            if (data.data.creditsInfo === "remove") {
                                newPublic.push(removedFile);
                                basket.changeCredit('photo', basket.credits.photo - data.data.creditsCount)
                            }
                        }

                        self.userFiles.public = newPublic;
                        self.userFiles.private = newPrivate;

                        self.trigger('files-changed')
                    } else if (data.err === 'Dont have enough credits') {
                        window.fetchPopupData({
                            popupType: "CreditsExchange", 
                            props: {
                                type: "photo",
                                onExchange: function () {
                                    self.moveFile(fileId, album)
                                }
                            }
                        })
                    }
                })
            })
        }
    }

    setPrimary(link) {
        var self = this;
        self.photo = link;
        self.trigger('photoChanged')
    }

    authorization(id, token) {
        var self = this;
        // lifehack
        return new Promise(function (resolve, reject) {
            setCookie('logid', id);
            setCookie('oncelogged', id);
            setCookie('login_key', token);
            // self.trigger('authorization');
            instancesPool._clearForLogin();
            window.udapp.globalController.initHeader();
            resolve();
        });
    }

    login(login, password) {
        var self = this;
        return requestApi('/auth/login', 'POST', { login, password }, 'json', 'json').then(
            function (response) {

                if (response.success) {
                    return self.authorization(response.uid, response.token);
                } else {
                    return Promise.reject(response.err);
                }
            }
        )
    }

    doLoginByPassKey(passKey) {
        return requestApi2('/next/user/doLoginByPassKey', 'POST', { passKey }).then(response => response)
    }

    unsetSoftDisable(userId) {
        return requestApi2('/next/user/unsetSoftDisable', 'POST', { userId }).then(response => response)
    }

    getQuizResults(userId) {
        return requestApi2(`/next/${userId}/quiz/quizResults`).then(response => response)
    }

    saveUserPasswordFromQuiz(userId, pass) {
        return requestApi2(`/next/${userId}/quiz/saveUserPasswordFromQuiz`, 'POST', { pass }).then(response => response)
    }

    logout() {
        return new Promise(function (resolve, reject) {
            Cookies.set('logid', 0, { expires: -1, path: '/', domain: '.uadreams.com' });
            Cookies.set('login_key', 0, { expires: -1, path: '/', domain: '.uadreams.com' });
            instancesPool._clearForLogin();
            window.location.assign('/login');
            resolve();
        });
    }

    changePassword(data) {
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/password';
            return requestApi(url, 'POST', data, 'json', 'json').then(
                function (data) {
                    return Promise.resolve(data)
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }

    changeEmail(data) {
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/email';
            return requestApi(url, 'POST', data, 'json', 'json').then(
                function (data) {
                    return Promise.resolve(data)
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }

    confirmEmailCode(data) {
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/email/confirm?code=' + data;
            return requestApi(url, 'POST', null, 'json').then(
                function (data) {
                    return Promise.resolve(data)
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }

    resendCode() {
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/email/resendCode';
            return requestApi(url, 'POST', null, 'json').then(
                function () {
                    return Promise.resolve()
                }
            )
        }
    }

    useMarketingOffer(offerId) {
        const userId = getCookie('logid');
        if (userId > 0) {
            const url = `/user/${userId}/marketing/useOffer?offerId=${offerId}`

            return requestApi(url, 'POST', null, 'json').then(
                function (data) {
                    return Promise.resolve(data)
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }

    shownMarketingPopup({ offerId, type }) {
        const userId = getCookie('logid');
        if (userId > 0) {
            const url = `/user/${userId}/marketing/shownPopup?offerId=${offerId}&type=${type}`

            return requestApi(url, 'POST', null, 'json').then(
                function (data) {
                    return Promise.resolve(data)
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }
    setTripsValue(data, id, air) {
        var userId = getCookie('logid');
        var url = '/support/sendTrips?air=' + air
        if (userId > 0) {
            url = '/user/' + id + '/support/sendTrips?air=' + air
        }
        return requestApi(url, 'POST', data, 'json', 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }

    closeBottomOnline(data) {
        this.trigger('close-bottom-online');
    };
    animateCredits(data) {
        let userId = getCookie('logid');
        if (userId > 0) {
            this.trigger('isAnimateCredits', data);
        }
    }
    loadPrimary(fileId, body) {
        var self = this;
        var userId = getCookie('logid');
        if (userId <= 0) {
            return
        }
        var url = '/user/' + userId + '/file/primary?fid=' + fileId;
        return requestApi(url, 'PUT', body, 'json', 'json').then(function (resp) {
            if (resp.success) {
                self.setPrimary(resp.primary)
            }
            return Promise.resolve(resp)
        })
    }
    editPhoto(fileId, body) {
        var self = this;
        var userId = getCookie('logid');
        if (userId <= 0) {
            return
        }
        var url = '/user/' + userId + '/file/edit?fid=' + fileId;
        return requestApi(url, 'PUT', body, 'json', 'json').then(function (data) {
            return data
        })
    }

    getNeedComleteInfo(type) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/checkProfileInfo';
        return new Promise(function (resolve) {
            if (self.needInfoData && !type) {
                resolve(self.needInfoData)
            } else {
                requestApi(url, 'GET', null, 'json').then(function (data) {
                    self.needInfoData = data
                    resolve(self.needInfoData)
                })
            }
        })
    }

    setFullProc(data) {
        var self = this;
        self.fullproc = data.fullproc;
        self.infoBlock = data.infoBlock;
        self.trigger('fullproc-changed')
    }

    getSeoPosts() {
        let url = '/seo/posts';
        return requestApi(url, 'GET', null, 'json').then(
            function (data) {
                // console.log('data', data);
                return Promise.resolve(data);
            }, function (error) {
                // console.log('error', error);
                return Promise.reject(error);
            }
        );
    }

    //deprecated
    getFlow(girlId) {
        var url = '/ladies/forFlow?ids=[' + girlId + ']';
        return requestApi(url, 'GET', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        )
    }

    static setSession({ logid, login_key, uniqkey, uniqkeyBrowser } = {}) {

        if (!logid || !login_key || !uniqkey || !uniqkeyBrowser)
            throw Error('Empty session argument');

        const domain = isEnvDevelopment ? '' : '.uadreams.com';

        setCookieLearn('logid', logid, { 'path': '/', domain });
        setCookieLearn('login_key', login_key, { 'path': '/', domain });
        setCookieLearn('oncelogged', logid, { 'path': '/', domain });
        setCookieLearn('user_auth', '1', { 'path': '/', domain });
    }

    static async authFlow(passKey) {
        try {

            if (!passKey)
                throw Error('Empty pass key');

            const { ladyInfo, email, session, setCookies, goTo } = await requestApi(`/flow/${passKey}`, 'GET', null, 'json');


            // auth
            User.setSession(session);

            if (setCookies?.length > 0) {
                setCookies.forEach(({ name, value, options }) => setCookieLearn(name, value, options));
            }

            // create responce for flow registration page
            return {
                userEmail: email,
                girlId: ladyInfo.id,
                girlName: ladyInfo.name,
                myself: ladyInfo.descriptions[0].content.replaceAll('&#111;', 'o').replaceAll('&#108;', 'l'),
                goals: ladyInfo.descriptions[1].content.replaceAll('&#111;', 'o').replaceAll('&#108;', 'l'),
                horizontal: ladyInfo.photos.find(photo => photo.type === 'horizontal') || {},
                vertical: ladyInfo.photos.filter(photo => photo.type === 'vertical') || [],
                goTo,
            }

        } catch (e) {
            console.error(e);
            return false;
        }
    }

    static async saveFlowPassword(password) {
        try {
            const result = await requestApi(`/flow/password`, 'POST', { password }, 'json', 'json');
            return result;
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    shownTopUpSuccess(pref) {
        const userId = getCookie('logid');
        if (userId > 0) {
            const url = `/user/${userId}/chat/shownTopUpSuccess?pref=${pref}`

            return requestApi(url, 'POST', null, 'json').then(
                function (data) {
                    return Promise.resolve(data)
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }


    getNews(type, page, lastDate) {
        let self = this;
        if (self.id > 0) {
            if (type == '') {
                type = `all`;
            }
            if (page == 0) {
                page = 1;
            }
            let date = '';
            if (lastDate != 0) {
                date = `&lastDate=${lastDate}`;
            }
            let url = '/user/' + self.id + '/news?type=' + type + '&page=' + page + date;
            return requestApi(url, 'GET', null, 'json').then(
                function (data) {
                    return Promise.resolve(data);
                },
                function (error) {
                    console.log(error);
                    return Promise.reject(error);
                })
        };
    }

    getCarouselProfile() {
        let self = this;
        if (self.id > 0) {
            let url = '/user/' + self.id + '/news/carousel';
            return requestApi(url, 'get', null, 'json').then(
                function (data) {
                    return Promise.resolve(data);
                },
                function (error) {
                    console.log(error);
                    return Promise.reject(error);
                })
        }
    }

    getEasterList(){
        let self = this;
        if (self.id > 0) {
            let url = '/user/' + self.id + '/marketing/easter2022/chatlist';
            return requestApi(url, 'get', null, 'json').then(
                function (data) {
                    return Promise.resolve(data);
                }, 
                function (error) {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        } else {
            return Promise.resolve([]);
        }
    }

}
