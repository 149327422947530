import '../css/index.scss';
import React from 'react';
import { Router } from './core/router';
import LoadingModules from './core/loadingModules';
import { closeReactPopup, initReactPopup } from './helpers/functions';
import { instancesPool } from './old/models';

// init old models logic
window.instancesPool = instancesPool;  
window.initReactPopup = initReactPopup;
window.closeReactPopup = closeReactPopup;
window.readyPaths = []

// временнное решение для работы пагинации в SEO галерее
if(window.location.href.includes('?next=')) {
    var queryString = window.location.href.split('?')[1].replace(/\D/g,'');
    window._seoGalleryPage = +queryString / 10 || 0;
}

var udapp = {};
window.udapp = udapp; 
udapp.router = new Router(!!window.reactBuild);

udapp.loadingModules = new LoadingModules();

// 404
udapp.router.use(
    ['/404'], () => import('./controllers/page-404')
);

// INIT VIDEO STAT
window.instancesPool.get('VideoStatistic')

// set handlers for paths



udapp.router.use(['/login/key-:passKey'], () => import('./controllers/login'));

udapp.router.use(
    [
        '/quiz-results/:search',
        '/personal-match',
    ],
    () => import('./controllers/gallery')
);


// MediaBox
udapp.router.use(['/mediabox/', '/mediabox/send/', '/mediabox/send/:gid', '/mediabox/send/:gid/:fileId', '/mediabox/success/:gid/:count', '/mediabox/to-ladies/', '/mediabox/to-lady/', '/mediabox/to-lady/:gid'], () => import('./controllers/media-box'));
 
// Mailbox
udapp.router.use(['/mailbox/', '/mailbox/folder/:folderId', '/mailbox/lady/:ladyId',
    '/mailbox/sent/', '/mailbox/drafts/', '/mailbox/all/',
    '/mailbox/history/', '/mailbox/history/:ladyId',
    '/mailbox/editletter/:mailId', '/mailbox/complete/:mailId',
    '/mailbox/compose/', '/mailbox/compose/:ladyId/attach/:fileId', '/mailbox/compose/:ladyId/:letterType',
    '/mailbox/readletter/:mailId', '/mailbox/sent/readletter/:mailId', '/mailbox/wink/:winkId', '/mailbox/readpromo/:promoId'], () => import('./controllers/mailbox-new'));

//Video chat
udapp.router.use([
    '/startvideochat/:roomId', '/startvideochat/:roomId/:type'
], () => import('./controllers/uservideochat'));

let staticPagePaths = [
    '/youtubeads', '/thanks-yt',
    '/quiz',
];


udapp.router.use(staticPagePaths, () => import('./controllers/static'));

udapp.router.use(['/registration-flow/:code'], () => import('./controllers/flow'));

udapp.router.init = udapp.router.init.bind(udapp.router);
window.addEventListener('DOMContentLoaded', udapp.router.init);

if (window.isRPX) {
    Promise.all([
        import('./../css/index_rpx.scss'),
        import('./controllers/global')
    ]).then(([index_rpx, res]) => {
        window.udapp.globalController = new res.Global;
        window.udapp.router.addListener('changeState', event => {
            window.udapp.globalController.setState(
                window.udapp.router.getPathForController()
            );
        });
        window.udapp.globalController.initializeAllComponents();
        window.udapp.globalController.afterLoadingApplication();
        import('./controllers/rpx').then(
            rpx => {
                window._rpxjs = new rpx.default;
            }
        )
    });
}
