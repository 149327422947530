import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";
import { instancesPool } from "../old/models";

export const timeZones = [
    {
        "id": "1",
        "title": "Afghanistan - Kabul",
        "short_title": "Afghanistan - Kabul",
        "min": "270",
        "mc": 4.5
    },
    {
        "id": "2",
        "title": "Algeria - Algiers",
        "short_title": "Algeria - Algiers",
        "min": "60",
        "mc": 1
    },
    {
        "id": "3",
        "title": "Argentina - Buenos Aires",
        "short_title": "Argentina - Buenos Aires",
        "min": "-180",
        "mc": -3
    },
    {
        "id": "4",
        "title": "Australia - Australian Capital Territory - Canberra",
        "short_title": "Australia - Canberra",
        "min": "660",
        "mc": 11
    },
    {
        "id": "5",
        "title": "Australia - New South Wales - Sydney",
        "short_title": "Australia - Sydney",
        "min": "660",
        "mc": 11
    },
    {
        "id": "6",
        "title": "Australia - Northern Territory - Darwin",
        "short_title": "Australia - Darwin",
        "min": "570",
        "mc": 9.5
    },
    {
        "id": "7",
        "title": "Australia - Queensland - Brisbane",
        "short_title": "Australia - Brisbane",
        "min": "600",
        "mc": 10
    },
    {
        "id": "8",
        "title": "Australia - South Australia - Adelaide",
        "short_title": "Australia - Adelaide",
        "min": "630",
        "mc": 10.5
    },
    {
        "id": "9",
        "title": "Australia - Victoria - Melbourne",
        "short_title": "Australia - Melbourne",
        "min": "660",
        "mc": 11
    },
    {
        "id": "10",
        "title": "Australia - Western Australia - Perth",
        "short_title": "Australia - Perth",
        "min": "480",
        "mc": 8
    },
    {
        "id": "11",
        "title": "Austria - Vienna - Vienna",
        "short_title": "Austria - Vienna",
        "min": "60",
        "mc": 1
    },
    {
        "id": "12",
        "title": "Bahamas - Nassau",
        "short_title": "Bahamas - Nassau",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "13",
        "title": "Bangladesh - Dhaka",
        "short_title": "Bangladesh - Dhaka",
        "min": "360",
        "mc": 6
    },
    {
        "id": "14",
        "title": "Belarus - Minsk",
        "short_title": "Belarus - Minsk",
        "min": "180",
        "mc": 3
    },
    {
        "id": "15",
        "title": "Belgium - Brussels",
        "short_title": "Belgium - Brussels",
        "min": "60",
        "mc": 1
    },
    {
        "id": "16",
        "title": "Bolivia - La Paz",
        "short_title": "Bolivia - La Paz",
        "min": "-240",
        "mc": -4
    },
    {
        "id": "17",
        "title": "Brazil - Distrito Federal - Brasilia",
        "short_title": "Brazil - Brasilia",
        "min": "-180",
        "mc": -3
    },
    {
        "id": "18",
        "title": "Brazil - Rio de Janeiro - Rio de Janeiro",
        "short_title": "Brazil - Rio de Janeiro",
        "min": "-180",
        "mc": -3
    },
    {
        "id": "19",
        "title": "Brazil - Sao Paulo - Sao Paulo",
        "short_title": "Brazil - Sao Paulo",
        "min": "-180",
        "mc": -3
    },
    {
        "id": "20",
        "title": "Bulgaria - Sofia",
        "short_title": "Bulgaria - Sofia",
        "min": "120",
        "mc": 2
    },
    {
        "id": "21",
        "title": "Canada - Alberta - Calgary",
        "short_title": "Canada - Calgary",
        "min": "-420",
        "mc": -7
    },
    {
        "id": "22",
        "title": "Canada - Alberta - Edmonton",
        "short_title": "Canada - Edmonton",
        "min": "-420",
        "mc": -7
    },
    {
        "id": "23",
        "title": "Canada - British Columbia - Vancouver",
        "short_title": "Canada - Vancouver",
        "min": "-480",
        "mc": -8
    },
    {
        "id": "24",
        "title": "Canada - Manitoba - Winnipeg",
        "short_title": "Canada - Winnipeg",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "25",
        "title": "Canada - Newfoundland and Labrador - St. John's",
        "short_title": "Canada - St. John's",
        "min": "-210",
        "mc": -3.5
    },
    {
        "id": "26",
        "title": "Canada - Nova Scotia - Halifax",
        "short_title": "Canada - Halifax",
        "min": "-240",
        "mc": -4
    },
    {
        "id": "27",
        "title": "Canada - Ontario - Ottawa",
        "short_title": "Canada - Ottawa",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "28",
        "title": "Canada - Ontario - Toronto",
        "short_title": "Canada - Toronto",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "29",
        "title": "Canada - Quebec - Montreal",
        "short_title": "Canada - Montreal",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "30",
        "title": "Chile - Santiago",
        "short_title": "Chile - Santiago",
        "min": "-180",
        "mc": -3
    },
    {
        "id": "31",
        "title": "China - Beijing",
        "short_title": "China - Beijing",
        "min": "480",
        "mc": 8
    },
    {
        "id": "32",
        "title": "China - Shanghai",
        "short_title": "China - Shanghai",
        "min": "480",
        "mc": 8
    },
    {
        "id": "33",
        "title": "Colombia - Bogota",
        "short_title": "Colombia - Bogota",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "34",
        "title": "Congo Dem.Rep. - Kinshasa",
        "short_title": "Congo Dem.Rep. - Kinshasa",
        "min": "60",
        "mc": 1
    },
    {
        "id": "35",
        "title": "Croatia - Zagreb",
        "short_title": "Croatia - Zagreb",
        "min": "60",
        "mc": 1
    },
    {
        "id": "36",
        "title": "Cuba - Havana",
        "short_title": "Cuba - Havana",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "37",
        "title": "Czech Republic - Prague",
        "short_title": "Czech Republic - Prague",
        "min": "60",
        "mc": 1
    },
    {
        "id": "38",
        "title": "Denmark - Copenhagen",
        "short_title": "Denmark - Copenhagen",
        "min": "60",
        "mc": 1
    },
    {
        "id": "39",
        "title": "Dominican Republic - Santo Domingo",
        "short_title": "Dominican Republic - Santo Domingo",
        "min": "-240",
        "mc": -4
    },
    {
        "id": "40",
        "title": "Egypt - Cairo",
        "short_title": "Egypt - Cairo",
        "min": "120",
        "mc": 2
    },
    {
        "id": "41",
        "title": "El Salvador - San Salvador",
        "short_title": "El Salvador - San Salvador",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "42",
        "title": "Estonia - Tallinn",
        "short_title": "Estonia - Tallinn",
        "min": "120",
        "mc": 2
    },
    {
        "id": "43",
        "title": "Ethiopia - Addis Ababa",
        "short_title": "Ethiopia - Addis Ababa",
        "min": "180",
        "mc": 3
    },
    {
        "id": "44",
        "title": "Fiji - Suva",
        "short_title": "Fiji - Suva",
        "min": "720",
        "mc": 12
    },
    {
        "id": "45",
        "title": "Finland - Helsinki",
        "short_title": "Finland - Helsinki",
        "min": "120",
        "mc": 2
    },
    {
        "id": "46",
        "title": "France - Paris",
        "short_title": "France - Paris",
        "min": "60",
        "mc": 1
    },
    {
        "id": "47",
        "title": "Germany - Berlin - Berlin",
        "short_title": "Germany - Berlin",
        "min": "60",
        "mc": 1
    },
    {
        "id": "48",
        "title": "Germany - Hesse - Frankfurt",
        "short_title": "Germany - Frankfurt",
        "min": "60",
        "mc": 1
    },
    {
        "id": "49",
        "title": "Ghana - Accra",
        "short_title": "Ghana - Accra",
        "min": "0",
        "mc": 0
    },
    {
        "id": "50",
        "title": "Greece - Athens",
        "short_title": "Greece - Athens",
        "min": "120",
        "mc": 2
    },
    {
        "id": "51",
        "title": "Guatemala - Guatemala",
        "short_title": "Guatemala - Guatemala",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "52",
        "title": "Honduras - Tegucigalpa",
        "short_title": "Honduras - Tegucigalpa",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "53",
        "title": "Hong Kong - Hong Kong",
        "short_title": "Hong Kong - Hong Kong",
        "min": "480",
        "mc": 8
    },
    {
        "id": "54",
        "title": "Hungary - Budapest",
        "short_title": "Hungary - Budapest",
        "min": "60",
        "mc": 1
    },
    {
        "id": "55",
        "title": "Iceland - Reykjavik",
        "short_title": "Iceland - Reykjavik",
        "min": "0",
        "mc": 0
    },
    {
        "id": "56",
        "title": "India - Delhi - New Delhi",
        "short_title": "India - New Delhi",
        "min": "330",
        "mc": 5.5
    },
    {
        "id": "57",
        "title": "India - Karnataka - Bangalore",
        "short_title": "India - Bangalore",
        "min": "330",
        "mc": 5.5
    },
    {
        "id": "58",
        "title": "India - Maharashtra - Mumbai",
        "short_title": "India - Mumbai",
        "min": "330",
        "mc": 5.5
    },
    {
        "id": "59",
        "title": "India - West Bengal - Kolkata",
        "short_title": "India - Kolkata",
        "min": "330",
        "mc": 5.5
    },
    {
        "id": "60",
        "title": "Indonesia - Jakarta Special Capital Region - Jakarta",
        "short_title": "Indonesia - Jakarta",
        "min": "420",
        "mc": 7
    },
    {
        "id": "61",
        "title": "Iran - Tehran",
        "short_title": "Iran - Tehran",
        "min": "210",
        "mc": 3.5
    },
    {
        "id": "62",
        "title": "Iraq - Baghdad",
        "short_title": "Iraq - Baghdad",
        "min": "180",
        "mc": 3
    },
    {
        "id": "63",
        "title": "Ireland - Dublin",
        "short_title": "Ireland - Dublin",
        "min": "0",
        "mc": 0
    },
    {
        "id": "64",
        "title": "Israel - Jerusalem",
        "short_title": "Israel - Jerusalem",
        "min": "120",
        "mc": 2
    },
    {
        "id": "65",
        "title": "Italy - Rome",
        "short_title": "Italy - Rome",
        "min": "60",
        "mc": 1
    },
    {
        "id": "66",
        "title": "Jamaica - Kingston",
        "short_title": "Jamaica - Kingston",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "67",
        "title": "Japan - Tokyo",
        "short_title": "Japan - Tokyo",
        "min": "540",
        "mc": 9
    },
    {
        "id": "68",
        "title": "Jordan - Amman",
        "short_title": "Jordan - Amman",
        "min": "120",
        "mc": 2
    },
    {
        "id": "69",
        "title": "Kazakhstan - Almaty",
        "short_title": "Kazakhstan - Almaty",
        "min": "360",
        "mc": 6
    },
    {
        "id": "70",
        "title": "Kenya - Nairobi",
        "short_title": "Kenya - Nairobi",
        "min": "180",
        "mc": 3
    },
    {
        "id": "71",
        "title": "Kiribati - Christmas Island - Kiritimati",
        "short_title": "Kiribati - Kiritimati",
        "min": "840",
        "mc": 14
    },
    {
        "id": "72",
        "title": "Kuwait - Kuwait City",
        "short_title": "Kuwait - Kuwait City",
        "min": "180",
        "mc": 3
    },
    {
        "id": "73",
        "title": "Lebanon - Beirut",
        "short_title": "Lebanon - Beirut",
        "min": "120",
        "mc": 2
    },
    {
        "id": "74",
        "title": "Madagascar - Antananarivo",
        "short_title": "Madagascar - Antananarivo",
        "min": "180",
        "mc": 3
    },
    {
        "id": "75",
        "title": "Malaysia - Kuala Lumpur",
        "short_title": "Malaysia - Kuala Lumpur",
        "min": "480",
        "mc": 8
    },
    {
        "id": "76",
        "title": "Mexico - Federal District - Mexico City",
        "short_title": "Mexico - Mexico City",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "77",
        "title": "Morocco - Casablanca",
        "short_title": "Morocco - Casablanca",
        "min": "60",
        "mc": 1
    },
    {
        "id": "78",
        "title": "Myanmar - Yangon",
        "short_title": "Myanmar - Yangon",
        "min": "390",
        "mc": 6.5
    },
    {
        "id": "79",
        "title": "Nepal - Kathmandu",
        "short_title": "Nepal - Kathmandu",
        "min": "345",
        "mc": 5.75
    },
    {
        "id": "80",
        "title": "Netherlands - Amsterdam",
        "short_title": "Netherlands - Amsterdam",
        "min": "60",
        "mc": 1
    },
    {
        "id": "81",
        "title": "New Zealand - Auckland",
        "short_title": "New Zealand - Auckland",
        "min": "780",
        "mc": 13
    },
    {
        "id": "82",
        "title": "Nicaragua - Managua",
        "short_title": "Nicaragua - Managua",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "83",
        "title": "Nigeria - Lagos",
        "short_title": "Nigeria - Lagos",
        "min": "60",
        "mc": 1
    },
    {
        "id": "84",
        "title": "Norway - Oslo",
        "short_title": "Norway - Oslo",
        "min": "60",
        "mc": 1
    },
    {
        "id": "85",
        "title": "Pakistan - Islamabad",
        "short_title": "Pakistan - Islamabad",
        "min": "300",
        "mc": 5
    },
    {
        "id": "86",
        "title": "Pakistan - Karachi",
        "short_title": "Pakistan - Karachi",
        "min": "300",
        "mc": 5
    },
    {
        "id": "87",
        "title": "Pakistan - Lahore",
        "short_title": "Pakistan - Lahore",
        "min": "300",
        "mc": 5
    },
    {
        "id": "88",
        "title": "Paraguay - Asuncion",
        "short_title": "Paraguay - Asuncion",
        "min": "-180",
        "mc": -3
    },
    {
        "id": "89",
        "title": "Peru - Lima - Lima",
        "short_title": "Peru - Lima - Lima",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "90",
        "title": "Philippines - Manila",
        "short_title": "Philippines - Manila",
        "min": "480",
        "mc": 8
    },
    {
        "id": "91",
        "title": "Poland - Warsaw",
        "short_title": "Poland - Warsaw",
        "min": "60",
        "mc": 1
    },
    {
        "id": "92",
        "title": "Portugal - Lisbon",
        "short_title": "Portugal - Lisbon",
        "min": "0",
        "mc": 0
    },
    {
        "id": "93",
        "title": "Puerto Rico - San Juan",
        "short_title": "Puerto Rico - San Juan",
        "min": "-240",
        "mc": -4
    },
    {
        "id": "94",
        "title": "Qatar - Doha",
        "short_title": "Qatar - Doha",
        "min": "180",
        "mc": 3
    },
    {
        "id": "95",
        "title": "Romania - Bucharest",
        "short_title": "Romania - Bucharest",
        "min": "120",
        "mc": 2
    },
    {
        "id": "96",
        "title": "Russia - Anadyr",
        "short_title": "Russia - Anadyr",
        "min": "720",
        "mc": 12
    },
    {
        "id": "97",
        "title": "Russia - Moscow",
        "short_title": "Russia - Moscow",
        "min": "180",
        "mc": 3
    },
    {
        "id": "98",
        "title": "Saudi Arabia - Riyadh",
        "short_title": "Saudi Arabia - Riyadh",
        "min": "180",
        "mc": 3
    },
    {
        "id": "99",
        "title": "Serbia - Belgrade",
        "short_title": "Serbia - Belgrade",
        "min": "60",
        "mc": 1
    },
    {
        "id": "100",
        "title": "Singapore - Singapore",
        "short_title": "Singapore - Singapore",
        "min": "480",
        "mc": 8
    },
    {
        "id": "101",
        "title": "South Africa - Cape Town",
        "short_title": "South Africa - Cape Town",
        "min": "120",
        "mc": 2
    },
    {
        "id": "102",
        "title": "South Africa - Johannesburg",
        "short_title": "South Africa - Johannesburg",
        "min": "120",
        "mc": 2
    },
    {
        "id": "103",
        "title": "South Korea - Seoul",
        "short_title": "South Korea - Seoul",
        "min": "540",
        "mc": 9
    },
    {
        "id": "104",
        "title": "Spain - Barcelona - Barcelona",
        "short_title": "Spain - Barcelona",
        "min": "60",
        "mc": 1
    },
    {
        "id": "105",
        "title": "Spain - Madrid",
        "short_title": "Spain - Madrid",
        "min": "60",
        "mc": 1
    },
    {
        "id": "106",
        "title": "Sudan - Khartoum",
        "short_title": "Sudan - Khartoum",
        "min": "180",
        "mc": 3
    },
    {
        "id": "107",
        "title": "Sweden - Stockholm",
        "short_title": "Sweden - Stockholm",
        "min": "60",
        "mc": 1
    },
    {
        "id": "108",
        "title": "Switzerland - Zurich",
        "short_title": "Switzerland - Zurich",
        "min": "60",
        "mc": 1
    },
    {
        "id": "109",
        "title": "Taiwan - Taipei",
        "short_title": "Taiwan - Taipei",
        "min": "480",
        "mc": 8
    },
    {
        "id": "110",
        "title": "Tanzania - Dar es Salaam",
        "short_title": "Tanzania - Dar es Salaam",
        "min": "180",
        "mc": 3
    },
    {
        "id": "111",
        "title": "Thailand - Bangkok",
        "short_title": "Thailand - Bangkok",
        "min": "420",
        "mc": 7
    },
    {
        "id": "112",
        "title": "Turkey - Ankara",
        "short_title": "Turkey - Ankara",
        "min": "180",
        "mc": 3
    },
    {
        "id": "113",
        "title": "Turkey - Istanbul",
        "short_title": "Turkey - Istanbul",
        "min": "180",
        "mc": 3
    },
    {
        "id": "114",
        "title": "U.S.A. - Alaska - Anchorage",
        "short_title": "U.S.A. - Anchorage",
        "min": "-480",
        "mc": -8
    },
    {
        "id": "115",
        "title": "U.S.A. - Arizona - Phoenix",
        "short_title": "U.S.A. - Phoenix",
        "min": "-420",
        "mc": -7
    },
    {
        "id": "116",
        "title": "U.S.A. - California - Los Angeles",
        "short_title": "U.S.A. - Los Angeles",
        "min": "-480",
        "mc": -8
    },
    {
        "id": "117",
        "title": "U.S.A. - California - San Francisco",
        "short_title": "U.S.A. - San Francisco",
        "min": "-480",
        "mc": -8
    },
    {
        "id": "118",
        "title": "U.S.A. - Colorado - Denver",
        "short_title": "U.S.A. - Denver",
        "min": "-420",
        "mc": -7
    },
    {
        "id": "119",
        "title": "U.S.A. - District of Columbia - Washington DC",
        "short_title": "U.S.A. - Washington DC",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "120",
        "title": "U.S.A. - Florida - Miami",
        "short_title": "U.S.A. - Miami",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "121",
        "title": "U.S.A. - Georgia - Atlanta",
        "short_title": "U.S.A. - Atlanta",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "122",
        "title": "U.S.A. - Hawaii - Honolulu",
        "short_title": "U.S.A. - Honolulu",
        "min": "-600",
        "mc": -10
    },
    {
        "id": "123",
        "title": "U.S.A. - Illinois - Chicago",
        "short_title": "U.S.A. - Chicago",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "124",
        "title": "U.S.A. - Indiana - Indianapolis",
        "short_title": "U.S.A. - Indianapolis",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "125",
        "title": "U.S.A. - Louisiana - New Orleans",
        "short_title": "U.S.A. - New Orleans",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "126",
        "title": "U.S.A. - Massachusetts - Boston",
        "short_title": "U.S.A. - Boston",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "127",
        "title": "U.S.A. - Michigan - Detroit",
        "short_title": "U.S.A. - Detroit",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "128",
        "title": "U.S.A. - Minnesota - Minneapolis",
        "short_title": "U.S.A. - Minneapolis",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "129",
        "title": "U.S.A. - Nevada - Las Vegas",
        "short_title": "U.S.A. - Las Vegas",
        "min": "-480",
        "mc": -8
    },
    {
        "id": "135",
        "title": "U.S.A. - New York",
        "short_title": "U.S.A. - New York",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "136",
        "title": "U.S.A. - Ohio - Columbus",
        "short_title": "U.S.A. - Columbus",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "137",
        "title": "U.S.A. - Pennsylvania - Philadelphia",
        "short_title": "U.S.A. - Philadelphia",
        "min": "-300",
        "mc": -5
    },
    {
        "id": "138",
        "title": "U.S.A. - Texas - Dallas",
        "short_title": "U.S.A. - Dallas",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "139",
        "title": "U.S.A. - Texas - Houston",
        "short_title": "U.S.A. - Houston",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "140",
        "title": "U.S.A. - Utah - Salt Lake City",
        "short_title": "U.S.A. - Salt Lake City",
        "min": "-420",
        "mc": -7
    },
    {
        "id": "141",
        "title": "U.S.A. - Washington - Seattle",
        "short_title": "U.S.A. - Seattle",
        "min": "-480",
        "mc": -8
    },
    {
        "id": "142",
        "title": "Ukraine - Kyiv",
        "short_title": "Ukraine - Kyiv",
        "min": "120",
        "mc": 2
    },
    {
        "id": "143",
        "title": "United Arab Emirates - Dubai",
        "short_title": "United Arab Emirates - Dubai",
        "min": "240",
        "mc": 4
    },
    {
        "id": "144",
        "title": "United Kingdom - England - London",
        "short_title": "United Kingdom - London",
        "min": "0",
        "mc": 0
    },
    {
        "id": "146",
        "title": "Uzbekistan - Tashkent",
        "short_title": "Uzbekistan - Tashkent",
        "min": "300",
        "mc": 5
    },
    {
        "id": "147",
        "title": "Venezuela - Caracas",
        "short_title": "Venezuela - Caracas",
        "min": "-270",
        "mc": -4.5
    },
    {
        "id": "148",
        "title": "Vietnam - Hanoi",
        "short_title": "Vietnam - Hanoi",
        "min": "420",
        "mc": 7
    },
    {
        "id": "149",
        "title": "Zimbabwe - Harare",
        "short_title": "Zimbabwe - Harare",
        "min": "120",
        "mc": 2
    },
    {
        "id": "150",
        "title": "Slovak Republic - Bratislava",
        "short_title": "Slovak Republic - Bratislava",
        "min": "60",
        "mc": 1
    },
    {
        "id": "151",
        "title": "Canada - Saskatchewan - Regina",
        "short_title": "Canada - Regina",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "152",
        "title": "Costa Rica - San Jose",
        "short_title": "Costa Rica - San Jose",
        "min": "-360",
        "mc": -6
    },
    {
        "id": "153",
        "title": "Singapore - Singapore",
        "short_title": "Singapore - Singapore",
        "min": "540",
        "mc": 9
    }
]

export class Times extends CustomEventTarget {
    promiseCreateInstance () {
        return new Promise((resolve, reject) => {
                instancesPool.get('User').then(
                    (user) => {
                    var self = this;
                    let userId = getCookie('logid');
                    let url = '/user/' + userId + '/timeZones';
                    requestApi(url, 'GET', null, 'json').then(function (data) {
                        self.timeZones = data;
                        self.currentTimeZoneId = user.currentTimeZone;
    
                        resolve();
                    }, console.warn)
                    }, reject
                ).catch((error) => {
                    this.currentTimeZoneId = 135; // hack for enable registration
                    console.error(error);
                });
            }
        );
    };
    setTimeZone (date, timeZone) {
        var localTimezoneOffset = date.getTimezoneOffset();
        var diffOffsetMin = parseInt(timeZone.min) - ((-1) * localTimezoneOffset);
        date.setMilliseconds(diffOffsetMin * 60000);
        return date;
    };
    getTimeZone (timeZoneId) {
        var timeZone = this.timeZones.filter(
            function (timeZone) {
                return timeZone.id == timeZoneId;
            }
        );
    
        if (timeZone.length == 1) {
            return timeZone[0];
        }
    
        return {};
    }
    getCurrentTimeZone () {
        return this.getTimeZone(this.currentTimeZoneId);
    };
    getUserTimeNow () {
        return this.setTimeZone((new Date), this.getCurrentTimeZone());
    };
    getUkrainTimeNow () {
        return this.setTimeZone((new Date), this.getTimeZone(142));
    };
    getISOString (date) {
        var strDate = date.toLocaleString('en-GB');
        var arrayDate = strDate.split(/\D/);
        return arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0] + " " + arrayDate[4] + ":" + arrayDate[5] + ":" + arrayDate[6];
    }
    changeTimeZone (timeZoneId) {
        var self = this;
        return new Promise(function (resolve, reject) {
    
            if (timeZoneId <= 0) {
                return reject('ERROR:Class UDTime, method changeTimeZone not set argument "timeZoneId"');
            }
    
            if (self.currentTimeZoneId == timeZoneId) {
                return resolve();
            }
            var userId = getCookie('logid');
           var url = '/user/' + userId + '/changeTimeZone?newTimezone=' + timeZoneId;
            requestApi(url, 'POST', null, 'json').then(function (res) {
                if(res.success) {
                    self.currentTimeZoneId = timeZoneId;
                    self.trigger('changed');
                    return resolve();
                }
            })
        })
    };
    intTimeToString (intTime, dateTo, delay, type) {
        var mobile = window.innerWidth <= 768;
        var stringTime = null;
        var labelText = '';
        if(type === 'soon'){
            labelText = 'online in '
        }
        if(delay) {
            intTime = intTime + (delay * 60000);
        }
    
        if (intTime > 60000) {
            
            // время до чата меньше часа - пишем минуты
            if (intTime <= 3600000) {
                if(mobile && type !== 'invitation'){
                    stringTime = " ".concat(labelText, Math.round(intTime / 60000), " min");
                }else {
                    stringTime = " ".concat(labelText, Math.round(intTime / 60000), " minutes");
                }
                // время до чата меньше 8 часов - пишем часы
            } else if (intTime > 3600000 && intTime < 28800000) {
                var timeNum = Math.round(intTime / 3600000);
                if(timeNum === 1){
                    // время до чата 1 час
                    stringTime = " ".concat(labelText, timeNum, " hour");
                }else{
                    // время до чата больше 1 часа
                    stringTime = " ".concat(labelText, timeNum, " hours");
                }
                // stringTime = " ".concat(Math.round(intTime / 3600000), " hour(s)");
    
                // время до чата больше 8 часов
            } else if (intTime > 28800000 && intTime < 86400000) {
                var timeZone = this.getCurrentTimeZone();
                var currentDateForUaerTimeZone = this.setTimeZone((new Date), timeZone);
    
                if (currentDateForUaerTimeZone.getDay() < dateTo.getDay()) {
                    if (type === 'invitation') {
                        stringTime = 'tomorrow';
                    }else {
                        stringTime = 'online tomorrow';
                    }
                } else {
                    stringTime = " ".concat(labelText, Math.round(intTime / 3600000), " hours");
                }
            } else if (type === 'invitation'){
                stringTime = 'soon';
            }else{
                stringTime = 'online soon';
            }
        } else {
            stringTime = "on the way";
        }
    
        return stringTime;
    };
}
