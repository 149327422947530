let userApiUrl = null;
let userApiV2Url = null;

userApiUrl='https://api.uadreams.com'
if(window.location.href.includes('redesign0.uadreams.com')) {
    userApiUrl = 'https://api0.uadreams.com'
} else if(window.location.href.includes('redesigndev.uadreams.com')) {
    userApiUrl = 'https://apidev.uadreams.com'
} else if(window.location.href.includes('redesigndev2.uadreams.com')) {
    userApiUrl = 'https://apidev2.uadreams.com'
} 

if (window.location.href.includes('localhost') || window.location.href.includes('testzone.uadreams.com')) {
    userApiUrl = localStorage.userApiUrl || 'https://apijenkins.uadreams.com';
    userApiV2Url = localStorage.userApiV2Url || userApiUrl;
}

export const devApiUrl = userApiUrl || 'https://api.uadreams.com';
export const devApiV2Url = userApiV2Url || userApiUrl;
export const isEnvProduction = process.env.NODE_ENV === 'production';
export const isEnvDevelopment = process.env.NODE_ENV === 'development';