import {StaticPagesMethods} from './../models/StaticPagesMethods';
import {User} from './../models/user';
import {SupportPage} from './../models/support-page';
import {Times} from './../models/times';
import {Winks} from './../models/winks';
import {ApplicationState} from './../models/application-state';
import {Basket} from './../models/basket';
import {Billing} from './../models/billing';
import {Captcha} from './../models/captcha';
import {Files} from './../models/files';
import {GiftsMinPrice} from './../models/gifts-min-price';
import {Invitations} from './../models/invitations';
import {Ladies} from './../models/ladies';
import {LadiesStatus} from './../models/ladies-status';
import {Lady} from './../models/lady';
import {LadyMedias} from './../models/lady-medias';
import {MailBox} from './../models/mailbox';
import {MainMenu} from './../models/main-menu';
import {Marketing} from './../models/marketing';
import {MediaBox} from './../models/media-box';
import {NotificationSettings} from './../models/notification-settings';
import {OfficeWebCams} from './../models/office-web-cams';
import {OnlineSoonLadies} from './../models/online-soon-ladies';
import {DataOptions} from './../models/options';
import {PhoneConfirm} from './../models/phone-confirm';
import {PromoBanners} from './../models/promo-banners';
import {QuickSupport} from './../models/quick-support';
import {ReactPopup} from './../models/react-popup';
import {SearchFormData} from './../models/search-form-data';
import {ShopNavigation} from './../models/shop-navigation';
import {ShopPage} from './../models/shop-page';
import {SiteIdeas} from './../models/site-ideas';
import {CurrentMarketing} from './../models/currentMarketing';
import InstancesPool from "../helpers/instancesPool";
import { VideoStatistic } from '../models/video-stat';
import {App} from '../models/app'

export const instancesPool = new InstancesPool({
    User, StaticPagesMethods, SupportPage, App,
    Times,Winks,ApplicationState,Basket,Billing,Captcha,Files,GiftsMinPrice,Invitations,Ladies,LadiesStatus,Lady,
    LadyMedias,MailBox,MainMenu,Marketing,MediaBox,NotificationSettings,OfficeWebCams,OnlineSoonLadies,DataOptions,
    PhoneConfirm,PromoBanners,QuickSupport,ReactPopup,SearchFormData,ShopNavigation,ShopPage,SiteIdeas, CurrentMarketing, VideoStatistic 
});