import { CustomEventTarget } from "../helpers/custom-classes";
import { getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";

export class NotificationSettings extends CustomEventTarget {
    promiseCreateInstance() {
        var userId = getCookie('logid');
        var self = this;

        if (userId > 0) {
            var url = '/user/' + userId + '/notification/settings';
            return requestApi(url, 'GET', null, 'json').then(function (res) {
                var data = res.data;
                for (var k in data) {
                    self[k] = data[k];
                }
                return Promise.resolve();
            }, function (error) {
                return Promise.reject(error);
            })
        } else {
            return Promise.reject('No auth user');
        }
    }

    update (state) {
        var userId = getCookie('logid');
        var self = this;
    
        if (userId > 0) {
            var url = '/user/' + userId + '/notification/settings';
            return requestApi(url, 'POST', state, 'json', 'json').then(
                function (res) {
                    for (var k in state) {
                        self[k] = state[k];
                    }
                    self.trigger('reload')
                    return Promise.resolve();
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        } else {
            return Promise.reject('No auth user');
        }
    }
} 