import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";

export class Captcha extends CustomEventTarget {
    promiseCreateInstance () {
        return Promise.resolve();
    }
    getCaptcha () {
        var self = this;
        return requestApi('/captcha', 'GET', null, 'json').then(function (props) {
            self.data = {};
            for (var k in props) {
                self.data[k] = props[k];
            }
            return Promise.resolve(self.data);
        }, function (error) {
            return Promise.reject(error);
        });
    }
    checkCaptcha (key, text) {
        var url = '/captcha/check?key=' + key + '&word=' + text;
        return requestApi(url, 'GET', null, 'json').then(function (props) {
            return Promise.resolve(props);
        }, function (error) {
            return Promise.reject(error);
        });
    }
}