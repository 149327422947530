import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { $, getCookie } from "../helpers/functions";

export class MainMenu extends CustomEventTarget {
    dropPage = '';

    promiseCreateInstance () {
        var userId = getCookie('logid')
        var url = '/menu/data';
    
        var self = this;
    
        if (userId > 0) {
            url = '/user/' + userId + '/menu/data';
        }
    
        return requestApi(url, 'GET', null, 'json').then(
            function (props) {
                for (var k in props) {
                    self[k] = props[k];
                }
                var menuInfoready = true;
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }

    updateCounter () {
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/getUserCounts';
        return requestApi(url, 'GET', null, 'json').then(function(resp) {
            self.counts = resp
            self.trigger('counts-changed')
        });
    };
    
    setDropPage (page) {
        this.dropPage = page;
        this.trigger('menuChanged');
    }
    
    getDropPage () {
        return this.dropPage
    }
    
    setSearchResult (total) {
        this.searchResult = total
        this.trigger('MenuSearchResult');
    
    }
    
    getSearchResult () {
        return this.searchResult
    }
    
    mobileClose () {
        this.trigger('MenuClose');
    }
    
    searchMobile (search) {
        return new Promise(
            function (resolve, reject) {
                $.post("/templates/json/girs_list_by_name_or_id.rpx", {
                    params: search,
                    rnd: Math.random()
                }, function (data) {
                    resolve(data)
                }, "json");
            }
        );
    }
    
    changeMobileHeader (newHeader) {
        if (newHeader === 'default') {
            $('.mob-logo').empty().append('<img src="/inc/images/new-logo-mobile.png" width="140px" height="16px">')
        } else {
            var text = newHeader[0].toUpperCase() + newHeader.slice(1);
            $('.mob-logo').empty().text(text)
        }
    }
}