import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";

export class PhoneConfirm extends CustomEventTarget {
    _confirmProps = {
        country: '',
        phone: '',
        format: '',
    }

    promiseCreateInstance() { return Promise.resolve()}

    getCaptch () {
        var self = this;
        return requestApi('/captcha', 'GET', null, 'json').then(function (props) {
            self.data = {};
            for (var k in props) {
                self.data[k] = props[k];
            }
            return Promise.resolve(self.data);
        }, function (error) {
            return Promise.reject(error);
        });
    }
    
    checkCaptchAndSendSMS (userId, data) {
        return requestApi('/phone/' + userId + '/send_code', 'POST', data, 'json', 'json').then(
            function (data) {
                return Promise.resolve(data)
            }, function (error) {
                return Promise.reject(error);
            }
        )
    }
    
    resendSMS (userId, data) {
        return requestApi('/phone/' + userId + '/resend_code', 'POST', data, 'json', 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
    
            }
        );
    }
    
    confirmNumber (userId, data) {
        return requestApi('/phone/' + userId + '/confirm_phone', 'POST', data, 'json', 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    
    abilitySMS (userId) {
        return requestApi('/phone/' + userId + '/ability_send_code', 'POST', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    
    getConfirmProps () {
        return this._confirmProps;
    }
    
    setConfirmProps (props) {
        this._confirmProps = Object.assign(this._confirmProps, props);
    }
}