import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";
import BillingService from "../api/BillingService";

export class Billing extends CustomEventTarget {
    promiseCreateInstance (currencyCode = 'EUR') {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/checkout/' + currencyCode;
        
        
        return BillingService.getCheckoutInfo(currencyCode).then( function(res){
            const data = res.data
            for (var k in data) {
                self[k] = data[k];
            }
    
            self['currencyCode'] = currencyCode;
            return Promise.resolve();

        })
        
        // return requestApi(url, 'GET', null, 'json').then(function(data) {
            

        //     for (var k in data) {
        //         self[k] = data[k];
        //     }
    
        //     self['currencyCode'] = currencyCode;
        //     return Promise.resolve();
        // });
        
    }
    saveAddress (data) {
        return new Promise(function(resolve, reject) {
            var userId = getCookie('logid');
            var url = '/user/' + userId + '/checkout/billingInfo';
            requestApi(url, 'POST', data, 'json', 'json').then(function(result){
                resolve(result);  
            });
        });
    }
    reload (currencyCode) {
        return this.promiseCreateInstance(currencyCode);
    }
    
    static checkPayBonuses () {
        return new Promise(function(resolve, reject) {
            var userId = getCookie('logid');
            var url = '/user/' + userId + '/checkout/check/bonusPay'
            requestApi(url, 'GET', null, 'json').then(function(result){
                if (result.success) {
                    resolve(result.goTo);
                } else {
                    reject(result.error);
                }
            });
            
        });
    }

    static checkBirthdayBon = function () {
        return new Promise(function(resolve, reject) {
            var userId = getCookie('logid');
            var url = '/user/' + userId + '/checkout/check/birthday';
            requestApi(url, 'GET', null, 'json').then(function(data){
                resolve(data.birthday);
            }, function(){resolve(false)})
        });
    }
}