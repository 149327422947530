import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { $, getCookie } from "../helpers/functions";
import { instancesPool } from "../old/models";

export class Files extends CustomCollection {
    filesList = [];

    promiseCreateInstance () {
        return Promise.resolve();
    };

    acceptFile (obj) {
        var self = this;
        var initialObj = obj;
        if(typeof obj === 'string') {
            obj = JSON.parse(obj)
        }
        var fromPage = window.location.pathname;
        if(window.location.href.includes('/women/')) {
            fromPage = 'lady-profile'
        } else if(window.location.href.includes('/mailbox')) {
            fromPage = 'mailbox'
        } else if(window.location.href.includes('/mediabox')) {
            fromPage = 'files-from-ladies'
        }
        var acceptBtnClass = '.media-accept-button-' + obj.id + '-' + obj.type;
        var acceptBtn = document.querySelectorAll(acceptBtnClass);
        acceptBtn.forEach(function(item) {item.classList.add('processed')})
        return new Promise(function(resolve, reject) {
            var userId = getCookie('logid')
            var url = '/user/' + userId + '/ladies/files/acceptMedia';
            var data = {
                "fileId": obj.id,
                "fileType": obj.type,
                "page": fromPage
            };
            requestApi(url, 'POST', data, 'json', 'json').then(function(response) {
                instancesPool.get('Basket').then(function(basket) {
                    if(response.success && response.status === 'accepted') {
                        if(obj.type === 'video') {
                            self.trigger('accept', {downLoadSrc: response.srcDl,  obj: obj, acceptedData: response})
                            self.changeAcceptedFile({downLoadSrc: response.srcDl,  obj: obj})
                        } else if(obj.type === 'photo') {
                            var resp = {
                                imgPreviewSrc:  response.preview,
                                imgPreviewSrcBig: response.previewBig,
                                imgSrc: response.src,
                            }
                            self.trigger('accept', Object.assign(resp, {obj: obj}))
                            self.changeAcceptedFile(Object.assign(resp, {obj: obj}))
                        } else if(obj.type === 'audio') {
                            var resp = {
                                src:  response.src,
                                fileSource: response.src,
                                downLoadSrc: response.srcDl,
                            }
                            self.trigger('accept', Object.assign(resp, {obj: obj}))
                            self.changeAcceptedFile(Object.assign(resp, {obj: obj}))
                        }
                        basket.loadCredits()
                    } else {
                        if(!response.success && response.msg === 'Not enougth credits') {
                            
                            function acceptCanceled() {
                                self.trigger('accept', {close: true, obj: obj})
                                self.changeAcceptedFile({close: true, obj:obj})
                                acceptBtn.forEach(function(item) {item.classList.remove('processed')})
                            }
                            
                            var mediaType = obj.type;
                            if(mediaType === 'audio') {
                                mediaType = 'photo'
                            }
                            window.fetchPopupData({
                                popupType: "CreditsExchange", 
                                props: {
                                    type: mediaType,
                                    onExchange: () => {
                                        self.acceptFile(initialObj)
                                    },
                                    onClose : () => {
                                        obj.needBuyMedia=true 
                                        acceptCanceled()
                                    }
                                }
                            })
                        } else {
                            console.log(response);
                        }
                    }
                })
            })
        }) 
    }
    
    changeAcceptedFile (data) {
        var self = this;
        var obj = data.obj;
        if(data.close) {
            var btn = document.querySelector('.media-accept-button-' + obj.id + '-' + obj.type)
            if (btn) {
                btn.classList.remove('processed', 'accept-media-after-change-react')
            }
            return true
        }
        
        // принятие файла при получении по ID
        var changedFile = false;
        var newFilesList = self.filesList.map(function(file) {
            if (file.id === +obj.id) {
                var newFile = file;
                
                if (obj.type === 'video') {
                    // console.log(file)
                    newFile = Object.assign(file, {
                        access: true,
                        downloadUrl: data.downLoadSrc,
                        fileSource: data.downLoadSrc,
                    })
                } else if (obj.type === 'photo') {
                    newFile = Object.assign(file, {
                        access: true,
                        downloadUrl: data.imgSrc,
                        fileSource: data.imgSrc,
                        previewImg: data.imgPreviewSrc,
                    })
                } else if (obj.type === 'audio') {
                    newFile = Object.assign(file, {
                        access: true,
                        downloadUrl: data.downLoadSrc,
                        fileSource: data.fileSource,
                    })
                }
                changedFile = newFile
                return newFile
            } else {
                return file
            }
        })
        self.filesList = newFilesList;
        self.trigger('file-accepted', {file: changedFile})
    }
    
    checkIsSended (fileId, fileType, ladyId) {
        var userId = getCookie('logid');
        if(!fileType) {
            fileType = 'photo';
        }
        var url = '/user/' + userId + '/ladies/files/check?file=[{"id":"' + fileId + '","type":"' + fileType + '"}]&gid=' + ladyId;
        return requestApi(url, 'GET', null, 'json')
    }
    checkIsSendedAll (fileString, ladyId) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/check?file=' + fileString + '&gid=' + ladyId;
        return requestApi(url, 'GET', null, 'json')
    }
    
    sendFilesToLady (ladyId, filesList) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/send';
        var data = {
            gid: ladyId,
            files: filesList
        }
        return requestApi(url, 'POST', data, 'json', 'json')
    }

    setFiles(files) {
        const newFiles = [];
        files.forEach(file => {
            if(!this.filesList.find(item => item.id === file.id)) {
                newFiles.push(file);
            }
        })
        this.filesList = [...this.filesList, ...newFiles];
    }
    
    getFiles (files, ladyId) {
        var userId = +getCookie('logid');
        var self = this;
        return this._detectNotLoadFiles(files, ladyId, userId).then(function(noloadiesFiles) {
            return self._loadFiles(noloadiesFiles, ladyId, userId);
        }).then(function() {
            return self.filterFiles(files, ladyId, userId);
        });
    }
    
    _detectNotLoadFiles (ids, ladyId, userId) {
        var self = this;
        ids = ids || [];
        return new Promise(function(resolve, reject) {
            var noLoadFiles = ids.filter(function(id) {
                var result = self.filesList.findIndex(function(item) {
                    return item.id === parseInt(id) && (item.ownerID === ladyId || item.ownerID === userId)
                });
                return result === -1;
            });
            resolve(noLoadFiles);
        });
    }
    
    _loadFiles (ids, ladyId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId +'/ladies/files/byids?fileids=[' + ids + ']&gid=' + ladyId;
        if(!ids.length) {
            return Promise.resolve();
        }
        return new Promise(function(resolve) {
            requestApi(url, 'GET', null, 'json').then(function(files) {
                self.filesList = self.filesList.concat(files);
                resolve();
            })
        })
    }
    
    filterFiles (ids, ladyId, userId) {
        const files = ids.map(id => {
            return this.filesList.find(file => (ladyId === file.ownerID || file.ownerID === userId) && file.id === id)
        })
        return Promise.resolve(files);
    }

    setRating (data) {
        var userId = getCookie('logid');
        var url = '/user/' + userId +'/ladies/files/setRating';
        return requestApi(url, 'POST', data, 'json', 'json').then((resp) => {
            let changedFile = null;
            this.filesList = this.filesList.map((file) => {
                if(file.id === data.fid) {
                    const updatedFile = {...file, rating: data.rating}
                    changedFile = updatedFile
                    return updatedFile
                } else {
                    return file
                }
            })
            this.trigger('file-changed', { file:changedFile })
            return Promise.resolve(resp);
        })
    }
}