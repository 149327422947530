import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import requestApi2 from "../helpers/request-api2";
import { getCookie } from "../helpers/functions";
import { Lady } from "./lady";
import { instancesPool } from "../old/models";

export class Ladies extends CustomCollection {
    promiseCreateInstance () {
        this._filesFromCarouselLadies = [];
        this._filesToCarouselLadies = [];
        this._ladiesMailboxHistory = [];    
        return Promise.resolve();    
    };
    
    _detectNotLoadingLadies (ids) {
        var self = this;
        ids = ids || [];
        return new Promise(function(resolve, reject) {
            var noLoadLadies = ids.filter(function(id) {
                var result = self.findIndex(function(item) {
                    return item.id === parseInt(id);
                });
                return result === -1;
            });
            resolve(noLoadLadies);
        });
    };
    
    _load (ids, withHidden) {
        var self = this;
    
        if (ids.length == 0) {
            return Promise.resolve();
        }
    
        var userId = getCookie('logid');
        var url = '/ladies/ids?ids=['+ids+']';
        
        if (userId > 0) {
            url = '/user/'+userId+'/ladies/ids?ids=['+ids+']';
        } 
        if(withHidden) {
            url += '&withDisable=1'
        }
    
        var pool = [requestApi(url, 'GET', null, 'json')];
    
        if (userId > 0) {
            pool.push(window.instancesPool.get('LadiesStatus'));
        }
    
        return Promise.all(pool).then(function(results) {
            var data = results[0];
            
            if (!(data instanceof Array)) {
                return Promise.resolve();
            }
    
            data.forEach( function(lady){
    
                if (!self.findLady(lady.id)) {
                    var createdLady = new Lady(lady);
                    self.push(createdLady);
                }
    
            });
    
            if (userId > 0) {
                var ladiesStatus = results[1];
                ladiesStatus.detectedLadiesStatus(self);
            }
    
            return Promise.resolve();
        }, function(error) {
            return Promise.reject(error);
        });
    } 
    
    setLadies (data) {
        var self = this;
    
        if (data.length == 0) {
            return Promise.resolve();
        }
    
        var userId = getCookie('logid');
    
        var pool = [];
        if (userId > 0) {
            pool.push(window.instancesPool.get('LadiesStatus'));
        }
        return new Promise(function(resolve, reject) {
            return Promise.all(pool).then(function(results) {
            
                if (!(data instanceof Array)) {
                    return Promise.resolve();
                }
                var ladiesId = [];
                data.forEach( function(lady){
                    ladiesId.push(lady.id)
                    if (!self.findLady(lady.id)) {
                        var createdLady = new Lady(lady);
                        if(lady.profile_url) createdLady.linkToProfile = lady.profile_url
                        if(lady.photo_src) createdLady.photo = lady.photo_src
                        self.push(createdLady);
                    }
                });
        
                if (userId > 0) {
                    var ladiesStatus = results[0];
                    ladiesStatus.detectedLadiesStatus(self);
                }
                self.getLadies(ladiesId).then(function(ladies) {
                    resolve(ladies);
                })
            }, function(error) {
                return Promise.reject(error);
            });
        })
    } 
    
    getLadiesWithHidden (ladiesIds) {
        var self = this;
        return new Promise(function(resolve) {
            return self._detectNotLoadingLadies(ladiesIds).then(function(noloadiesLady) {
                return self._load(noloadiesLady, true);
            }).then(function() {
                resolve(self.filterLadies(ladiesIds, true));
            });
        }) 
    }
    
    getLadies (ladies) {
        var self = this;
        return this._detectNotLoadingLadies(ladies).then(function(noloadiesLady) {
            return self._load(noloadiesLady);
        }).then(function() {
            return self.filterLadies(ladies);
        });
    }
    
    filterLadies (ids, withHidden) {
        var self = this;
        var isLogged = getCookie('logid') > 10000 ? true : false
        var ladies = this.filter(function(lady) {
            return !!ids.find(function(id) {
                return id == lady.id && (!lady.disable || withHidden);
            });
        });
        var ladiesAddInfo = ladies.map(function(lady) {
            if(!lady.age && lady.bd) {
                lady.age = self.getAge(lady.bd)
            }
            if(!isLogged && lady.photoNotLogin) {
                lady.photo = lady.photoNotLogin
            }
            if(isLogged && lady.x1x4PrimaryPhotos && lady.x1x4PrimaryPhotos.x4) {
                lady.photo = lady.x1x4PrimaryPhotos.x4
                lady.photoSrcSet = `${lady.x1x4PrimaryPhotos.x1}, ${lady.x1x4PrimaryPhotos.x2} 2x, ${lady.x1x4PrimaryPhotos.x3} 3x, ${lady.x1x4PrimaryPhotos.x4} 4x`
            }
            
            return lady
        })
        return Promise.resolve(ladiesAddInfo);
    }
    
    findLady (ladyId) {
        return this.find(function(lady){
            return lady.id == ladyId;
        });
    }
    
    getRandomNextPrev (currentLady, searchParams) {
        searchParams = searchParams || false;
    
        if (currentLady <= 0) {
            return Promise.reject();
        }
    
        var searchJson = '';
    
        if (searchParams) {
            searchJson = '&searchparams=';
            var searchObjectParams = {};
            searchParams.split('&').map(
                function(prop) {
                    var kv = prop.split('=');
                    searchObjectParams[kv[0]] = kv[1];
                }
            );
            searchJson += JSON.stringify(searchObjectParams);
        }
    
        var userId = getCookie('logid');
        var url = '/ladies/cl/?currentgirl='+currentLady+searchJson;
    
        if (userId > 0) {
            url = '/user/'+userId+'/ladies/cl/?currentgirl='+currentLady+searchJson;
        }
    
        return requestApi(url, 'GET', null, 'json').then(function(res) {
                var data = res.data;
                var ladies = new Ladies;
                data.forEach(function(item) {
                    ladies.push(item)
                });
                return Promise.resolve(ladies);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }

    getRandomLadyProfileLink (currentLady) {
        if (currentLady <= 0) {
            return Promise.reject();
        }

        var userId = getCookie('logid');
        var url = '/ladies/cl/?currentgirl='+currentLady;
    
        if (userId > 0) {
            url = '/user/'+userId+'/ladies/cl/?currentgirl='+currentLady;
        }
    
        return requestApi(url, 'GET', null, 'json').then(function(res) {
                var data = res.data;
                var ladies = new Ladies;
                data.forEach(function(item) {
                    if(item.type=="random"){
                        ladies.push(item)
                    }
                });
                return Promise.resolve(ladies.length ? ladies[0] : null);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    
    getAge (date) {
        var girlBd = date.split('T')[0]
        var now = new Date(); // Текущая дата
        // hack
        if (girlBd=='0000-00-00' || !girlBd ){
          girlBd = new Date('2020-01-01')
        }
    
        var birthday = new Date(girlBd); //Дата рождения
        //Возраст = текущий год - год рождения
        var age = now.getFullYear() - birthday.getFullYear();
        // bdThisYear - полная дата дня рождения в текущем году
        var bdThisYear = new Date (now.getFullYear(),birthday.getMonth(),birthday.getDate());
        // Парсим now и bdThisYear в милисекунды для справнения
        now = Date.parse(now);
        bdThisYear = Date.parse(bdThisYear);
        //Если ДР в этом году ещё предстоит, то вычитаем из age один год
        if (now < bdThisYear) {
          age = age-1;
        }
        return age
    }

    ladiesStoriesList(userId){
        let url = '/next/ladiesStories/ladiesList';
        if(userId){
            url = '/next/' + userId + '/ladiesStories/ladiesList'
        }
        return requestApi2(url).then( result => result.data ) 
    }
    ladiesListToProfileLady(userId, girlId){
        return requestApi2('/next/' + userId + '/ladiesStories/ladiesListToProfileLady/?girlId=' + girlId).then( result => result.data ) 
    }
    ladiesListToProfileLadyLoading(userId, girlIds){
        return requestApi2('/next/' + userId + '/ladiesStories/ladiesListToProfileLadyLoading/?girlIds=' + girlIds).then( result => result.data ) 
    }
    ladyStoriesLoading(userId, girlId){
        return requestApi2('/next/' + userId + '/ladiesStories/LadyStoriesList/?girlId=' + girlId).then( result => result.data ) 
    }
    ladyStoryViewed(userId, girlId, storyId){
        return requestApi2('/next/' + userId + '/ladiesStories/ladyStoryViewed', 'POST', {girlId, storyId}).then( response => response ) 
    }
    ladyStoryLiked(userId, girlId, storyId){
        return requestApi2('/next/' + userId + '/ladiesStories/ladyStoryLiked', 'POST', {girlId, storyId}).then( response => response ) 
    }
    ladyChangeStatus(userId, girlId){
        return requestApi2('/next/' + userId + '/ladies/ladyChangeStatus', 'POST', {girlId}).then( response => response ) 
    }
    
    chooseLadiesList (girl, withHidden) {
        var self = this;
        return new Promise(function (resolve, reject) {
            if(!girl && self._chooseLadiesResp) {
                resolve(self._chooseLadiesResp)
            } else {
                var getLadiesMethod = 'getLadies';
                if(withHidden) {
                    getLadiesMethod = 'getLadiesWithHidden'
                }
                var userId = getCookie('logid');
                if(girl) {
                    if(girl.replace(/\D/g,'') > 1000 && girl.replace(/\D/g,'') < 10000) {
                        // поиск по id
                        self[getLadiesMethod]([+girl.replace(/\D/g,'')]).then(function(data) {
                            if(data.length) {
                                resolve({total: 1, items: data})
                            } else {
                                resolve({total: 0})
                            }
                        })
                    } else {
                        self.getLadiesByName(girl, withHidden).then(function(ladies) {
                            if(ladies.length) {
                                resolve({total: ladies.length, items: ladies})
                            } else {
                                resolve({total: 0})
                            }
                        })
                    }
                } else {
                    var url = '/user/' + userId + '/ladies/favoriteAndRelations';
                    requestApi(url, 'GET', null, 'json').then(function(data) {
                        var ids = data.slice(0, 30)
                        self[getLadiesMethod](ids).then(function(ladies) {
                            var resp = {total:data.length, items: ladies}
                            self._chooseLadiesResp = resp
                            resolve(resp)
                        })
                    })
                }
            }
        })
    }
    
    findFullLady (ladyId) {
        return this.find(function(lady){
            return lady.id == ladyId && lady.isFull;
        });
    }
    
    filterLadiesFull (ids) {
        var isLogged = getCookie('logid') > 10000 ? true : false
        var ladies = this.filter(function(lady) {
            return !!ids.find(function(id) {
                return id == lady.id && lady.isFull;
            });
        });
        var ladiesAddInfo = ladies.map(function(lady) {
            if(!isLogged && lady.files.photos.avatar.photoNotLogin) {
                lady.photo = lady.files.photos.avatar.photoNotLogin
            }
            if(isLogged && lady.files.photos.avatar.x1x4PrimaryPhotos && lady.files.photos.avatar.x1x4PrimaryPhotos.x4) {
                lady.photo = lady.files.photos.avatar.x1x4PrimaryPhotos.x4
                lady.photoSrcSet = `${lady.files.photos.avatar.x1x4PrimaryPhotos.x1}, ${lady.files.photos.avatar.x1x4PrimaryPhotos.x2} 2x, ${lady.files.photos.avatar.x1x4PrimaryPhotos.x3} 3x, ${lady.files.photos.avatar.x1x4PrimaryPhotos.x4} 4x`
            }
            return lady
        })
        return Promise.resolve(ladiesAddInfo);
    }
    
    setFullLadies (data) {
        var self = this;
        if (Array.isArray(data) && data.length == 0) {
            return Promise.resolve();
        }
    
        var userId = getCookie('logid');
    
        var pool = [];
        if (userId > 0) {
            pool.push(window.instancesPool.get('LadiesStatus'));
        }
    
        return Promise.all(pool).then(function(results) {
            if (!(data instanceof Array)) {
                return Promise.resolve();
            }
    
            data.forEach( function(lady){
    
                if (!self.findFullLady(lady.id)) {
                    var createdLady = new Lady(lady);
                    if(!createdLady.photo) {
                        createdLady.photo = lady.files.photos.avatar.primary;
                        createdLady.photoCircle = lady.files.photos.avatar.primary_circle;
                    }
                    createdLady.isFull = true;
                    var ladiesIndex = self.findIndex(function(item) {
                        return item.id === lady.id
                    })
                    if(ladiesIndex > -1) {
                        self.splice(ladiesIndex, 1);
                    }
                    self.push(createdLady);
                } else if(self.findFullLady(lady.id).isFull){
                    var createdLady = Object.assign(self.findFullLady(lady.id), lady);
                }
            });
    
            if (userId > 0) {
                var ladiesStatus = results[0];
                ladiesStatus.detectedLadiesStatus(self);
            }
            var ids = data.map(function(lady){
                return lady.id
            })
            return self.getFullLadies(ids);
        }, function(error) {
            return Promise.reject(error);
        });
    } 
    
    getFullLadies (ladies) {
        var self = this;
        return this._detectNotLoadingLadiesFull(ladies).then(function(noloadiesLady) {
            return self._loadFull(noloadiesLady);
        }).then(function() {
            return self.filterLadiesFull(ladies);
        });
    }
    _detectNotLoadingLadiesFull (ids) {
        var self = this;
        ids = ids || [];
        return new Promise(function(resolve, reject) {
            var noLoadLadies = ids.filter(function(id) {
                var result = self.findIndex(function(item) {
                    return item.id == id && item.isFull;
                });
                return result === -1;
            });
            resolve(noLoadLadies);
        });
    };
    
    _loadFull (ids) {
        var self = this;
    
        if (ids.length == 0) {
            return Promise.resolve();
        }
    
        var userId = getCookie('logid');
        var url = '/ladies/full?ids=[' + ids + ']';
        
        if (userId > 0) {
            url = '/user/' + userId + '/ladies/full?ids=[' + ids + ']';
        } 
    
        var pool = [requestApi(url, 'GET', null, 'json')];
        if (userId > 0) {
            pool.push(window.instancesPool.get('LadiesStatus'));
        }
        return Promise.all(pool).then(function(results) {
            var data = results[0];
            
            if (!(data instanceof Array)) {
                return Promise.resolve();
            }
    
            data.forEach( function(lady){
    
                if (!self.findFullLady(lady.id)) {
                    var ladiesIndex = self.findIndex(function(item) {
                        return item.id === lady.id
                    })
                    if(ladiesIndex > -1) {
                        self.splice(ladiesIndex, 1);
                    }
                    var createdLady = new Lady(lady);
                    createdLady.photo = lady.files.photos.avatar.primary;
                    createdLady.photoCircle = lady.files.photos.avatar.primary_circle;
                    createdLady.isFull = true;
                    self.push(createdLady);
                }
    
            });
    
            if (userId > 0) {
                var ladiesStatus = results[1];
                ladiesStatus.detectedLadiesStatus(self);
            }
    
            return Promise.resolve();
        }, function(error) {
            return Promise.reject(error);
        });
    } 
    isFull (id) {
        var self = this;
        var isFull = false;
        self.map(function(lady) {
            if(lady.id === id && lady.isFull) {
                isFull = true
            }
        })
        return isFull
    } 
    
    getGalleryGirls (page, isNew, isMarketing) {
        var self = this;
    
        var userId = getCookie('logid');
        var url = '/ladies/all?page=' + page;
        
        if (userId > 0) {
            url = '/user/' + userId + '/ladies/all?page=' + page;
        } 
        if(isNew) {
            url = '/ladies/filter?new=1&page=' + page;
            if (userId > 0) {
                url = '/user/' + userId + '/ladies/filter?new=1&page=' + page;
            } 
        }
        if(isMarketing) {
            if(page == 0){
                page = 1
            }
            url = '/ladies/promoGallery?page=' + page;
            if (userId > 0) {
                url = '/user/' + userId + '/ladies/promoGallery?page=' + page;
            } 
        }
    
        var pool = [requestApi(url, 'GET', null, 'json')];
        return new Promise(function(resolve, reject) {
            return Promise.all(pool).then(function(results) {                
                var data = results[0];
                if(data.err) {
                    var path = '/ukrainian-women-photo-gallery';
                    if(window.location.href.includes('russian-girls-photo-gallery')) {
                        path = '/ukraine-girls-photo-gallery'
                    }
                    window.location.href = path
                } else {
                    var count = data.count;
                    var ladies = data.ladies;
                    self.setFullLadies(ladies).then(function (fullLadies) {
                        return resolve({count: count, ladies: fullLadies});
                    })
                }
            }, function(error) {
                return Promise.reject(error);
            });
        })
    } 
    
    getFilteredGalleryGirls (request, isNew, isMarketing) {
        var self = this;
        var userId = getCookie('logid');
        var path = '/ladies/filter?' + request;
        if (userId > 0) {
            path = '/user/' + userId + '/ladies/filter?' + request;
        }
        if(!request.includes('page=')) {
            path += '&page=1'
        }
        if(isNew) {
            path += '&new=1'
        }
        if(isMarketing) {
            path += '&promoLadies=1'
        }        
    
        var pool = [requestApi(path, 'GET', null, 'json')];
        return new Promise(function(resolve, reject) {
            return Promise.all(pool).then(function(results) {                
                var data = results[0];
                if(data.ladies.length) {
                    var count = data.count;
                    var ladies = data.ladies;
                    self.setFullLadies(ladies).then(function (fullLadies) {
                        return resolve({count: count, ladies: fullLadies});
                    })
                } else {
                    return resolve(data)
                }           
            }, function(error) {
                return Promise.reject(error);
            });
        })
    }
    
    getSimilarLadies (gid) {
        var self = this;
    
        var userId = getCookie('logid');
        var url = '/ladies/similar?gid=' + gid;
        
        if (userId > 0) {
            url = '/user/' + userId + '/ladies/similar?gid=' + gid;
        }
        
        return new Promise(function(resolve, reject) {
            var pool = [
                requestApi(url, 'GET', null, 'json')
            ];
    
            if (userId > 0) {
                pool.push(window.instancesPool.get('LadiesStatus'));
            }
    
            Promise.all(pool).then(function(results) {
                var data = results[0] || [];
    
                if (data.length) {
                    var ladies = data.map(function(ladyData) {
                        return new Lady(ladyData)
                    });
    
                    if (userId > 0) {
                        var ladiesStatus = results[1];
                        ladiesStatus.detectedLadiesStatus(ladies);
                    }
    
                    resolve(ladies);
    
                }
            });
        });   
    }
    
    getPhotoGalleryGirls (request) {
        var self = this;
    
        var userId = getCookie('logid');
        var url = '/ladies/photoGallery';
        
        if (userId > 0) {
            url = '/user/' + userId + '/ladies/photoGallery';
        }
        if(request) {
            url += '?' + request;
        } 
    
        return requestApi(url, 'GET', null, 'json').then(function(res) {
            return Promise.resolve(res);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    } 
    getLadyFiles (ladyId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/ladies/files?id=' + ladyId;
        if(userId > 0) {
            url = '/user/' + userId + '/ladies/files?id=' + ladyId;
        }
        return requestApi(url, 'GET', null, 'json')
    } 
    getVideoGalleryGirls (page) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/ladies/videohistory?page=' + page;
    
        if(userId > 0) {
            url = '/user/' + userId + '/ladies/videohistory?page=' + page;
        }
    
        return new Promise(function(resolve, reject) {
            var pool = [
                requestApi(url, 'GET', null, 'json')
            ];
    
            if (userId > 0) {
                pool.push(window.instancesPool.get('LadiesStatus'));
            }
    
            Promise.all(pool).then(function(results) {
                var data = results[0] || [];
    
                if (data.ladies.length) {
                    var ladies = data.ladies.map(function(ladyData) {
                        return new Lady(ladyData)
                    });
    
                    if (userId > 0) {
                        var ladiesStatus = results[1];
                        ladiesStatus.detectedLadiesStatus(ladies);
                    }
    
                    resolve({
                        ladies: ladies,
                        count: data.count
                    });
    
                } else {
                    resolve(data)
                }
            });
        });  
    } 
    getLoginLadiesCarousel (ladies) {
        var url = '/ladies/loginCarousel';
        return window.requestApi(url, 'GET', null, 'json').then(
            function (ladies) {
                return Promise.resolve(ladies);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    
    getLadiesByName (name, withHidden) {
        var self = this;
        var url = '/ladies/byName?name=' + name;
        var userId =  getCookie('logid');
        if(userId > 10000) {
            url = "/user/" + userId + "/ladies/byName?name=" + name;
        }
        if(withHidden) {
            url += '&withDisable=1'
        }
        return new Promise(function(resolve) {
            requestApi(url, 'GET', null, 'json').then(function(data) {
                var getMethod = 'getLadies';
                if(withHidden) {
                    getMethod = 'getLadiesWithHidden'
                }
                self[getMethod](data).then(function(ladies) {
                    resolve(ladies)
                })
            })
        })
    }
    
    getRandomLadiesForFav (){
        var self = this;
        var pool = [];
        if (self.length < 10) {
            pool.push(instancesPool.get('OnlineSoonLadies').then(
                function (onlineSoonLadies) {
                    var allIds = onlineSoonLadies.getAllCollectionsIds();
                    var randomAllIds = allIds.map(function (i) {
                        return [Math.random(), i];
                      }).sort().map(function (i) {
                        return i[1];
                      });
                    var randomFilterIds = randomAllIds.slice(0,12);
                    return self.getLadies(randomFilterIds);
                }
            ));
        }
    
        return Promise.all(pool).then(function() {
            var randomAllLadies = self.map(function (i) {
                return [Math.random(), i];
              }).sort().map(function (i) {
                return i[1];
              }).filter(i => { return i.disable != true});
            randomAllLadies.slice(0,3);
            var randomFilterIds = randomAllLadies.slice(0,3);
            return Promise.resolve(randomFilterIds);
        });
    }
    
    filesFromCarousel (query) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/carouselFrom';
        if(self._filesFromCarouselLadies.length && !query) {
            return Promise.resolve(self._filesFromCarouselLadies)
        }
        if(query) {
            var id = parseInt(query.match(/\d+/))
            if(id) {
                url += '?id=' + id;
            } else {
                url += '?name=' + query;
            }
        }
        return requestApi(url, 'GET', null, 'json').then(function(data) {
            if(!query) {
                self._filesFromCarouselLadies = data
            }
            return Promise.resolve(data)
        })
    }
    
    filesToCarousel (query) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/carouselTo';
        if(self._filesToCarouselLadies.length && !query) {
            return Promise.resolve(self._filesToCarouselLadies)
        }
        if(query) {
            var id = parseInt(query.match(/\d+/))
            if(id) {
                url += '?id=' + id;
            } else {
                url += '?name=' + query;
            }
        }
        return requestApi(url, 'GET', null, 'json').then(function(data) {
            if(!query) {
                self._filesToCarouselLadies = data
            }
            return Promise.resolve(data)
        })
    }
    
    getHistoryLadies (query) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/ladiesWithLetters';
        if(self._ladiesMailboxHistory.length && !query) {
            return Promise.resolve(self._ladiesMailboxHistory)
        }
        if(query) {
            url += '?name=' + query;
        }
        return requestApi(url, 'GET', null, 'json').then(function(data) {
            if(!query) {
                self._ladiesMailboxHistory = data
                self.setLadies(data)
            }
            return Promise.resolve(data)
        })
    };
    getMatchLadies () {
        var self = this;
        var userId = getCookie('logid');
        if(userId > 10000) {
            var url = '/user/' + userId + '/ladies/personal-match';
            return new Promise(function(resolve) {
                requestApi(url, 'GET', null, 'json').then(function(resp) {
                    self.setFullLadies(resp.ladies).then(function(fullLadies) {
                        resolve(fullLadies)
                    })
                })
            })
        }
        return Promise.resolve()
    };
    
    setWink (ladyId, winkType) {
        var self = this;
        self.forEach(function(lady, index) {
            if(lady.id === ladyId && lady.isFull) {
                self[index].wink = {
                    link: "/inc/images/winks/" + winkType + ".png",
                    type: winkType
                }
            }
        })
    }
}