import { CustomCollection } from "../helpers/custom-classes";
import { getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";

export class LadyMedias extends CustomCollection {
    getByIds (photos, videos, audios) {
        var userId = getCookie('logid');
    
        if (userId > 0) {
            var url = '/user/' + userId + '/ladies/media?photos=[' + photos + ']';
        } else {
            var url = '/ladies/media?photos=[' + photos + ']';
        }
    
        var url = '/ladies/media?photos=[' + photos + ']';
        return requestApi(url, 'GET', null, 'json').then(function (res) {
            var data = res.data;
            var instance = new this;
            data.forEach(function (item) {
                instance.push(item)
            });
            return Promise.resolve(instance);
        }, function (error) {
            return Promise.resolve(error);
        });
    };
}