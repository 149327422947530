class InstancesPool {
    constructor (models) {
        this.instances = {};
        this.promises = {};
        this.models = models;
    }

    get(className, forsed) {
        var self = this;

        return new Promise(function (resolve, reject) {
            if (forsed) {
                delete self.instances[className];
                delete self.promises[className];
            }

            if (!self.instances[className] || forsed) {
                self.promiseAll(className).then(function () {
                    return resolve(self.instances[className]);
                });
            } else {
                resolve(self.instances[className]);
            }
        });
    }

    gets(classesName) {
        var self = this;
        var pool = classesName.map(function(item) {
            return self.get(item)
        });
        return Promise.all(pool);
    }

    promiseAll(className) {
        var self = this;
        return new Promise(function(resolve, reject) {
            
            if (!(self.promises[className] instanceof Array)) {
                self.create(className);
            }

            self.push(className, resolve);
        });    
    }

    push(className, callBack) {
        
        if (this.promises[className] === undefined) {
            this.promises[className] = [];
        }
    
        this.promises[className].push(callBack);
    }

    pushInstance(className, instance) {
        this.instances[className] = instance;
	
        if (this.promises[className] instanceof Array) {
            this.promises[className].forEach(function(call) {
                call()
            });
        }
    }

    create(className) {
        var self = this;

        try {
            if (typeof this.models[className] === 'function') {
                var instance = new this.models[className];
    
                if (typeof  instance['promiseCreateInstance'] === 'function') {
                    instance['promiseCreateInstance']().then(function() {
                        self.pushInstance(className, instance)
                    })
                } else {
                    self.pushInstance(className, instance);
                }
            } 

        } catch (error) {
            console.error(error);
        }
    }

    _clearForLogin() {
        this.instances = {};
        this.promises = {};
    }
};

export default InstancesPool;