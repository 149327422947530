import { getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";
import { instancesPool } from "../old/models";

const StaticPagesMethods = {};

StaticPagesMethods.sendStaffToDoForm = function (data) {
    var userId = getCookie('logid');

    var url = '';
    if (userId > 0) {
        url = '/user/' + userId + '/trip/want';
    }

    return requestApi(url, 'POST', data, 'json', 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}

StaticPagesMethods.filterLadies = function (filter) {
    var userId = getCookie('logid');

    var url = '';
    if (userId > 0) {
        url = '/user/' + userId + '/search/filter?props=' + JSON.stringify(filter);
    } else {
        url = '/search/filter?props=' + JSON.stringify(filter);
    }

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}

StaticPagesMethods.getGiftPrice = function (giftId) {
    var url = '/gifts/price/?giftId=' + giftId;

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}

StaticPagesMethods.getGiftsPrice = function (giftId) {
    var url = '/gifts?ids=[' + giftId + ']';

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}

StaticPagesMethods.getGiftByGroup = function (giftId) {
    var url = '/gifts/group?id=' + giftId;

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}

StaticPagesMethods.carouselGirls = function () {
    var userId = getCookie('logid');

    var url = '';
    if (userId > 0) {
        url = '/user/' + userId + '/ladies/randomForVerticalCarousel';
    } else {
        url = '/ladies/randomForVerticalCarousel';
    }

    return new Promise(function (resolve, reject) {
        requestApi(url, 'GET', null, 'json').then(
            function (data) {
                instancesPool.get('Ladies').then(ladies => {
                    ladies.setLadies(data).then(() => {
                        const LadiesId = data.map(lady => lady.id)
                        ladies.getLadies(LadiesId).then((ladiesList) => {
                            resolve(ladiesList);
                        })
                    })
                })
            }, function (error) {
                reject(error);
            }
        );
    })
}

StaticPagesMethods.getCarouselWithChatInfo = function() {
    return new Promise ((resolve, reject) => {
        StaticPagesMethods.carouselGirls().then(ladies => {
            var userId = getCookie('logid');
            if(userId > 10000) {
                instancesPool.get('OnlineSoonLadies').then(onlineSoonLadies => {
                    let onlineSoonArr = [];
                    let otherLadiesArr = [];
                    ladies.map((lady, index) => {
                        if (index <= 20) {
                            if (onlineSoonLadies.getByGid(lady.id)) {
                                const onlineLady = onlineSoonLadies.getByGid(lady.id);
                                onlineLady.languages = lady.languages
                                onlineSoonArr.push(onlineLady)
                            } else {
                                otherLadiesArr.push(lady);
                            }
                        } else return
                    });
                    resolve([...onlineSoonArr, ...otherLadiesArr])
                })
            } else {
                resolve(ladies)
            }
            
        })
    })
}

StaticPagesMethods.getTestimonial = function () {
    var url = '/testimonial/random';
    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getTestimonialById = function (id) {
    var userId = getCookie('logid');

    var url = '/testimonial/id/' + id;
    if (userId > 0) {
        url = '/user/' + userId + '/testimonial/id/' + id;
    }

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getestimonialByYear = function (year, lastId, lastDate) {
    
    var userId = getCookie('logid');

    if(year === 0) {
        const date = new Date();
        year = date.getFullYear();
    }

    var url = '/testimonial/year/' + year;
    if (userId > 0) {
        url = '/user/' + userId + '/testimonial/year/' + year;
    }

    if (lastId) {
        url += '?lastID=' + lastId + '&lastDate=' + lastDate
    }

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getestimonialLady = function (lastId) {
    var userId = getCookie('logid');

    var url = '/testimonial/lady/';
    if (userId > 0) {
        url = '/user/' + userId + '/testimonial/lady/';
    }

    if (lastId) {
        url += '?lastID=' + lastId
    }

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}

StaticPagesMethods.getMarriageStories = function () {
    var userId = getCookie('logid');

    var url = '';
    if (userId > 0) {
        url = '/user/' + userId + '/marriage';
    } else {
        url = '/marriage';
    }
    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getSeoLink = function () {
    var url = '/crossLink';
    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getCaptcha = function () {
    var url = '/captcha';
    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getLastLoveStories = function () {
    var userId = getCookie('logid');

    var url = '';
    if (userId > 0) {
        url = '/user/' + userId + '/marriage/last';
    } else {
        url = '/marriage/last';
    }
    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getAllLoveStories = function () {
    var userId = getCookie('logid');

    var url = '';
    if (userId > 0) {
        url = '/user/' + userId + '/marriage/all';
    } else {
        url = '/marriage/all';
    }
    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            return Promise.resolve(data);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}
StaticPagesMethods.getTopBanner = function () {
    var url = '/decoration/banner';

    return requestApi(url, 'GET', null, 'json').then(
        function (data) {
            var topBanner = {
                bannerClassName: data.season,
                girlLink: data.girlLink,
                girlImgSrc: data.girlImgSrc,
                backgroundColor: data.girlNameColor,
                girlName: data.name,
                girlAge: data.age,
                girlStory: data.partner
            };
            return Promise.resolve(topBanner);
        }, function (error) {
            return Promise.reject(error);
        }
    );
}

StaticPagesMethods.getGallerySEOTestimonial = function () {
    return requestApi('/testimonial/gallery/seo/','GET', null, 'json');
}

StaticPagesMethods.getSEOPagesML = function (page = '') {
    return requestApi(`/seo/pagesML/${page}`,'GET', null, 'json');
}

StaticPagesMethods.sendTestimonial = function (data) {
    var userId = getCookie('logid');
    var url = '/user/' + userId + '/testimonial';
    return requestApi(url,'POST', data, 'json', 'json');
}

export { StaticPagesMethods }