import { CustomEventTarget } from "../helpers/custom-classes";
import { createQuery, $, getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";
import { instancesPool } from "../old/models";

export class MailBox extends CustomEventTarget {
    promiseCreateInstance () {
        this.foldersList = [];
        this.attachedFilesIds = [];
        this.decorList = [];
        return this.updateMailFolders();
    };
    
    updateMailFolders () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/folders';
        return new Promise(function(resolve) {
            if(userId > 10000) {
                requestApi(url, 'GET', null, 'json').then(function(data) {
                    self.foldersList = data.folders;
                    self.foldersColors = data.colorsList;
                    self.trigger('folders-changed')
                    return resolve(data);
                })
            } else {
                return resolve();
            }
        })
    }
    addFilesToLetter (files) {
        this.trigger('files-added', files);
    };
    getMailData (id) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/sentMailData?ids=[' + id + ']' ;
        return requestApi(url, 'GET', null, 'json').then(function(data) {
            return Promise.resolve(data)
        })
    };
    getMailsList (data, type) {
        // data = {
        //     - gid:7444
        //     - searchText:Test
        //     - page:1
        //     - userFolder:27455
        //     - sort: unread, oldest, (default - newest)
        // }
        // type = false(inbox) || 'sent' || 'draft' || 'all'
        if(type === 'all') {
            type = 'allinbox'
        }
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/' + type;
    
        if(Object.keys(data).length) {
            url += '?' + createQuery(data)
        }
    
        return requestApi(url, 'GET', null, 'json').then(function(resp) {
            return Promise.resolve(resp)
        })
    };
    
    getHistoryList (ladyId, page) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/history/' + ladyId + '?page=' + page;
        return requestApi(url, 'GET', null, 'json').then(function(resp) {
            return Promise.resolve(resp)
        })
    }
    
    getMailById (mailId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/id/' + mailId;
        return requestApi(url, 'GET', null, 'json').then(function(resp) {
            if(resp.success && resp.data[0] && !resp.data[0].read) {
                self.updateMailFolders();
                window.instancesPool.get('MainMenu').then(function(mainMenu) {
                    mainMenu.updateCounter()
                })
            }
            return Promise.resolve(resp)
        })
    }
    
    getPromoMail (mailId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/banners/mail?id=' + mailId;
        return requestApi(url, 'GET', null, 'json').then(function(resp) {
            return Promise.resolve(resp)
        })
    }
    
    removePromo (promoId) {
        return new Promise(function(resolve) {
            function confirm() {
                var userId = getCookie('logid');
                var url = '/user/' + userId + '/banners/mail?id=' + promoId;
                requestApi(url, 'DELETE', null, 'json').then(function() {
                    window.instancesPool.get('PromoBanners').then(function(promoBanners) {
                        promoBanners.promoMailsList = promoBanners.promoMailsList.filter(function(banner) {
                            return banner.id !== promoId
                        })
                    })
                    resolve({success: true})
                })
            }
            function close() {
                resolve({success: false})
            }
    
            var title = 'Deleting mail';
            var content = 'Are you sure you want to delete mail?<br />This action cannot be undone';
            var data = {
                confirm: confirm,
                close: close,
                content: content,
                title: title,
            }
            window.initReactPopup('initConfirmPopup', data)
        })
    }
    
    createFolder (name, mailId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/folder/' + name + '?ids=[' + mailId + ']';
    
        return requestApi(url, 'POST', null, 'json').then(function(resp) {
            if(resp.success) {
                self.foldersList.push(resp.data);
                self.trigger('folders-changed');
            }
            return Promise.resolve(resp)
        })
    }
    
    moveToFolder (folderId, mailIds) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/folder/' + folderId + '?ids=[' + mailIds + ']';
        if(folderId === 'inbox') {
            url = '/user/' + userId + '/mailbox/toinbox?ids=[' + mailIds + ']';
        }
    
        return requestApi(url, 'PUT', null, 'json').then(function(resp) {
            self.foldersList = resp.data;
            self.trigger('folders-changed');
            return Promise.resolve(resp)
        })
    }
    
    getDecor () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/decor';
        return new Promise(function(resolve) {
            if(self.decorList.length) {
                resolve(self.decorList)
            } else {
                requestApi(url, 'GET', null, 'json').then(function(resp) {
                    resolve(resp.data)
                })
            }
        })
    }
    setDecor (data, mailId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/decorate/' + mailId;
        return requestApi(url, 'PUT', data, 'json', 'json').then(function(resp) {
            return Promise.resolve(resp)
        })
    }
    getWinkInfo (ladyId) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/wink?girlID=' + ladyId;
        return requestApi(url, 'GET', null, 'json', 'json').then(function(resp) {
            return Promise.resolve(resp)
        })
    }
    removeWink (ladyId) {
        return new Promise(function(resolve) {
            function confirm() {
                var userId = getCookie('logid');
                var url = '/user/' + userId + '/mailbox/deleteWinks?girls=[' + ladyId + ']';
                requestApi(url, 'PUT', null, 'json').then(function() {
                    resolve({success: true})
                })
            }
            function close() {
                resolve({success: false})
            }
            var title = 'Deleting wink';
            var content = 'Are you sure you want to delete wink?<br />This action cannot be undone';
            var data = {
                confirm: confirm,
                close: close,
                content: content,
                title: title,
            }
            window.initReactPopup('initConfirmPopup', data)
        })
    }
    
    removeLetter (mailIds, type) {
        return new Promise(function(resolve) {
            function confirm() {
                
                var userId = getCookie('logid');
                var url = '/user/' + userId + '/mailbox/delete?ids=[' + mailIds + ']';
                if(type === 'draft') {
                    url = '/user/' + userId + '/mailbox/delete?drafts=[' + mailIds + ']';
                }
                requestApi(url, 'PUT', null, 'json').then(function() {
                    window.instancesPool.get('Basket').then(function(basket) {
                        basket.updateData()
                    })
                    resolve({success: true})
                })
            }
            function close() {
                resolve({success: false})
            }
            var letterText = '1 letter';
            if(Array.isArray(mailIds) && mailIds.length > 1) {
                letterText = mailIds.length + ' letters'
            }
            var title = 'Deleting ' + letterText;
            var content = 'Are you sure you want to delete ' + letterText + '?<br />This action cannot be undone';
            var data = {
                confirm: confirm,
                close: close,
                content: content,
                title: title,
            }
            window.initReactPopup('initConfirmPopup', data)
        })
    }
    removeLetterRPX (mailIds) {
        return new Promise(function(resolve) {
            function confirm() {
                resolve({success: true})
                $.post("/inc/mailbox/_ajax_json.rpx", {
                    "do": "removemails",
                    "mids": mailIds.join(",")
                }, function () {
                    window.location.reload()
                })
            }
            function close() {
                resolve({success: false})
            }
            var letterText = '1 letter';
            if(Array.isArray(mailIds) && mailIds.length > 1) {
                letterText = mailIds.length + ' letters'
            }
            var title = 'Deleting ' + letterText;
            var content = 'Are you sure you want to delete ' + letterText + '?<br />This action cannot be undone';
            var data = {
                confirm: confirm,
                close: close,
                content: content,
                title: title,
            }
            window.initReactPopup('initConfirmPopup', data)
        })
    }
    
    sendRating (string) {
        var url = '/raiting/sendcomplain?' + string;
        return requestApi(url, 'GET', null, 'json')
    }
    
    saveDraft (body) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/makedraft';
        return requestApi(url, 'PUT', body, 'json', 'json')
    }
    getDraftLetter (ladyId) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/draftsto/' + ladyId;
        return requestApi(url, 'GET', null, 'json')
    }
    
    rejectMail (mailId, value) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/reject/' + mailId + '?sory=' + value;
        return requestApi(url, 'POST', null, 'json').then(function(resp) {
            return Promise.resolve(resp)
        })
    }
    sendLetter (body) {
        
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/compose?sendAnyway=0';
        return requestApi(url, 'POST', body, 'json', 'json').then(function(resp) {
            if(resp.success) {
                instancesPool.get('LadiesStatus').then(ladiesStatus => {
                    ladiesStatus.addToSweethearts(body.to_id)
                })
            }
            return Promise.resolve(resp)
        })
    }
    editLetter (body) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/edit';
        return requestApi(url, 'PUT', body, 'json', 'json').then(function(resp) {
            return Promise.resolve(resp)
        })
    }
    
    getRandomFolderColor () {
        var randomColor = this.foldersColors[Math.floor(Math.random() * this.foldersColors.length)];
        return randomColor
    }
    
    setNewFolderName (folderId, name, color) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/mailbox/folder/rename?folder=' + folderId + '&name=' + name + '&color=' + color;
        return requestApi(url, 'PUT', null, 'json').then(function(resp) {
            if(resp.success) {
                self.foldersList = self.foldersList.map(function(folder) {
                    if(folder.folderID === folderId) {
                        folder.name = name;
                        folder.color = color;
                    }
                    return folder
                })
                self.trigger('folders-changed')
            }
        })
    }
}
