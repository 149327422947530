import { Events } from "./events";

class HandlerWays extends Events {

	constructor() {
		super();
		this.rulesWay = [];
	};

    getPathwayParams(ruleWay, paramsMatches) {
		var params = {},
			keys = ruleWay.match(/:[\w&=%]*/ig);

		if (!(paramsMatches instanceof Array)) {
			return params;
		}

		paramsMatches.splice(0, 1);
		paramsMatches.forEach((value, key) => {
			params[keys[key].slice(1)] = value
		});

		return params;
	};

    checkPathways(ruleWays, path) {

		if (typeof ruleWays === 'string') {
			ruleWays = [ruleWays];
		}

		return ruleWays.indexOf(path) > -1;
	};

    checkParamsRuleWay(ruleWay, pathway) {
		
		if (/[\w]*:/i.test(ruleWay)) {
			let reg = new RegExp('^' + ruleWay.replace(/:[\w&=%]*/ig, '([\\w&=%]*)') + '$');
			
			if (reg.test(pathway)) {
				return reg.exec(pathway);
			}
			
			return false;
		}

		return false;
	};

	addRulesWay(ruleWays) {
		if (typeof ruleWays == 'string' 
		&& this.rulesWay.indexOf(ruleWays) == -1) {
			this.rulesWay.push(ruleWays);
		} else if (ruleWays instanceof Array) {
			ruleWays.forEach(
				ruleWay => this.addRulesWay(ruleWay)
			)
		}
	};

	setState(path, fullPath, browserTransition = false) {

		return new Promise((resolve, reject) => {
			if (path === '') {
				path = '/';
			}
			
			fullPath = fullPath || path;
			let eventProps = {path : path, params : {}, rule : path, callback : resolve, fullPath, browserTransition};
	
			if (this.rulesWay.indexOf(path) > -1) {
				this.trigger('changeState', eventProps);
			} else {
				let isPage = false;
				this.rulesWay.forEach(
					rule => {
						let matches = this.checkParamsRuleWay(rule, path);
					
						if (matches !== false) {
							isPage = true
							eventProps.params = this.getPathwayParams(rule, matches);
							eventProps.rule = rule;
							this.trigger('changeState', eventProps);
						}
					}
				);
				// проверка несуществующий react url
				if(!isPage) {
					setTimeout(() => {
						if(document.querySelector('.page-content')?.childElementCount === 1 && document.querySelector('.js-first-loading')) {
							window.udapp.router.goTo404()
						}
					}, 500)
				}
			}
		}) 
		
	};
}

export {HandlerWays};