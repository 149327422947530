import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";

export class DataOptions extends CustomEventTarget {
    promiseCreateInstance () {
        var self = this;
        return new Promise(function (resolve, reject) {
            requestApi('/dataOptions', 'GET', null, 'json').then(
                function(data) {
    
                    for (var k in data) {
                        self[k] = data[k];
                    }
        
                    resolve();
                }, console.warn
            )
        });
    }
}