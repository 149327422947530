import { CustomEventTarget } from "../helpers/custom-classes";
import { getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";

export class VideoStatistic extends CustomEventTarget {
    promiseCreateInstance() {
        // add isPlaying atr to all media elements
        Object.defineProperty(HTMLMediaElement.prototype, 'isPlaying', {
            get: function () {
                return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
            }
        })
        var userId = getCookie('logid');
        if (userId > 10000) {
            var self = this;
            self.sessionId = Math.floor(Math.random() * 1000000);
            self.playingVideos = [];
            self.playingChanged = false;

            setInterval(function () {
                var allVideos = document.querySelectorAll('video');
                Array.from(allVideos).forEach(function (element, index) {
                    if (element.isPlaying) {
                        var videoId = element.currentSrc;
                        var isVideoPlayedBefore = false;
                        for (var i = 0; i < self.playingVideos.length; i++) {
                            if (self.playingVideos[i].id === videoId) {
                                self.playingVideos[i].time = self.playingVideos[i].time + 1;
                                self.playingChanged = true;
                                isVideoPlayedBefore = true;
                            }
                        }
                        if (!isVideoPlayedBefore) {
                            self.playingChanged = true;
                            self.playingVideos.push({
                                id: videoId,
                                time: 0,
                            })
                        }
                    }
                })
            }, 1 * 1000)

            setInterval(function () {
                self.sendStatistic()
            }, 5 * 1000);

            window.addEventListener("beforeunload", function (evt) {
                self.sendStatistic('close')
            });
        }

        return Promise.resolve()
    }

    sendStatistic(type = 'interval') {
        var self = this;
        var userId = getCookie('logid');
        if (userId > 10000 && self.playingChanged) {
            self.playingChanged = false;
            var data = {
                videos: self.playingVideos,
                sessionId: self.sessionId,
                page: window.location.href
            }
            var url = '/user/' + userId + '/ladies/files/playStats'
            // requestApi(url, 'POST', data, 'json', 'json')
        }
    }
}