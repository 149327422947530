import { CustomEventTarget } from "../helpers/custom-classes";
import { Lady } from "./lady";
import { $, getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";

export class OnlineSoonLady extends Lady {
    constructor(params) {
        super(params);
        this.photo_src = this.photoChat_src || this.photo_src;
    }
}

export class OnlineSoonLadies extends CustomEventTarget {
    loading = false;
    isReady = false;
    listeners = {};
    online = [];
    soon = [];
    props = [];
    flowGirlId = null;

    onReady (listenersName, listenerBody) {
        if (this.isReady) {
            listenerBody(this);
        }
    
        try {
            this.listeners[listenersName] = listenerBody;
        } catch (error) {
            console.error(error);
        }
    }
    
    ready (eventName) {
        if (typeof eventName === "string") {
            this.listeners[eventName](this);
        } else {
            for (var listener in this.listeners) {
                this.listeners[listener](this);
            }
        }
        this.isReady = true;
    }
    
    promiseCreateInstance = function () {
        var self = this;
        var userId = getCookie('logid');
    
        if (userId <= 0) {
            return Promise.resolve();
        }
    
        var url = '/user/' + userId + '/ladies/inchat';
        return Promise.all([
            requestApi(url, 'GET', null, 'json'),
            window.instancesPool.gets(['Times', 'LadiesStatus'])
        ]).then(function(results){
            var resp = results[0];
            var udtime = results[1][0];
            var ladiesStatus = results[1][1];
            self.props = [];
            self.flowGirlId = resp.flowGirlId
            for (var i = 0; i < resp.data.length; i++) {
                var lady = new OnlineSoonLady(resp.data[i]);
                if(!lady.photo && lady.photo_src) lady.photo = lady.photo_src;
                if(!lady.linkToProfile && lady.profile_url) lady.linkToProfile = lady.profile_url;
                self.props.push(lady);
            };
    
            self.sortByFavs();
            self.online = self.getLadiesByStatusInChat('online');
            self.soon = self.getLadiesByStatusInChat('soon');
            self.updateOnlineSoonTextesOnPage();
            self.prepareTextesForLadiesObject();
            udtime.addListener('changed', function() {
                self.countDiff().then(function() {
                    self.trigger('changed');
                });
            });
    
            ladiesStatus.detectedLadiesStatus(self.props);
            return Promise.resolve();
        }, function(error) {
            return Promise.reject(error);
        }).then(
            function() {
                return self.countDiff();
            }, function(error) {
                return Promise.reject(error);
            }
        ).catch(function(e){
    
        });
    }
    
    countDiff () {
        var self = this;
        return new Promise (
            function (resolve, reject) {
                window.instancesPool.get('Times').then(
                    function (udtime) {
                        var timeZone = udtime.getCurrentTimeZone();
                        var timeZoneUkr = udtime.timeZones.find( function (timeZone) {
                            return timeZone.id === "142"
                        });
    
                        self.soon.forEach(
                            function (soon) {
                                soon.chatInfo.userDateTo = new Date(soon.chatInfo.date_to);
                                soon.chatInfo.userDateTo = udtime.setTimeZone(soon.chatInfo.userDateTo, timeZone);
                                soon.chatInfo.userDateToUkr = new Date(soon.chatInfo.date_to);
                                soon.chatInfo.userDateToUkr = udtime.setTimeZone(soon.chatInfo.userDateToUkr, timeZoneUkr);
                                soon.chatInfo.userDateFrom = new Date(soon.chatInfo.date_from);
                                soon.throughToInt = soon.chatInfo.userDateFrom - new Date;
                                soon.chatInfo.userDateFrom = udtime.setTimeZone(soon.chatInfo.userDateFrom, timeZone);
                                soon.throughToString = udtime.intTimeToString(soon.throughToInt, soon.chatInfo.userDateFrom, soon.chatInfo.delay, 'soon');
                                soon.chatInfo.userDateFromUkr = new Date(soon.chatInfo.date_from);
                                soon.chatInfo.userDateFromUkr = udtime.setTimeZone(soon.chatInfo.userDateFromUkr, timeZoneUkr);
    
                            }
                        );
                        resolve();
                    }
                )
            }
        )
    }
    
    sortDateASC = function(chatLadies) {
        return chatLadies.sort(function (a,b) {
            var timeStartA = a.chatInfo.userDateFrom.getTime();
            var timeStartB = b.chatInfo.userDateFrom.getTime();
            return (timeStartA > timeStartB ? 1 : (timeStartA < timeStartB ? -1 : 0));
        }) || [];
    }
    
    sortSoonDateASC (list) {
        return this.sortDateASC(this.soon);
    };
    
    _sortByStatus (items, status) {
        return items.filter(function (item) {
            return item.status == status
        })
    }
    
    sortSoonByLadiesAndDate () {
        var sweetheartsSoon = this._sortByStatus(this.soon, "sweetheart");
        var favoritesSoon = this._sortByStatus(this.soon, "favorite");
        var defaultSoon = this._sortByStatus(this.soon, "default");
    
        return this.sortDateASC(sweetheartsSoon).concat(
            this.sortDateASC(favoritesSoon), this.sortDateASC(defaultSoon)
        );
    };
    
    sortOnlineByStatus () {
        return this._sortByStatus(this.online, "sweetheart").concat(
            this._sortByStatus(this.online, "favorite"), this._sortByStatus(this.online, "default")
        );
    }
    
    _sortOnlineByRoomId (collection) {
        return collection.sort(function (a,b) {
            return (a.value < b.value ? 1 : (a.value > b.value ? -1 : 0));
        });
    }
    
    sortOnlineByStatusAndRoomId () {
        var sweetheartsOnline = this._sortByStatus(this.online, "sweetheart");
        var favoritesOnline = this._sortByStatus(this.online, "favorite");
        var defaultOnline = this._sortByStatus(this.online, "default");
        return this._sortOnlineByRoomId(sweetheartsOnline).concat(
            this._sortOnlineByRoomId(favoritesOnline), this._sortOnlineByRoomId(defaultOnline)
        );
    }
    
    filterOnlineAndSort (){
        return this.sortOnlineByStatusAndRoomId().filter(function(lady) {
            return lady.chatInfo.happyHour.access !== 1;
        })
    }
    
    updateOnlineSoonTextesOnPage () {
        $(".js-ladies-online").text(this.online.length);
        $(".js-ladies-soon").text(this.soon.length);
    }
    
    // DEPRECATED
    prepareTextesForLadiesObject () {
        var lady, favClass = 'deleted', favTooltip = 'Add to favorites';
        for (var i = 0; i < this.props.length; i++) {
            lady = this.props[i];
            lady.bday = (lady.isBirthdayToday ? 'bday' : 'hide');
            if (lady.inFavs && lady.inSubs) {
                favClass = 'subscribed';
                favTooltip = 'Unsubscribe and delete from favorites';
            } else if (lady.inFavs) {
                favClass = 'added';
                favTooltip = 'Subscribe';
            } else {
                favClass = 'deleted';
                favTooltip = 'Add to favorites';
            }
    
            lady.in_fav = favClass;
            lady.fav_tooltip = favTooltip;
        }
    }
    sortByFavs () {
        if (this.props) {
            const flowGirlId = this.flowGirlId
            return this.props.sort(function compare(a, b) {
                if (a.chatInfo.type < b.chatInfo.type) {
                    return -1;
                }
                if (a.chatInfo.type < b.chatInfo.type) {
                    return 1;
                }
                if (a.chatInfo.type === b.chatInfo.type && a.chatInfo.type === 'online') {
                    if (a.inFavs > b.inFavs || a.id === flowGirlId) {
                        return -1;
                    }
                    if (a.inFavs < b.inFavs) {
                        return 1;
                    }
                }
                return 0;
            });
        }
    }
    
    getLadiesByStatusInChat (statusInChat) {
        if (this.props) {
            return this.props.filter(function (lady) {
                return lady.chatInfo.type === statusInChat;
            });
        }
    }
    getByGid (gid) {
        return this.props.find( function(onlSoon){
            return onlSoon.id == gid;
        })
    }

    getByRoomId(roomId){
        return this.props.find( function(onlSoon){
            return onlSoon.value == roomId;
        })
    }
    getChatHistory (lastId) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/chat/history';
        if(lastId) {
            url = '/user/' + userId + '/chat/history?lastID=' + lastId;
        }
        return requestApi(url, 'GET', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    getHappHourLadies () {
        var onlineLadies = this.sortOnlineByStatusAndRoomId();
        var list = onlineLadies.filter(function(ladie) {
            return ladie.chatInfo.happyHour.access === 1;
        });

        return list;
    }
    
    isHappyHourLadie = function(id) {
        return !!(this.online && this.online.find(function(lady) {
            return lady.id === id 
            && lady.chatInfo.happyHour.access === 1
            && lady.chatInfo.happyHour.reason!='used'
        }));
    }
    
    happyHourLimitReached = function() {
        var filterLadies = this.getHappHourLadies().filter(function(lady) {
            return lady.chatInfo.happyHour.happyCredits <= 0
        });
    
        if (filterLadies.length) {
            return filterLadies;
        } else {
            return false;
        }
    }
    static goToFreeChat = function(roomId, ladyId, callBack) {
    
        var userId = getCookie('logid');
    
        if (userId <= 0) {
            return Promise.resolve();
        }
    
        window.instancesPool.gets(['User','Basket']).then(function([user, {credits}]) {
            if(!user.canHappyHour && !(user.isFlow && credits.chat.total>0) ) {
                window.fetchPopupData({
                    popupType: "CreditsExchange", 
                    props: {
                        type: "happy-hour"
                    }
                })
            } else {
                var url = '/user/' + userId + '/chat/happyhour?roomId=' + roomId + '&gid=' + ladyId;
                return requestApi(url, 'POST', null, 'json').then(function(res) {
                    var link = '/startvideochat/' + roomId;
                    if(res.access && user.canHappyHour) {
                        link += '/free'
                    }
                    window.location.href = link; 
                });
            }
        });
    }
    
    static goToChat = function(roomId) {
        window.instancesPool.gets(['Basket','OnlineSoonLadies']).then(function([basket,onlineSoon]) {
            function goToChatRoom() {
                var link = '/startvideochat/' + roomId;
                window.location.href = link; 
            }

            var lady = onlineSoon.getByRoomId(roomId);
            
            if(basket.credits.chat.total > 0 || lady.chatInfo?.isFree) {
                goToChatRoom()
            } else {
                window.fetchPopupData({
                    popupType: "CreditsExchange", 
                    props: {
                        type: "chat",
                        onExchange: goToChatRoom
                    }
                })
            }
        })
    }
    
    isLadyOnline = function(ladyId) {
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/ladies/chatGirlCheck?girlId=' + ladyId
        return requestApi(url, 'GET', null, 'json').then(function(resp) {
            return Promise.resolve(resp);
        });
    }
    
    getAllCollectionsIds = function() {
        var self = this;
        var returnId = function(item) {
            if(item.id != 102){
                return item.id;
            }
        }
        var ids = self.online.map(returnId);
        return ids.concat(self.soon.map(returnId));
    }

    static checkRoom(room) {
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/chat/getFreshRoom?roomId=' + room;
        if(userId > 10000) {
            return requestApi(url, 'GET', null, 'json')
        }
    }
    
    static clickStatistic = function(gid, inviteType, path) {
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/chat/statsInvites';
        var data = {
            gid: gid,
            inviteType: inviteType,
            path: path,
            page: window.location.pathname
        }

        if(userId > 10000) {
            window.instancesPool.get('OnlineSoonLadies').then(function(onlineSoon) {
                var lady = onlineSoon.getByGid(gid);
                if(lady && lady.type === 'online') {
                    requestApi(url, 'POST', data, null, 'json')
                }
            })
        }
    }
}
