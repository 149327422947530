import React from 'react';
import { instancesPool } from '../old/models';

export const createPriceString = (price, currency) => {
    let cent = '00';
    if (new String(price).split('.')[1]) cent = new String(price).split('.')[1];
    if (cent.length === 1) cent += '0';
    let currencySymbol = '';
    if (currency === 'dollar') {
        currencySymbol = '$'
    }
    if (currency === 'euro') {
        currencySymbol = '€'
    }
    return `${currencySymbol}${new String(price).split('.')[0]}.${cent.slice(0, 2)}`
}

export const createGiftsArr = (basket) => {
    return new Promise(resolve => {
        const giftsItems = basket.basketItems?.filter(item => item.needGirl === 1)
        if (giftsItems?.length) {
            window.instancesPool.gets(['Ladies']).then(([ladies]) => {
                let ladiesIdsArr = [];
                let noLadyGifts = [];
                giftsItems.map(item => {
                    if (!item.gid || item.gid < 1000) {
                        noLadyGifts.push({ ladyId: false, lady: false, gifts: [item] })
                    } else if (item.gid && !ladiesIdsArr.includes(item.gid)) {
                        ladiesIdsArr.push(item.gid)
                    }
                })
                ladies.getLadies(ladiesIdsArr).then(resp => {
                    let withLadiesGifts = resp.map(lady => {
                        const giftObj = {
                            ladyId: lady.id,
                            lady,
                            gifts: []
                        }
                        giftsItems.map(gift => {
                            if (gift.gid === lady.id) {
                                giftObj.gifts = [...giftObj.gifts, gift]
                            }
                        })
                        return giftObj
                    })
                    let giftsArr = [...withLadiesGifts, ...noLadyGifts];
                    resolve(giftsArr)
                })
            })
        } else {
            resolve([])
        }
    })
}

export const createServicesArr = (basket) => {
    const servicesInitial = basket.basketItems?.filter(item => item.needGirl === 0);
    if (servicesInitial?.length) {
        let servicesCount = {};
        servicesInitial.map(item => {
            if (servicesCount[item.giftID]) {
                servicesCount[item.giftID].count = servicesCount[item.giftID].count + 1;
            } else {
                item.count = 1
                servicesCount[item.giftID] = item;
            }
        })
        return Object.values(servicesCount).sort((a, b) => a.payID - b.payID)
    }
    return []
}

export const getSmsDate = (date) => {
    const mailDate = new Date(date);
    let hours = ("0" + mailDate.getHours()).slice(-2);
    let mins = ("0" + mailDate.getMinutes()).slice(-2);
    mailDate.setSeconds(0);
    let dateText = `Delivery: ${mailDate.getDate()}th of ${mailDate.toLocaleString('en', { month: 'long' })} at ${hours}:${mins} (Ukraine, GMT+2)`;

    return dateText;
}

export const getMailboxDate = (date) => {
    const today = new Date();
    today.setMinutes(0)
    today.setSeconds(0)
    const mailDate = new Date(date);
    mailDate.setMinutes(0)
    mailDate.setSeconds(0)

    const difDays = Math.floor((today - mailDate) / 86400000) + (mailDate.getHours() > today.getHours() ? 1 : 0);
    const todayCheck = today.getDate() === mailDate.getDate()
        && today.getMonth() === mailDate.getMonth()
        && today.getFullYear() === mailDate.getFullYear();
        
    let dateText = '';
    if (todayCheck === true) {
        dateText = 'today'
    } else if ((difDays === 0 || difDays === 1) && todayCheck === false) {
        dateText = 'yesterday'
    } else if (difDays <= 9) {
        dateText = `${difDays} days ago`
    } else {
        if (mailDate.getFullYear() === today.getFullYear()) {
            dateText = `${mailDate.getDate()}-${mailDate.toLocaleString('en', { month: 'short' })}`
        } else {
            dateText = `${mailDate.getDate()}-${mailDate.toLocaleString('en', { month: 'short' })}-${mailDate.getFullYear() - 2000}`
        }
    }
    return dateText
}

export const prepareLetterList = (body, lady) => {
    let letterBody = body;
    if (body) {
        if (lady) {
            letterBody = body.replace(`THIS LETTER WAS TRANSLATED FOR ${lady ? lady.name.toUpperCase() : ''}`, '');
        }

        try {

            letterBody = '<p>' + letterBody.replaceAll(/\r\n|\n\r|\n/g, "</p><p>") + '</p>';

            letterBody = letterBody.replaceAll(/\*(\d+)\*/gi, '<img title="$1" src="https://www.uadreams.com/inc/images/winks/$1.png"/>')
            letterBody = letterBody.replaceAll(/\$(\d+)\$/gi, '<img title="$1" src="https://www.uadreams.com/img/smile/$1.gif"/>')

            letterBody = letterBody.replaceAll(':)', '<img smile-code=":)" src="https://www.uadreams.com/img/smile/01.gif" title="$01$" />')
            letterBody = letterBody.replaceAll('o:-)', '<img smile-code="o:-)" src="https://www.uadreams.com/img/smile/07.gif" title="$07$" />')
            letterBody = letterBody.replaceAll(';)', '<img smile-code=";)" src="https://www.uadreams.com/img/smile/05.gif" title="$05$" />')
            letterBody = letterBody.replaceAll(':babah:', '<img smile-code=":babah:" src="https://www.uadreams.com/img/smile/03.gif" title="$03$" />')
            letterBody = letterBody.replaceAll('8)', '<img smile-code="8)" src="https://www.uadreams.com/img/smile/04.gif" title="$04$" />')
            letterBody = letterBody.replaceAll(':sorry2:', '<img smile-code=":sorry2:" src="https://www.uadreams.com/img/smile/06.gif" title="$06$" />')
            letterBody = letterBody.replaceAll(':smoke:', '<img smile-code=":smoke:" src="https://www.uadreams.com/img/smile/02.gif" title="$02$" />')
            letterBody = letterBody.replaceAll(':(', '<img smile-code=":(" src="https://www.uadreams.com/img/smile/08.gif" title="$08$" />')
            letterBody = letterBody.replaceAll('*WRITE*', '<img smile-code="*WRITE*" src="https://www.uadreams.com/img/smile/09.gif" title="$09$" />')
            letterBody = letterBody.replaceAll('*BYE*', '<img smile-code="*BYE*" src="https://www.uadreams.com/img/smile/10.gif" title="$10$" />')
            letterBody = letterBody.replaceAll('*DONT_KNOW*', '<img smile-code="*DONT_KNOW*" src="https://www.uadreams.com/img/smile/11.gif" title="$11$" />')
            letterBody = letterBody.replaceAll('*INLOVE*', '<img smile-code="*INLOVE*" src="https://www.uadreams.com/img/smile/12.gif" title="$12$" />')
            letterBody = letterBody.replaceAll('*KISSING*', '<img smile-code="*KISSING*" src="https://www.uadreams.com/img/smile/13.gif" title="$13$" />')
            letterBody = letterBody.replaceAll('*HI*', '<img smile-code="*HI*" src="https://www.uadreams.com/img/smile/14.gif" title="$14$" />')
            letterBody = letterBody.replaceAll('*:-D*', '<img smile-code="*:-D*" src="https://www.uadreams.com/img/smile/15.gif" title="$15$" />')
            letterBody = letterBody.replaceAll(':-P', '<img smile-code=":-P" src="https://www.uadreams.com/img/smile/16.gif" title="$16$" />')
            letterBody = letterBody.replaceAll('=-o', '<img smile-code="=-o" src="https://www.uadreams.com/img/smile/17.gif" title="$17$" />')
            letterBody = letterBody.replaceAll('*KISSED*', '<img smile-code="*KISSED*" src="https://www.uadreams.com/img/smile/18.gif" title="$18$" />')
            letterBody = letterBody.replaceAll('*NO*', '<img smile-code="*NO*" src="https://www.uadreams.com/img/smile/19.gif" title="$19$" />')
            letterBody = letterBody.replaceAll('*MUSIC*', '<img smile-code="*MUSIC*" src="https://www.uadreams.com/img/smile/20.gif" title="$20$" />')
            letterBody = letterBody.replaceAll(':-[', '<img smile-code=":-[" src="https://www.uadreams.com/img/smile/21.gif" title="$21$" />')
            letterBody = letterBody.replaceAll(':-*', '<img smile-code=":-*" src="https://www.uadreams.com/img/smile/22.gif" title="$22$" />')
            letterBody = letterBody.replaceAll(':\'(', '<img smile-code=":\'(" src="https://www.uadreams.com/img/smile/23.gif" title="$23$" />')
            letterBody = letterBody.replaceAll(':-X', '<img smile-code=":-X" src="https://www.uadreams.com/img/smile/24.gif" title="$24$" />')
            letterBody = letterBody.replaceAll('&gt;:o', '<img smile-code="&gt;:o" src="https://www.uadreams.com/img/smile/25.gif" title="$25$" />')
            letterBody = letterBody.replaceAll(':-|', '<img smile-code=":-|" src="https://www.uadreams.com/img/smile/26.gif" title="$26$" />')
            letterBody = letterBody.replaceAll(':-\\', '<img smile-code=":-\\" src="https://www.uadreams.com/img/smile/27.gif" title="$27$" />')
            letterBody = letterBody.replaceAll(']:-&gt;', '<img smile-code="]:-&gt;" src="https://www.uadreams.com/img/smile/28.gif" title="$28$" />')
            letterBody = letterBody.replaceAll(':-!', '<img smile-code=":-!" src="https://www.uadreams.com/img/smile/29.gif" title="$29$" />')
            letterBody = letterBody.replaceAll('%)', '<img smile-code="%)" src="https://www.uadreams.com/img/smile/31.gif" title="$31$" />')
            letterBody = letterBody.replaceAll('*WASSUP*', '<img smile-code="*WASSUP*" src="https://www.uadreams.com/img/smile/32.gif" title="$32$" />')
            letterBody = letterBody.replaceAll('*BRAVO*', '<img smile-code="*BRAVO*" src="https://www.uadreams.com/img/smile/33.gif" title="$33$" />')
            letterBody = letterBody.replaceAll('*ROFL*', '<img smile-code="*ROFL*" src="https://www.uadreams.com/img/smile/34.gif" title="$34$" />')
            letterBody = letterBody.replaceAll('*YAHOO*', '<img smile-code="*YAHOO*" src="https://www.uadreams.com/img/smile/35.gif" title="$35$" />')
            letterBody = letterBody.replaceAll('*yes*', '<img smile-code="*yes*" src="https://www.uadreams.com/img/smile/36.gif" title="$36$" />')
            letterBody = letterBody.replaceAll(';D', '<img smile-code=";D" src="https://www.uadreams.com/img/smile/37.gif" title="$37$" />')
            letterBody = letterBody.replaceAll('*scratch*', '<img smile-code="*scratch*" src="https://www.uadreams.com/img/smile/38.gif" title="$38$" />')
            letterBody = letterBody.replaceAll('*SHOUT*', '<img smile-code="*SHOUT*" src="https://www.uadreams.com/img/smile/39.gif" title="$39$" />')
            letterBody = letterBody.replaceAll('*VAVA*', '<img smile-code="*VAVA*" src="https://www.uadreams.com/img/smile/40.gif" title="$40$" />')
            letterBody = letterBody.replaceAll('*BEACH*', '<img smile-code="*BEACH*" src="https://www.uadreams.com/img/smile/41.gif" title="$41$" />')
            letterBody = letterBody.replaceAll('*FOCUS*', '<img smile-code="*FOCUS*" src="https://www.uadreams.com/img/smile/42.gif" title="$42$" />')
            letterBody = letterBody.replaceAll('*MEGA_SHOK*', '<img smile-code="*MEGA_SHOK*" src="https://www.uadreams.com/img/smile/43.gif" title="$43$" />')
            letterBody = letterBody.replaceAll('*THANK*', '<img smile-code="*THANK*" src="https://www.uadreams.com/img/smile/44.gif" title="$44$" />')
            letterBody = letterBody.replaceAll('*KING*', '<img smile-code="*KING*" src="https://www.uadreams.com/img/smile/45.gif" title="$45$" />')
            letterBody = letterBody.replaceAll('*LAZY*', '<img smile-code="*LAZY*" src="https://www.uadreams.com/img/smile/46.gif" title="$46$" />')
            letterBody = letterBody.replaceAll('*punish*', '<img smile-code="*punish*" src="https://www.uadreams.com/img/smile/47.gif" title="$47$" />')
            letterBody = letterBody.replaceAll('*TEASE*', '<img smile-code="*TEASE*" src="https://www.uadreams.com/img/smile/48.gif" title="$48$" />')
            letterBody = letterBody.replaceAll('*scare*', '<img smile-code="*scare*" src="https://www.uadreams.com/img/smile/49.gif" title="$49$" />')
            letterBody = letterBody.replaceAll(':yess:', '<img smile-code=":yess:" src="https://www.uadreams.com/img/smile/50.gif" title="$50$" />')
            letterBody = letterBody.replaceAll('*TIRED*', '<img smile-code="*TIRED*" src="https://www.uadreams.com/img/smile/51.gif" title="$51$" />')
            letterBody = letterBody.replaceAll(':hi:', '<img smile-code=":hi:" src="https://www.uadreams.com/img/smile/52.gif" title="$52$" />')
            letterBody = letterBody.replaceAll(':good:', '<img smile-code=":good:" src="https://www.uadreams.com/img/smile/53.gif" title="$53$" />')
            letterBody = letterBody.replaceAll(':sorry:', '<img smile-code=":sorry:" src="https://www.uadreams.com/img/smile/54.gif" title="$54$" />')
            letterBody = letterBody.replaceAll('*angry*', '<img smile-code="*angry*" src="https://www.uadreams.com/img/smile/55.gif" title="$55$" />')
            letterBody = letterBody.replaceAll(':victory:', '<img smile-code=":victory:" src="https://www.uadreams.com/img/smile/56.gif" title="$56$" />')
            letterBody = letterBody.replaceAll('*SPITEFUL*', '<img smile-code="*SPITEFUL*" src="https://www.uadreams.com/img/smile/57.gif" title="$57$" />')
            letterBody = letterBody.replaceAll(':help:', '<img smile-code=":help:" src="https://www.uadreams.com/img/smile/58.gif" title="$58$" />')
            letterBody = letterBody.replaceAll('*CRAZY*', '<img smile-code="*CRAZY*" src="https://www.uadreams.com/img/smile/59.gif" title="$59$" />')
            letterBody = letterBody.replaceAll(':drinks:', '<img smile-code=":drinks:" src="https://www.uadreams.com/img/smile/60.gif" title="$60$" />')
            letterBody = letterBody.replaceAll(':search:', '<img smile-code=":search:" src="https://www.uadreams.com/img/smile/61.gif" title="$61$" />')
            letterBody = letterBody.replaceAll('*STINKER*', '<img smile-code="*STINKER*" src="https://www.uadreams.com/img/smile/62.gif" title="$62$" />')
            letterBody = letterBody.replaceAll(':wub:', '<img smile-code=":wub:" src="https://www.uadreams.com/img/smile/63.gif" title="$63$" />')
            letterBody = letterBody.replaceAll(':0k:', '<img smile-code=":0k:" src="https://www.uadreams.com/img/smile/64.gif" title="$64$" />')
            letterBody = letterBody.replaceAll(':rtfm:', '<img smile-code=":rtfm:" src="https://www.uadreams.com/img/smile/65.gif" title="$65$" />')
            letterBody = letterBody.replaceAll('*LOL*', '<img smile-code="*LOL*" src="https://www.uadreams.com/img/smile/66.gif" title="$66$" />')
            letterBody = letterBody.replaceAll('*VAMPIRE*', '<img smile-code="*VAMPIRE*" src="https://www.uadreams.com/img/smile/67.gif" title="$67$" />')
            letterBody = letterBody.replaceAll(':bouquet:', '<img smile-code=":bouquet:" src="https://www.uadreams.com/img/smile/68.gif" title="$68$" />')
            letterBody = letterBody.replaceAll(':first_touch:', '<img smile-code=":first_touch:" src="https://www.uadreams.com/img/smile/69.gif" title="$69$" />')
            letterBody = letterBody.replaceAll(':love_letter:', '<img smile-code=":love_letter:" src="https://www.uadreams.com/img/smile/70.gif" title="$70$" />')
            letterBody = letterBody.replaceAll(':fall_in_love:', '<img smile-code=":fall_in_love:" src="https://www.uadreams.com/img/smile/71.gif" title="$71$" />')
            letterBody = letterBody.replaceAll(':rose:', '<img smile-code=":rose:" src="https://www.uadreams.com/img/smile/72.gif" title="$72$" />')
            letterBody = letterBody.replaceAll(':struck_on:', '<img smile-code=":struck_on:" src="https://www.uadreams.com/img/smile/73.gif" title="$73$" />')
            letterBody = letterBody.replaceAll(':serenade:', '<img smile-code=":serenade:" src="https://www.uadreams.com/img/smile/74.gif" title="$74$" />')
            letterBody = letterBody.replaceAll(':passionate_kiss:', '<img smile-code=":passionate_kiss:" src="https://www.uadreams.com/img/smile/75.gif" title="$75$" />')
            letterBody = letterBody.replaceAll(':sweet_kiss:', '<img smile-code=":sweet_kiss:" src="https://www.uadreams.com/img/smile/76.gif" title="$76$" />')
            letterBody = letterBody.replaceAll(':embrace:', '<img smile-code=":embrace:" src="https://www.uadreams.com/img/smile/77.gif" title="$77$" />')
            letterBody = letterBody.replaceAll(':dance:', '<img smile-code=":dance:" src="https://www.uadreams.com/img/smile/78.gif" title="$78$" />')
            letterBody = letterBody.replaceAll(':angel:', '<img smile-code=":angel:" src="https://www.uadreams.com/img/smile/79.gif" title="$79$" />')
            letterBody = letterBody.replaceAll(':umbrella:', '<img smile-code=":umbrella:" src="https://www.uadreams.com/img/smile/80.gif" title="$80$" />')
            letterBody = letterBody.replaceAll(':seesaw:', '<img smile-code=":seesaw:" src="https://www.uadreams.com/img/smile/81.gif" title="$81$" />')
            letterBody = letterBody.replaceAll('/> </p><p>', '/> ')
        } catch (e) {
            console.warn(e);
        }

    }
    return letterBody
}

export const parseMail = (body) => {
    if (!body) return { subject: '', textMail: '' }

    let { subject, text } = getMailText(body);
    let textMail = prepareLetterList(text);

    if (textMail.includes('<p>SMS')) {
        textMail = textMail.replace('<p>SMS', '')
        subject = 'SMS'
    }

    return { subject, textMail }
}

export const getMailText = (body) => {
    const $span = document.createElement('span');
    $span.innerHTML = body;
    const $subject = $span.querySelector('b');
    let subject = '';
    if ($subject) {
        subject = $subject.textContent;
        $subject.parentNode.removeChild($subject)
    }
    let text = $span.textContent;
    if (text[0] === ' ') {
        text = text.slice(1);
    }
    return { subject, text }
}
let hoverTimer = null;
export const hoverTrottler = () => {
    clearTimeout(hoverTimer);
    return new Promise((resolve) => {
        hoverTimer = setTimeout(resolve, 150)
    })
}

export const createBtnClassByColor = (color) => {
    let btnClass = 'button-success';
    switch (color) {
        case 'green':
            break;
        case 'light-red':
            btnClass = 'button-danger'
            break;
        case 'dark-red':
            btnClass = 'button-primary';
            break;
        case 'yellow':
            btnClass = 'button-warning';
            break;
        case 'blue':
            btnClass = 'button-info';
            break;
        case 'dark-blue':
            btnClass = 'button-dark-blue';
            break;
        case 'light-gray':
            btnClass = 'button-light';
            break;
        case 'dark-gray':
            btnClass = 'button-dark';
            break;
        case 'happy-hour':
            btnClass = 'button-happy-hour';
            break;
    }
    return btnClass
}

export const filterBranchLady = (e, city, isLogged) => {
    if (isLogged) {
        e.preventDefault()
        e.stopPropagation()
        let filterCity = city;
        let filterUrl = "/ukrainian-women-photo-gallery?girlWantKids=0&girlHaveKids=0&branches=" + filterCity + "&ageFrom=18&ageTo=63&heightFrom=0&heightTo=183&weightFrom=0&weightTo=100&page=1";
        window.location.href = filterUrl;
    }
}

export const editProfileErrosMapping = {
    height: { step: 'first', error: 'heightError', },
    weight: { step: 'first', error: 'weightError', },
    city: { step: 'first', error: 'cityError', },
    marital: { step: 'first', error: 'maritalError', },
    empstat: { step: 'first', error: 'empstatError', },
    bd: { step: 'first', error: 'currentByearValueError', },
    about: { step: 'first', error: 'aboutError', },

    education: { step: 'second', error: 'educationError', },
    race: { step: 'second', error: 'raceError', },
    religion: { step: 'second', error: 'religionError', },
    eyes: { step: 'second', error: 'eyesError', },
    glasses: { step: 'second', error: 'glassesError', },
    hair: { step: 'second', error: 'hairError', },
    smoke: { step: 'second', error: 'smokeError', },
    drinking: { step: 'second', error: 'drinkingError', },
    children: { step: 'second', error: 'childrenError', },
    employment: { step: 'second', error: 'employmentError', },
    occupation: { step: 'second', error: 'occupationError', },
    hobbies: { step: 'second', error: 'hobbiesError', },
}

export const getStepErrors = (dataList) => {
    let firstStapErrors = {};
    let secondStapErrors = {};
    dataList.forEach(item => {
        const mappingItem = editProfileErrosMapping[item];
        if (mappingItem) {
            if (mappingItem.step === 'first') {
                firstStapErrors[mappingItem.error] = 'add info';
            } else {
                secondStapErrors[mappingItem.error] = 'add info';
            }
        }
    })
    return { firstStapErrors, secondStapErrors }
}

export const createGMT = (diff) => {
    let gmt = '';
    if (typeof +diff === "number" && +diff) {
        const time = +diff / 60;
        gmt = time > 0 ? `+${time} GMT` : `${time} GMT`
    } else if (+diff === 0) {
        gmt = '+0 GMT'
    }
    return gmt
}

// функция для рекурсивного изменения всех child элементов
export const recursiveMap = (children, fn) => {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return child;
        }
        if (child.props.children) {
            child = React.cloneElement(child, {
                children: recursiveMap(child.props.children, fn)
            });
        }
        return fn(child);
    });
}

export const getMonthName = (monthNumber) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[monthNumber];
}

export const formatNumber = (format, number) => {
    var formattedNumber,
        indexFormat,
        indexNumber,
        lastCharacter;

    formattedNumber = '';
    number = String(number).replace(/\D/g, '');


    for (indexFormat = 0, indexNumber = 0; indexFormat < format.length; indexFormat = indexFormat + 1) {
        if (/\d/g.test(format.charAt(indexFormat))) {
            if (format.charAt(indexFormat) === number.charAt(indexNumber)) {
                formattedNumber += number.charAt(indexNumber);
                indexNumber = indexNumber + 1;
            } else {
                formattedNumber += format.charAt(indexFormat);
            }
        } else if (format.charAt(indexFormat) !== 'd') {
            if (number.charAt(indexNumber) !== '' || format.charAt(indexFormat) === '+') {
                formattedNumber += format.charAt(indexFormat);
            }
        } else {
            if (number.charAt(indexNumber) === '') {
                formattedNumber += '';
            } else {
                formattedNumber += number.charAt(indexNumber);
                indexNumber = indexNumber + 1;
            }
        }
    }

    lastCharacter = format.charAt(formattedNumber.length);
    if (lastCharacter !== 'd') {
        formattedNumber += lastCharacter;
    }

    return formattedNumber;
}

export const setSessionCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        secure: true,
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
}

export const getAllSessionCookie = () => {
    return document.cookie.split(";").reduce( (ac, cv, i) => Object.assign(ac, {[cv.split('=')[0].trim()]: cv.split('=')[1].trim()}), {})
}

export const getSessionCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

export const setCookie = (name, val) => {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + val + "; " + expires;
}

export const setCookieGlobal = (cname, cvalue, exdays) => {
    var expires = '';
    if (exdays != undefined) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
    };
    document.cookie = cname + "=" + cvalue + "; " + expires;
};

export const setCookieLearn = function (name, value) {
    var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    options = Object.assign({
        path: '/'
    }, options);

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    var updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (var optionKey in options) {
        updatedCookie += "; " + optionKey;
        var optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export const getBrowserId = () => {
    let browserId = getCookie('notifications_browser_id');
    if (!browserId) {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        browserId = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        window.setCookieGlobal("notifications_browser_id", browserId, 1000);
    }
    return browserId
}

export const parseQuery = (queryString) => {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export const createQuery = (data) => {
    var request = '';
    for (var key in data) {
        if (!key) {
            continue;
        }
        if (data[key] !== '') {
            request += key + '=' + data[key] + '&'
        }
    }
    if (request[request.length - 1] === "&") {
        request = request.substring(0, request.length - 1)
    }
    return request
}

export const decodeHTML = (html) => {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

export const initReactPopup = (type, data) => {
    instancesPool.get('ReactPopup').then(function (reactPopup) {
        let reactContainer = document.querySelector('[data-react-container="react-portal"]');
        let mobilePopupContainer = document.querySelector('.mobilepopup.open');
        let isReactPopup = reactContainer.childElementCount !== 0;
        let isMobilePopup = (mobilePopupContainer && mobilePopupContainer.childElementCount !== 0);
        let isLG = false;
        if(type !== "showWinksPopup" && (!!document.querySelector('.light_gallery_container') || !!document.querySelector('.react-lg-container'))) {
            isLG = true;
        }
        if (isMobilePopup || (isLG && type !== 'showCreditsPopup' && type !== 'creditsAfterAdd' && type !== 'initConfirmPopup') || isReactPopup) {
            let lastIndex = reactPopup.stack.length - 1;

            if (!reactPopup.stack.length || reactPopup.stack.length && reactPopup.stack[lastIndex].type !== type) {
                reactPopup.setToStack(type, data, false)
            }
        } else {
            if (window.udapp && window.udapp.globalController) {
                reactPopup.setToStack(type, data, true)
                window.udapp.globalController[type](data);
            } else {
                window.needPopup = { type: type, data: data }
            }

            $('select').blur();
        }
    })
}

export const closeReactPopup = () => {
    window.udapp.globalController && window.udapp.globalController.closeReactPopup();
    window.instancesPool.get('User').then(function (user) {
        user.closeBottomOnline();
    })
}

export const goToPage = (link) => {
    window.location.href = link
}


export const createChatCreditsString = (credits, type) => {
    let hours = 0;
    let min = 0;
    let sec = 0;

    if (credits > 0) {
        hours = Math.floor(credits / 3600);
        min = Math.floor((credits - hours * 3600) / 60);
        sec = credits - hours * 3600 - min * 60;
    }


    let timeString = '';

    if (hours) {
        timeString += hours + ' h ';
    }

    if (min) {
        timeString += min + ' m';
    }

    if (sec && type === 'seconds') {
        timeString += ` ${sec} s`
    }

    if (credits <= 59 && !type) {
        return '0 m'
    }
    return timeString
}

export const $ = window.$;
