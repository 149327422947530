import axios from "axios"
import Cookies from "js-cookie"
import { devApiV2Url } from "../constants"
import isMobile from "is-mobile"

export default function api(url, method="GET", data=null){
    if(
        typeof(method)==="undefined" || !['GET','POST','PUT','DELETE'].includes(method) ||
        typeof(url)==="undefined" || url===""
    ){
        return null
    }
    
    ["pr", "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].map( item => {
        const currentUrl = new URL(window.location.href)
        let currentParam = currentUrl.searchParams.get(item) || null
        if(currentParam){
            Cookies.set(item, currentParam, {
                path : '/', 
                expires : 3650, 
                domain: window.location.hostname === 'localhost' ? null : '.uadreams.com'
            })
        }
    })
    if(!Cookies.get("entry_page")){
        Cookies.set("entry_page", window.location.pathname, {
            path : '/', 
            expires : 3650, 
            domain: window.location.hostname === 'localhost' ? null : '.uadreams.com'
        })
    }
    if(!Cookies.get("entry_device")){
        Cookies.set("entry_device", (isMobile() ? "mobile" : "desktop"), {
            path : '/', 
            expires : 3650, 
            domain: window.location.hostname === 'localhost' ? null : '.uadreams.com'
        })
    }
    
    let cookies = Cookies.getJSON()
    
    let options = {
        baseURL: devApiV2Url + "/v2" + url,
        method,
        responseType: "json",
        withCredentials: true,
        headers: {
            "Session-Data": Buffer.from(JSON.stringify(cookies)).toString('base64'),
            "Client-Auth": 'd2ViY2xpZW50IG1mOlM0VUd4LjdHSjY8VjJHVUhTZXlrNUdiZTY7PFdd'
        },
        transformResponse: [function (data, headers) {
            if(headers['set-session-data']){
                const sessionData = JSON.parse(headers['set-session-data'])
                if(Array.isArray(sessionData) && sessionData.length){
                    sessionData.forEach( item => {
                        if(item.name && item.value){
                            let cookieOptions = typeof(item.options)==="object" ? {...item.options} : {}
                            cookieOptions.secure = typeof(cookieOptions.secure)==="undefined" ? false : cookieOptions.secure 
                            if(typeof(cookieOptions.domain)==="undefined"){
                                cookieOptions.domain = '.uadreams.com'
                            }
                            if(window.location.hostname === 'localhost'){
                                delete(cookieOptions.domain)
                            }
                            Cookies.set(item.name, item.value, cookieOptions)
                        }
                    })
                }
            }
            return JSON.parse(data).data
        }],
    }

    if(data){
        options['data'] = data
    }

    return axios(options)
}