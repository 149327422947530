import { CustomCollection } from "../helpers/custom-classes"
import requestApi from "../helpers/request-api"

export class CurrentMarketing extends CustomCollection {
    list = {}

    promiseCreateInstance () {
        return Promise.resolve()
    };

    getBySlug (slug) {
        if(this.list[slug]){
            return Promise.resolve({...this.list[slug]})
        }

        return new Promise((resolve, reject) => {
            requestApi(`/marketing/info?slug=${slug}`, 'GET', 'json', 'json').then((response) => {
                if (typeof response === 'object' && response[slug]) {
                    this.list[slug] = {...response[slug]}
                    resolve({...this.list[slug]})
                }
                resolve(null)
            })
        })
    }
}