import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";
import { devApiUrl } from "../constants";

export class SupportPage extends CustomCollection {
    promiseCreateInstance () {
        var self = this;
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/support/unreadTickets';
            return requestApi(url, 'get', null, 'json').then(function (data) {
                self.newTickets = data.unreadTickets;
                self.activeTicketsCount = data.active;
                self.archiveTicketsCount = data.archive;
            })
        } else {
            return Promise.resolve();
        }
    };
    
    updateNewTickets () {
        var self = this;
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/support/unreadTickets';
            return requestApi(url, 'get', null, 'json').then(function (data) {
                self.newTickets = data.unreadTickets;
                self.activeTicketsCount = data.active;
                self.archiveTicketsCount = data.archive;
                self.trigger('newTicketsChanged')
            })
        }
    }
    
    getRewies () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/support/goodReview';
        if (userId > 0) {
            url = '/user/' + userId + '/support/goodReview';
        }
        return requestApi(url, 'get', null, 'json').then(
            function (data) {
                self.rewies = data;
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    
    sendTicket (data) {
        var url = '/support';
        var userId = getCookie('logid');
        
        if (userId > 0) {
            url = '/user/' + userId + '/support';
        }
    
        return requestApi(url, 'POST', data, 'json');
    };
    
    getActiveTickets () {
        var userId = getCookie('logid');
        if (userId > 0) {
            return requestApi('/user/' + userId + '/support/active', 'get', null, 'json').then(
                function (data) {
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            );
        }
    }
    
    getArchiveTickets () {
        var userId = getCookie('logid');
        if (userId > 0) {
            return requestApi('/user/' + userId + '/support/archive', 'get', null, 'json').then(
                function (data) {
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            );
        }
    }
    
    getTicketById (ticketId) {
        var self = this;
        var userId = getCookie('logid');
        if (userId > 0) {
            return requestApi('/user/' + userId + '/support/history?id=' + ticketId, 'get', null, 'json').then(
                function (data) {
                    self.updateNewTickets();
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            );
        }
    }
    
    getTicketByKey (ticketKey) {
        return requestApi('/support/token?token=' + ticketKey, 'get', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    
    reopenTicket (ticketId) {
        var userId = getCookie('logid');
        if (userId > 0) {
            return requestApi('/user/' + userId + '/support/reopen?id=' + ticketId, 'PUT', null, null).then(
                function (data) {
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }
    
    rateTicket (ticketID, rate, comment) {
        var userId = getCookie('logid');
    
        return new Promise(function (resolve, reject) {
            var url = devApiUrl + '/user/' + userId + '/support/rate?id=' + ticketID + '&rate=' + rate;
            if (comment) {
                url += '&comment=' + comment;
            }
    
            var xhr = new XMLHttpRequest();
            // xhr.withCredentials = true;
    
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    resolve(this.responseText);
                }
            });
            var ssid = getCookie('login_key');
            xhr.open("POST", url);
            xhr.setRequestHeader("api-token", ssid);
            xhr.send()
        })
    }
    
    traceTicket (key, word, email) {
        return requestApi('/support/trace?key=' + key + '&word=' + word + '&email=' + email, 'GET', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        )
    }
}