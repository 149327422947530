import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";

export class ShopPage extends CustomEventTarget {
    allServices = [];
    giftGroups = {};

    promiseCreateInstance () {
        var self = this;
        var url = '/marketing/season';
        var userId = getCookie('logid');
        if(userId > 10000) {
            url = '/user/' + userId + '/marketing/season';
        }
        return requestApi(url, 'get', null, 'json').then(function(data) {
            self.holiday = data
            return Promise.resolve(self.holiday);
        })
    };
    
    getServicesInfo () {
        var self = this;
        var url = '/services/all';
        var userId = getCookie('logid');
        if(userId > 10000) {
            url = '/user/' + userId + '/services/all';
        }
        if(self.allServices.length > 0) {
            return Promise.resolve(self.allServices);
        }
        return requestApi(url, 'get', null, 'json').then(
            function (data) {
                self.allServices = data;
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    };
    
    reloadServices () {
        var self = this;
        self.allServices = [];
        self.getServicesInfo().then(function(){
            self.trigger('reloadServices')
        })
    }
    
    getGiftsByGroup (groupID) {
        var self = this;
        var url = '/gifts/group?id=' + groupID;
        if(self.giftGroups[groupID]) {
            return Promise.resolve(self.giftGroups[groupID])
        }
        return requestApi(url, 'get', null, 'json').then(
            function (data) {
                self.giftGroups[groupID] = data;
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    };
    getGiftsById (ids) {
        var self = this;
        var userId = getCookie('logid');
        var url = '/gifts?ids=[' + ids + ']';
        return requestApi(url, 'get', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    getRandomGifts () {
        var url = '/gifts/random';
        return requestApi(url, 'get', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    };
    
    getGiftsGroups () {
        throw Error('Deprecated methods, use ShopNavigations');
    };
    
    getGiftGroupShowOnSite () {
        throw Error('Deprecated methods, use ShopNavigations');
    };
}