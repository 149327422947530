import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";

export class Invitations extends CustomCollection {
    constructor() {
        super();
        var self = this;

        // check edit timezone
        window.instancesPool.get('Times').then(
            function (udtime) {
                udtime.addListener('changed', function () {
                    self.countDiff().then(
                        function () { self.trigger('reload') },
                        console.error
                    )
                })
            }
        );
        this.statusMatrix = {
            'create-client-new': { status: 'clientInvite', popupClass: 'i-change-time' },
            'create-girl-new': { status: 'womenInvite', popupClass: 'invite-new-girl' },
            'change_time-client-new': { status: 'clientInvite', popupClass: 'i-change-time' },
            'change_time-girl-new': { status: 'womenChange', popupClass: 'invite-new-another' },
            'change_status-girl-accept': { status: 'agree', popupClass: 'invite-accept-i' },
            'change_status-client-accept': { status: 'agree', popupClass: 'invite-accept-i' },
            'change_status-girl-declined': { status: 'womenDecline', popupClass: 'invite-declined-girl' },
            'change_status-client-declined': { status: 'clientDecline', popupClass: 'i-change-time' }
        };
    }

    chatDatesLength () {
        return this.length;
    };
    
    promiseCreateInstance () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/invitations';
        return new Promise(function (resolve, reject) {
            if (userId <= 10000) {
                resolve();
            } else {
                requestApi(url, 'GET', null, 'json').then(function (props) {
                    for (var k in props.data) {
                        self.push(props.data[k]);
                    }
    
                    self.countDiff().then(resolve, reject);
                }, reject);
            }
        });
    };
    
    sortingDateASC () {
        return this.sort(function (a, b) {
            var timeStartA = a.dateTo.getTime();
            var timeStartB = b.dateTo.getTime();
            return (timeStartA > timeStartB ? 1 : (timeStartA < timeStartB ? -1 : 0));
        });
    };
    
    countDiff () {
        var self = this;
        return new Promise(
            function (resolve, reject) {
                window.instancesPool.get('Times').then(
                    function (adtime) {
                        var timeZone = adtime.getCurrentTimeZone();
                        var timeZoneUkr = adtime.timeZones.find(function (timeZone) { return timeZone.id === "142" });
    
                        self.forEach(
                            function (invitation) {
                                invitation.dateTo = new Date(invitation.plan_chat_time);
                                invitation.dateFromUkr = new Date(invitation.plan_chat_time);
    
                                if (invitation.chat_girls_soon_date_from) {
                                    invitation.dateTo = new Date(invitation.chat_girls_soon_date_from);
                                    invitation.dateFromUkr = new Date(invitation.chat_girls_soon_date_from);
    
                                    // calculate minute to start chat
                                    invitation.throughToInt = invitation.dateTo - new Date;
                                    invitation.dateTo = adtime.setTimeZone(invitation.dateTo, timeZone);
                                    invitation.dateFromUkr = adtime.setTimeZone(invitation.dateFromUkr, timeZoneUkr);
                                    invitation.throughToString = adtime.intTimeToString(invitation.throughToInt, invitation.dateTo, invitation.chat_girls_soon_delay, 'invitation');
                                } else {
                                    invitation.dateTo = adtime.setTimeZone(invitation.dateTo, timeZone);
                                    invitation.dateFromUkr = adtime.setTimeZone(invitation.dateFromUkr, timeZoneUkr);
                                }
    
                                invitation.dateFrom = null;
                                invitation.dateToUkr = null;
    
                                if (invitation.chat_girls_soon_date_to) {
                                    invitation.dateFrom = new Date(invitation.chat_girls_soon_date_to);
                                    invitation.dateFrom = adtime.setTimeZone(invitation.dateFrom, timeZone)
                                    invitation.dateToUkr = new Date(invitation.chat_girls_soon_date_to);
                                    invitation.dateToUkr = adtime.setTimeZone(invitation.dateToUkr, timeZoneUkr);
                                }
                            }
                        );
                        resolve();
                    }
                )
            }
        )
    };
    
    getById (id) {
        var searchItem = this.filter(
            function (invitation) {
                return invitation.gid == id;
            }
        );
            
        if (searchItem.length == 1) {
            return searchItem[0];
        }
    
        return null;
    };
    
    refresh (id, data) {
        var key = this.findIndex(
            function (item) {
                return item.gid == id;
            }
        );
    
        this.splice(key, 1, data);
        return this.getById(id);
    };
    
    removeById (id) {
        var key = this.findIndex(
            function (item) {
                return item.gid == id;
            }
        );
    
        this.splice(key, 1);
    };
    
    chooseClassNameForPopup (gid) {
        var invitation = this.getById(gid);
        if (!invitation) {
            return '';
        }
    
        var popupClass = this.statusMatrix[invitation['lastAction'] + "-" + invitation['whoDo'] + "-" + invitation['status']].popupClass;
        return popupClass;
    }
    
    getInvitationStatus (id) {
        var invitation = this.getById(id);
    
        if (!invitation) {
            return null;
        }
    
        var status = this.statusMatrix[invitation['lastAction'] + "-" + invitation['whoDo'] + "-" + invitation['status']].status;
    
        if (!status) {
            return null;
        }
    
        if (status === 'agree' && invitation.chat_girls_soon_delay > 0) {
            status = 'late';
        }

        if (status === 'agree' && invitation.chat_girls_soon_delay < 0) {
            status = 'early';
        }
    
        if (invitation.roomId > 0) {
            status = 'online';
        }
    
        return status;
    }
    
    deleteByGid (gid) {
        var self = this;
        var invitation = this.getById(gid);
        if (!invitation) {
            return false;
        }
        function onConfirm() {
            var userId = getCookie('logid');
            var url = '/user/' + userId + '/chat/remove';
            var data = {id: invitation.invitation_id}
            requestApi(url, 'DELETE', data, 'json', 'json').then(function(resp) {
                window.closeReactPopup();
                self.removeById(gid);
                self.trigger('reload');
            });
        }
        window.initReactPopup('removeItemPopup', { onConfirm: onConfirm, title: 'Deleting invitation' });
    }
    
    accept (ladyId, callback) {
        var self = this;
        var invitation = this.getById(ladyId);
    
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/chat/accept?id=' + invitation.invitation_id;
        return requestApi(url, 'PUT', null, 'json').then(function(resp) {
            if (callback) {
                callback()
            }
            self.reload();
            return Promise.resolve(resp)
        });
    }
    
    getOnlineInvite () {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/notification/onlineInvite';
        return new Promise(function (resolve, reject) {
            if (userId <= 10000) {
                reject();
            } else {
                requestApi(url, 'GET', null, 'json').then(resolve, reject);
            }
        });
    }
}