import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";

export class SearchFormData extends CustomEventTarget {
    constructor () {
        super();
        this._searchProps = {
            name: '',
            girlWantKids: '',
            girlHaveKids: '',
            build: '',
            seeking: '',
            smoke_drink: '',
            eyes: '',
            hair: '',
            marital: '',
            glasses: '',
            religion: '',
            degree: '',
            zodiac: '',
            field_work: '',
            branches: ''
        };
        this.searchedStatus = false;
        this.buttonLoading = false;
    }

    promiseCreateInstance () {
        var url = '/search/props';
        var self = this;
    
        return requestApi(url, 'GET', null, 'json').then(
            function (props) {
                self.data = {};
                for (var k in props) {
                    self.data[k] = props[k];
                }
                return Promise.resolve(self.data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    };
    
    getSearchProps () {
        return this._searchProps;
    }
    
    setSearchProps (props) {
        this._searchProps = Object.assign(this._searchProps, props);
        switch (this._searchProps.girlWantKids) {
            case '':
                this._searchProps.girlWantKids = ''
                break
            case false:
                this._searchProps.girlWantKids = '0'
                break
            case true:
                this._searchProps.girlWantKids = '1'
                break
        }
        switch (this._searchProps.girlHaveKids) {
            case '':
                this._searchProps.girlHaveKids = ''
                break
            case false:
                this._searchProps.girlHaveKids = '0'
                break
            case true:
                this._searchProps.girlHaveKids = '1'
                break
        }
        this.trigger('changedSearchProps');
    }
    
    getSearchStatus () {
        return this.searchedStatus;
    }
    
    setSearchStatus (value) {
        this.searchedStatus = value;
        this.trigger('changedSearchStatus');
    }
    
    getButtonStatus () {
        return this.buttonLoading;
    }
    
    setButtonStatus (value) {
        this.buttonLoading = value;
        this.trigger('changedButtonStatus');
    }
    
    fromSearch (value) {
        this.trigger('fromSearch');
    }
    
    getCountSearch () {
        var url = '/search/send?data=' + JSON.stringify(this._searchProps);
        return requestApi(url, 'GET', null, 'json').then(
            function (res) {
                return Promise.resolve(res.total);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
}