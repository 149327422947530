import { CustomCollection } from "../helpers/custom-classes";
import { getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";

export class PromoBanners extends CustomCollection {
    promiseCreateInstance () {
        var self = this;
        self.promoMailsList = [];
        self.promoPhotosList = [];
        self.promoVideoList = [];
    
        self.mailboxBannersList = [];
        self.galleryBannersList = [];
        self.chatBannersList = [];
        self.servicesBannersList = [];
        self.profilePageBannerList = [];
        self.profileCaroueslBannerList = [];
        self.menuProfileBannersList = [];
        self.menuServiceBannerList = [];
        self.chatRoomBannerList = [];
        self.chatOnlineBannerList = [];
    
        var userId = getCookie('logid');
        return new Promise(function(resolve, reject) {
            var url = '/banners';
            if(userId > 10000) {
                url = '/user/' + userId + '/banners'
            }
            requestApi(url, 'get', null, 'json').then(function(resp) {
                self._setBannersData(resp)            
                resolve()
            })
        })
    }
    
    _setBannersData (resp) {
        var self = this;
        var todayDate = new Date();
        function creacteDate(date) {
            return new Date(date)
        }
        if(resp.success && resp.data.length) {
            resp.data.forEach(function(group) {
                if(creacteDate(group.dateFrom) >= todayDate || creacteDate(group.dateTo) <= todayDate || 
                (group.isTest && (window.location.host === "www.uadreams.com" || window.location.host === "uadreams.com"))) {
                    return true
                }
                group.banners.forEach(function(banner) {
                    if(creacteDate(banner.dateFrom) >= todayDate || creacteDate(banner.dateTo) <= todayDate) {
                        return true
                    }

                    var promoElement = {
                        id: banner.id,
                        dateFrom: banner.dateFrom,
                        dateTo: banner.dateTo,
                        photoSrc: banner.imgSrc,
                        photoSrcMob: banner.imgSrcMob,
                    }
                    if(banner.location === 'promo-mail') {
                        var promoMail = {
                            subject: banner.subject,
                            body: banner.promo_body,
                            type: 'promo',
                            gtm_class: banner.gtm_class ? banner.gtm_class : ''
                            
                        }
                        self.promoMailsList.push(Object.assign(promoElement, promoMail))
                    } else if(banner.location === 'lady-photos') {
                        var promoPhoto = {
                            text: banner.promo_body,
                            btnText: banner.btn_text,
                            btnLink: banner.btn_link,
                            btnType: banner.btn_type,
                            position: banner.position,
                            gtm_class: banner.gtm_class ? banner.gtm_class : ''
                        }
                        self.promoPhotosList.push(Object.assign(promoElement, promoPhoto))
                    } else if(banner.location === 'user-profile-video') {
                        var promoVideo = {
                            videoSrc: banner.url,
                            event_category: banner.event_category,
                            event_label: banner.event_label,
                            event_action: banner.event_action,
                            handlers: banner.handlers,
                            gtm_class: banner.gtm_class ? banner.gtm_class : ''
                        }
                        self.promoVideoList.push(Object.assign(promoElement, promoVideo))
                    } else {
                        var promoBanner = {
                            link: banner.url,
                            event_category: banner.event_category,
                            event_label: banner.event_label,
                            event_action: banner.event_action,
                            handlers: banner.handlers,
                            gtm_class: banner.gtm_class ? banner.gtm_class : ''
                        }
                        var fullBanner = Object.assign(promoElement, promoBanner)
                        var listType = false;
                        if(banner.location.includes('gallery')) {
                            self['galleryBannersList'].push(fullBanner)
                        } 
                        if(banner.location.includes('services')) {
                            self['servicesBannersList'].push(fullBanner)
                        } 
                        if(banner.location.includes('chat-list')) {
                            self['chatBannersList'].push(fullBanner)
                        } 
                        if(banner.location.includes('mailbox')) {
                            self['mailboxBannersList'].push(fullBanner)
                        } 
                        if(banner.location.includes('user-profile-page')) {
                            self['profilePageBannerList'].push(fullBanner)
                        } 
                        if(banner.location.includes('user-profile-carousel')) {
                            self['profileCaroueslBannerList'].push(fullBanner)
                        }
                        if(banner.location.includes('menu-user-profile')) {
                            self['menuProfileBannersList'].push(fullBanner)
                        }
                        if(banner.location.includes('menu-services')) {
                            self['menuServiceBannerList'].push(fullBanner)
                        }
                        if(banner.location.includes('chat-room')) {
                            self['chatRoomBannerList'].push(fullBanner)
                        }
                        if(banner.location.includes('chat-online-list')) {
                            self['chatOnlineBannerList'].push(fullBanner)
                        }
                        if(listType) {
                            self[listType].push(fullBanner)
                        }
                    }
                })
            })
        }
    }
}