import { getCookie } from "./functions";
import { isEnvDevelopment } from "../constants";
import { devApiUrl } from "../constants";

// request wrapper for rest api
const requestApi = (url, type, data, responceType, sendType) => {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest;
        responceType = responceType || 'event';
        xhr.open(type, devApiUrl + url);
        xhr.onload = function (event) {

            if (xhr.status === 401 || xhr.status === 403) {
                window.setCookieLearn('logid', "", {'max-age': -1, 'domain' : window.location.hostname});
                window.setCookieLearn('login_key', "", {'max-age': -1, 'domain' : window.location.hostname});

                if (isEnvDevelopment) {
                    window.location.assign('/login');
                } else {
                    window.checkAuth();
                }

                return false;
            }

            if (responceType === 'json') {
                try {
                    var res = JSON.parse(event.target.response);
                    resolve(res);
                } catch (error) {
                    reject(error);
                }
            } else {
                resolve(event)
            }
        };

        xhr.onerror = function (event) {
            reject("Error " + event.target.status + " occurred while receiving the document.")
        }

        var supportId = getCookie('slogid');
        var ssid = getCookie('login_key');
        var adm = getCookie('adm');
        var pr = getCookie('pr');

        const oncelogged = getCookie("oncelogged") || 0
        xhr.setRequestHeader('oncelogged', oncelogged);

        if (supportId > 0 && adm) {
            xhr.setRequestHeader('api-token-support', adm);
        } else if (!!ssid){
            xhr.setRequestHeader('api-token', ssid);
        }
        if(pr){
            xhr.setRequestHeader('pr', pr);
        }

        if (type === 'POST' || type === 'PUT' || type === 'DELETE') {
            if(sendType === 'json') {
                xhr.setRequestHeader('Content-Type', 'application/json');
                data = JSON.stringify(data || {});
            }
            xhr.send(data);
        } else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(data);
        }
    });
}
export default requestApi;