import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";

export class Basket extends CustomCollection {
    promiseCreateInstance () {
        return this.updateData();
    }
    
    updateData () {
        var self = this;
        var userId = getCookie('logid');
        
        if (userId > 0) {
            var url = '/user/' + userId + '/basket';
            return requestApi(url, 'get', null, 'json').then(function (data) {
                // console.log('request', data)
                self._setDataToBasket(data);
                return Promise.resolve();
            });
        } else {
            var currency = getCookie('user_currency')
            self.currency = currency ? currency : "euro";
            return Promise.resolve();
        }
    }
    
    toStringVideoChatCredit () {

        if (this.credits.chatText <= 59) {
            return '0 m';
        }
    
        var totalMin = Math.floor(this.credits.chatText / 60);
        var hours = 0;
        var min = 0;
        
        if (totalMin > 0) {
            hours = Math.floor(totalMin / 60)
            min = totalMin - hours * 60
        }
    
        var timeString = '';
    
        if (hours) {
            timeString += hours + ' h ';
        }
    
        if (min) {
            timeString += min + ' m';
        }
    
        return timeString;
    }
    
    changeCurrency (cur) {
        var userId = getCookie('logid');
        if(userId > 10000) {
            var url = '/user/' + userId + '/changeCurrency'
            requestApi(url, 'POST')
        } else {
            window.setCookie('user_currency', cur)
        }
        this.currency = cur;
        this.trigger('currencyChanged', {});
    }
    
    changeCredit (type, newCount) {
        this.credits[type] = newCount;
        this.trigger('creditsChanged')
    }
    
    setNewCredits (creditsObj) {
        this.credits = this.credits.assign(creditsObj)
        this.trigger('creditsChanged')
    }
    
    loadCredits () {   
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket';
    
        return requestApi(url, 'GET', null, 'json').then(function(data) {
            self.credits.photo = data.credits.photo;
            self.credits.video = data.credits.video;
            self.credits.mail = data.credits.email;
            self.credits.chat = {
                total: data.credits.chat,
                exchange: data.credits.chat_exchange,
                happyHourCredits: data.credits.happyChat
            }
            self.trigger('creditsChanged');
            return Promise.resolve(self.credits);
        });
    }
    addToCart (giftId, quantity, ladyId) {   
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket?id=' + giftId;
        if(quantity || quantity === 0) {
            url += '&quantity=' + quantity;
        }
        if(ladyId) {
            url += '&gid=' + ladyId;
        }
        return requestApi(url, 'POST', null, 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
                return Promise.resolve();
            } else if(resp.err === 'Girl ID empty') {
                function selectLady(data) {
                    self.addToCart(giftId, false, data.id)
                }
                window.initReactPopup('chooseLadyPopup', { selectLady: selectLady, title: 'Gift for..' })
            } else {
                self.updateData();
            }
        });
    }
    
    addManyToCart (data) {
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket/stack';
        return requestApi(url, 'POST', data, 'json', 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
                return Promise.resolve({success: true});
            } else {
                return Promise.resolve({success: false})
            }
        })
    }
    
    _setDataToBasket (data) {
        var self = this;
        for (var k in data) {
            self[k] = data[k];
        }
        self.credits.mail = self.credits.email;
        self.credits.chat = {
            total: self.credits.chat,
            exchange: self.credits.chat_exchange,
            happyHourCredits: self.credits.happyChat
        }
        delete self.credits.email;
        delete self.credits.chat_exchange;
        delete self.credits.happyChat;
        self.trigger('creditsChanged');
        self.trigger('basketChanged');
    }
    removeFromCart (payID) {   
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket?ids=[' + payID + ']';
        return requestApi(url, 'DELETE', null, 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
                return Promise.resolve();
            } else {
                self.updateData();
            }
        });
    }

    addToCartTopUp(giftId, roomId) {
        var self = this;
        var userId = getCookie('logid')
        var url = `/user/${userId}/chat/addService?giftId=${giftId}&roomId=${roomId}`;
        
        return requestApi(url, 'POST', null, 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
                return Promise.resolve();
            } else {
                self.updateData();
            }
        });
    }
    
    updateCartItem (body) { 
        // body - ARRAY  
        // var body = {
        //     "paysID" : number,
        //     "gid" : number, 
        //     "wrap": boolean,
        //     "delivery": boolean,
        //     "grand": boolean,
        //     "postCart": boolean,
        //     "comment": string
        // }
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket';
        return requestApi(url, 'PUT', body, 'json', 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
                return Promise.resolve();
            } else {
                self.updateData();
            }
        });
    }
    
    useBonus () { 
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket/payWithDiscount';
        return requestApi(url, 'POST', null, 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
                return Promise.resolve();
            } else {
                self.updateData();
            }
        });
    }
    
    clearAllBasket () { 
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket/all';
        return requestApi(url, 'DELETE', null, 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
                return Promise.resolve();
            } else {
                self.updateData();
            }
        });
    }
    getPaymentsHistory (lastId) { 
        console.log('lastId', lastId);
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket/getInvoices';
        if(lastId) {
            url = '/user/' + userId + '/basket/getInvoices?lastId=' + lastId;
        }
        return requestApi(url, 'GET', null, 'json').then(
            function (data) {
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
    getByInvoice (invoiceId = 0) {
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket/invoice/'+invoiceId;
        if(userId > 10000 || invoiceId !== 0) {
            return requestApi(url, 'GET', null, 'json')
        } else {
            return Promise.resolve()
        }
    }
    
    exchangeCredits (type) { 
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket/exchange';
        if(userId > 10000) {
            var body = { type: type }
            return requestApi(url, 'PUT', body, 'json', 'json').then(function(resp) {
                if(resp.success) {
                    self._setDataToBasket(resp.data);
                }
                return Promise.resolve(resp);
            })
        } else {
            return Promise.resolve()
        }
    }
    
    addSpecialInvoice (data) {
        var self = this;
        var userId = getCookie('logid')
        var url = '/user/' + userId + '/basket?id=200&gid=' + data.gid;
        var body = { 
            price: data.price,
            description: data.description 
        }
        return requestApi(url, 'POST', body, 'json', 'json').then(function(resp) {
            if(resp.success) {
                self._setDataToBasket(resp.data);
            }
            return Promise.resolve(resp);
        })
    }
}