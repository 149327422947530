import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";

export class OfficeWebCams extends CustomCollection {
    promiseCreateInstance () {
        var self = this;
        return requestApi('/support/webcams', 'get', null, 'json').then(
            function (data) {
                self.camsList = data;
                return Promise.resolve();
            }, function (error) {
                return Promise.reject(error);
            }
        );
    };
}