import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";

export class Winks extends CustomEventTarget {
    promiseCreateInstance () {
        this.favWinks = [];   
        return Promise.resolve();    
    };
    getWinks (ladyId){
        var self = this;
        var isList = 0;
        if(!self.favWinks.length) {
            isList = 1
        }
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/winks/' + ladyId + '?needList=' + isList;
        return new Promise(function (resolve, reject) {
            return requestApi(url, 'GET', null, 'json').then(function(resp) {
                if(isList) {
                    self.favWinks = resp.favorites
                    self.packs = resp.list
                }
                var data = {
                    sent: resp.sent,
                    sent_wink: resp.sentWink,
                    favWinks: self.favWinks,
                    packs: self.packs
                }
                resolve(data)
            })
        });
    }
    
    sendWink (ladyId, wink, msg) {
        var self = this;
        var data = {
            "ladyId": ladyId,
            "type": wink,
            "message": msg
        }
        return new Promise(function (resolve, reject) {
            var userId = getCookie('logid');
            var url = '/user/' + userId + '/winks/';
            requestApi(url, 'POST', data, 'json', 'json').then(function(resp) {
                if(resp.success) {
                    window.instancesPool.get('Ladies').then(function(ladies) {
                        ladies.setWink(ladyId, wink)
                    })
                    self.sendingWtype = wink
                    resolve(wink)
                } else {
                    resolve(false)
                }
            })
        });
    }
}