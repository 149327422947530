import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";
import { instancesPool } from "../old/models";

export class Marketing extends CustomCollection {
    promiseCreateInstance () {
        var self = this;
        var userId = getCookie('logid');
    
        if (userId > 0) {
            // self.active = true;
            // self.userType = 1;
            // self.game = {progress : 0, dayNumber : 1};
            // return Promise.resolve();
            return requestApi('/user/' + userId + '/marketing', 'get', null, 'json').then(
                function (data) {
                    for (var k in data) {
                        self[k] = data[k];
                    }
    
                    return Promise.resolve();        
                }, function (error) {
                    return Promise.reject(error);
                }
            );
        } else {
            return Promise.resolve();
        }
    };

    testing (data) {
       var self = this;
        var userId = getCookie('logid');
    
        if (userId > 0) {
            return requestApi('/user/' + userId + '/marketing/testing', 'POST', data, '', 'json').then(
                function () {
                    window.location.reload();
                    return Promise.resolve();
                }
            );
        } else {
            return Promise.resolve();
        }
    }
}