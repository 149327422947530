class Events {

	_initEvents(eventName) {
		if (!this._events) {
			this._events = {};
		}

		if (!this._events[eventName]) {
			this._events[eventName] = [];
		}
	}
	
	_getFunctionName(func) {
		
		if (typeof func !== 'function') {
			return '';
		}
		
		let name = func.name;
		
		if (name.length <= 0) {
			name = func.toString();
		}
		
		return name;
	}
	
	_eval(handler, event) {
		
		if (typeof handler !== 'function') {
			return false;
		}
		
		handler(event);
	}
	
	addListener(eventName, handler) {
		this._initEvents(eventName);
		this._events[eventName].push(handler);
	}
	
	removeListener(eventName, handler) {
		this._initEvents(eventName);
		let funcName = this._getFunctionName(handler);
		this._events[eventName].forEach(
			(handler, index) => (this._getFunctionName(handler) === funcName 
			&& this._events.splice(index, 1))
		);
	}

	trigger(eventName, props = {}) {
		this._initEvents(eventName);
		var event = Object.assign({'eventName' : eventName}, props);
		this._events[eventName].forEach(
			handler => this._eval(handler, event)
		)
	}
}

export {Events};