import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";

export class GiftsMinPrice extends CustomEventTarget {
    promiseCreateInstance () {
        var self = this;
        return requestApi('/gifts/getMinPricesGifts', 'GET', null, 'json').then(
            function (props) {
                self.data = props;
                return Promise.resolve();
            }, function (error) {
                return Promise.reject(error);
            }
        );
    }
} 