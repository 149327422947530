import EventTarget from "./event-target-polyfill";

export class CustomEventTarget {
    constructor() {
        try {
            this._originEventTarget = new EventTarget;
        } catch (e) {
            this._originEventTarget = new Element;
        }
    }
    
    addListener (eventName, callBack) {
        return this._originEventTarget.addEventListener(eventName, callBack);
    };

    removeListener (eventName, callBack) {
        return this._originEventTarget.removeEventListener(eventName, callBack);
    };

    trigger (eventName, currentTarget) {
        this._events = this._events || {};

        if (!(this._events[eventName] instanceof Event)) {
            try {
                this._events[eventName] = new Event(eventName);
            } catch (e) {
                this._events[eventName] = new CustomEvent(eventName);
            }
        }

        this._events[eventName].customCurrentTarget = currentTarget || this; 
        this._originEventTarget.dispatchEvent(this._events[eventName]);
    };
};

const customCollectionMixin = {
    addListener: CustomEventTarget.prototype.addListener,
    removeListener: CustomEventTarget.prototype.removeListener,
    trigger: CustomEventTarget.prototype.trigger,
};

export class CustomCollection extends Array {
    constructor() {
        super();
        try {
            this._originEventTarget = new EventTarget;
        } catch (e) {
            this._originEventTarget = new Element;
        }

        Object.assign(this, customCollectionMixin);
    }
	
    reload () {	
        var self = this;
        return new Promise(
            function(resolve, reject) {
                
                if (typeof self.promiseCreateInstance !== 'function') {
                    return reject("ERROR: Class "+self.constructor.name+" doesn't have method promiseCreateInstance. Override method reload");
                }
    
                self.splice(0,self.length)
                self.promiseCreateInstance().then(
                    function() {
                        self.trigger('reload');
                        resolve();
                    }, reject
                )
            }
        );
    };
}