import { CustomCollection } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";

export class ShopNavigation extends CustomCollection {
    promiseCreateInstance () {
        var self = this;
        self.items = [];
        return new Promise(function(resolve, reject) {
            var url = '/gifts/getGiftsGroups';
            requestApi(url, 'get', null, 'json').then(
                function (data) {
                    self.items = data;
                    self.items.push({
                        alias: "LadysBirthday",
                        id: 153,
                        show_onsite: "1",
                        subtitle: "Lady's Birthday!",
                        text: "Clothing & Shoes ",
                        title: "Lady's Birthday!",
                        // type: "Clothing_&_Shoes_"
                    });
                    resolve();
                }, function (error) {
                    return Promise.reject(error);
                }
            );
        }); 
    }
    
    getAllItems () {
        return this.items;
    }
    
    getShowOnSite () {
        return this.items.filter(function(item) {
            return item.show_onsite == 1
        });
    }
    
    getSubSections () {
        return this.items.filter(function(item) {
            return item.show_onsite !== 1 || item.id === 265
        });
    }
    
    getByCode (code) {
        let items = this.items.filter(function(item) {
            return item.alias === code 
        }); 
        
        if (items[0]) {
            return items[0];
        } 
    
        return false;
    }
}