import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { getCookie } from "../helpers/functions";
import { instancesPool } from "../old/models";

export class MediaBox extends CustomEventTarget {
    promiseCreateInstance () {
        this.selectedLady = false;
        return Promise.resolve();
    };
    setSelectedLady (lady) {
        var self = this;
        self.selectedLady = lady;
        self.trigger('ladyChanged');
    }
    getSelectedLady () {
        var self = this;
        return new Promise(function(resolve, reject) {
            resolve(self.selectedLady)
        })
    }
    
    getFilesFromLadies (filter, mediaType, page) {
        //mediaType = all, accepted, new, sale
        //type = new, sweet
        //page = 0,1,2...
    
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/' + mediaType + '?type=' + filter + '&page=' + page;
        return requestApi(url, 'GET', null, 'json')
    }
    
    getFilesById (ladyId) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/id?gid=' + ladyId;
        return requestApi(url, 'GET', null, 'json').then(resp => {
            instancesPool.get('Files').then(files => {
                files.setFiles(resp[0].files)
            })
            return Promise.resolve(resp)
        })
    }
    
    deleteFileFromLady (fileId, fileType) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/file?fid=' + fileId +'&type=' + fileType;
        return requestApi(url, 'DELETE')
    }
    deleteFileToLady (fileId, fileType, ladyId) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/file?fid=' + fileId +'&type=' + fileType + '&gid=' + ladyId;
        return requestApi(url, 'DELETE')
    }
    
    getFilesCounters () {
        var self = this;
        var userId = getCookie('logid');
        if(self.filesCounter) {
            return Promise.resolve(self.filesCounter)
        } else {
            return self.loadFilesCounters()
        }
    }
    updateFileCounters () {
        var self = this;
        self.loadFilesCounters().then(function() {
            self.trigger('files-counters-changed')
        })
    }
    loadFilesCounters () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/info';
        return requestApi(url, 'GET', null, 'json').then(function(data) {
            self.filesCounter = data
            return Promise.resolve(data)
        })
    }
    
    getFilesToLadies (page) {
        //page = 0,1,2...
    
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/to?page=' + page;
        return requestApi(url, 'GET', null, 'json')
    }
    getFilesToLadiesById (ladyId) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/ladies/files/to?id=' + ladyId;
        return requestApi(url, 'GET', null, 'json')
    }
}