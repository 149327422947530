import { CustomEventTarget } from "../helpers/custom-classes";
import { getCookie } from "../helpers/functions";
import requestApi from "../helpers/request-api";

export class SiteIdeas extends CustomEventTarget {
    promiseCreateInstance () {
        return Promise.resolve();
    }
    
    getNewSiteIdea () {
        var userId = getCookie('logid');
        var url = '/siteIdeas/new';
        if (userId > 0) {
            url = '/user/' + userId + '/siteIdeas/new'
        }
    
        if(this.newIdeas) {
            return Promise.resolve(this.newIdeas)
        } else {
            return this.updateNewSiteIdea()
        }
    }
    updateNewSiteIdea () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/siteIdeas/new';
        if (userId > 0) {
            url = '/user/' + userId + '/siteIdeas/new'
        }
    
        return requestApi(url, 'GET', null, 'json').then(
            function (data) {
                self.newIdeas = data
                return Promise.resolve(data);
            }, function (error) {
                return Promise.reject(error);
            }
        )
    }
    getCompletedSiteIdea () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/siteIdeas/completed';
        if (userId > 0) {
            url = '/user/' + userId + '/siteIdeas/completed'
        }
        if(self.completedIdeas) {
            return Promise.resolve(self.completedIdeas)
        } else {
            return requestApi(url, 'GET', null, 'json').then(
                function (data) {
                    self.completedIdeas = data;
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }
    getDevelopedSiteIdea () {
        var self = this;
        var userId = getCookie('logid');
        var url = '/siteIdeas/developer';
        if (userId > 0) {
            url = '/user/' + userId + '/siteIdeas/developer'
        }
        if(self.developedIdeas) {
            return Promise.resolve(self.developedIdeas)
        } else {
            return requestApi(url, 'GET', null, 'json').then(
                function (data) {
                    self.developedIdeas = data;
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        }
    }
    
    createNewSiteIdea (data) {
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/siteIdeas/new';
            return requestApi(url, 'POST', data, null, 'json').then(
                function (data) {
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        } else {
            return Promise.resolve()
        }
    }
    
    voteSiteIdea (id, rate) {
        var userId = getCookie('logid');
        if (userId > 0) {
            var url = '/user/' + userId + '/siteIdeas/vote?fid=' + id + 'ideaID&rate=' + rate;
            return requestApi(url, 'POST').then(
                function (data) {
                    return Promise.resolve(data);
                }, function (error) {
                    return Promise.reject(error);
                }
            )
        } else {
            return Promise.resolve()
        }
    }
}