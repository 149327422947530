import { CustomEventTarget } from "../helpers/custom-classes";
import requestApi from "../helpers/request-api";
import { $, getCookie } from "../helpers/functions";

export class ReactPopup extends CustomEventTarget {
    promiseCreateInstance () {
        this.stack = [];
        return Promise.resolve();
    };
    
    loadingComplete () {
        this.trigger('loaded')
    };
    
    chatInit (data) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/chat/inviteToChat';
        return requestApi(url, 'POST', data, 'json', 'json').then(resp => {
            return Promise.resolve(resp)
        });
    
    };
    
    logChatNow (gid) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/chat/clickChatNow?gid=' + gid;
        return new Promise(function (resolve, reject) {
            if (userId <= 10000) {
                resolve();
            } else {
                requestApi(url, 'POST', null, 'json');
            }
        });
    };
    
    chatDecline (data) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/chat/reject';
        return requestApi(url, 'PUT', data, 'json', 'json').then(function(resp) {
            return Promise.resolve(resp)
        });
    };
    
    chatNowPostpone (data) {
        var userId = getCookie('logid');
        var url = '/user/' + userId + '/chat/later?gid='+data.gid+'&elem='+data.elem;
        return requestApi(url, 'PUT', null, 'json').then(function(resp) {
            return Promise.resolve(resp)
        });
    };
    
    setToStack (type, data, isWatched) {
        var self = this;
        self.stack = self.stack.concat({ type: type, data: data, isWatched: isWatched })
    };
    
    checkStack (type, data) {
        var self = this;
        var reactContainer = document.querySelector('[data-react-container="react-portal"]');
        var mobilePopupContainer = document.querySelector('.mobilepopup.open');
        var isMobilePopup = false;
    
        if (mobilePopupContainer && mobilePopupContainer.childElementCount !== 0) {
            isMobilePopup = true;
        }
    
        if (self.stack.length > 0 && reactContainer.childElementCount === 0 && !isMobilePopup) {
            for (var i = 0; i < self.stack.length; i++) {
                if (!self.stack[i].isWatched) {
                    self.stack[i].isWatched = true;
                    // на время бикини
                    if(self.stack[i].type === 'showNowChatPopup' && window.location.href.includes('/profile')) {
                        break;
                    }
                    // на время бикини end
                    window.udapp.globalController[self.stack[i].type](self.stack[i].data);
                    $('select').blur();
                    break;
                }
            }
        }
    }
}